import { ActionType, getActionTypeFromInstance, NgxsNextPluginFn } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MeetingState } from '../../../dsmb/store/meeting/meeting.state';
import { MEETING_STATE_TOKEN } from 'src/app/dsmb/store/meeting/meeting.token';
import { ConfigurationState } from 'src/app/dsmb/store/configurations/configuration.state';
import { CONFIGURATION_STATE_TOKEN } from 'src/app/dsmb/store/configurations/configuration.token';
import type { ReadonlyDeep } from 'type-fest';
import { TasksState } from '../../../dsmb/store/tasks/tasks.state';
import { TASKS_STATE_TOKEN } from '../../../dsmb/store/tasks/tasks.token';
import { ApplicationState } from '../application/application.state';
import { APPLICATION_STATE_TOKEN } from '../application/application.token';
import { LogoutAction } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import { AUTH_STATE_LOCAL_STORAGE_KEYS, AUTH_STATE_TOKEN } from '../auth/auth.token';
import { HelpdeskState } from '../helpdesk/helpdesk.state';
import { HELPDESK_STATE_TOKEN } from '../helpdesk/helpdesk.token';
import { OperationState } from '../operation/operation.state';
import { OPERATION_STATE_LOCAL_STORAGE_KEYS, OPERATION_STATE_TOKEN } from '../operation/operation.token';
import { OrganizationState } from '../organization/organization.state';
import { ORGANIZATION_STATE_TOKEN } from '../organization/organization.token';
import { SidebarState } from '../sidebar/sidebar.state';
import { SIDEBAR_STATE_TOKEN } from '../sidebar/sidebar.token';
import { ICoreState } from '../store.interface';
import { StudyState } from '../study/study.state';
import { STUDY_STATE_TOKEN } from '../study/study.token';
import { UserState } from '../user/user.state';
import { USER_STATE_TOKEN } from '../user/user.token';
import { DOCUMENT_STATE_TOKEN } from 'src/app/dsmb/store/document/document.token';
import { DocumentState } from 'src/app/dsmb/store/document/document.state';
import { DOCUMENT_COUNTERSIGN_STATE_TOKEN } from 'src/app/dsmb/store/document-countersign/document-countersign.token';
import { DocumentCountersignState } from 'src/app/dsmb/store/document-countersign/document-countersign.state';
import { MEETING_POLL_STATE_TOKEN } from 'src/app/dsmb/store/meeting-poll/meeting-poll.token';
import { MeetingPollState } from 'src/app/dsmb/store/meeting-poll/meeting-poll.state';
import { MeetingRecordingState } from 'src/app/dsmb/store/meeting-recording/meeting-recording.state';
import { MEETING_RECORDING_STATE_TOKEN } from 'src/app/dsmb/store/meeting-recording/meeting-recording.token';
import { USER_CHAT_STATE_TOKEN } from '../chat/chat.token';
import { UserMessageState } from '../chat/chat.state';
import { DiscussionItemsState } from 'src/app/dsmb/store/discussion-items/discussion-items.state';
import { DISCUSSION_ITEMS_STATE_TOKEN } from 'src/app/dsmb/store/discussion-items/discussion-items.token';
import { DECISIONS_STATE_TOKEN } from 'src/app/dsmb/store/decisions/decisions.token';
import { DecisionsState } from 'src/app/dsmb/store/decisions/decisions.state';
import { VERSION_STATE_TOKEN } from '../version/version.token';
import { VersionState } from '../version/version.state';
import { TRAINING_HISTORY_TOKEN } from '../training-history/training-history.token';
import { TrainingHistoryState } from '../training-history/training-history.state';

export function logoutPlugin(
  state: ReadonlyDeep<ICoreState>,
  action: Readonly<ActionType>,
  next: NgxsNextPluginFn
): Observable<any> {

  if (getActionTypeFromInstance(action) === LogoutAction.type) {
    state = {
      ...state, // "unknown" core inheritors state

      [APPLICATION_STATE_TOKEN.getName()]: { ...ApplicationState.defaultState },
      [AUTH_STATE_TOKEN.getName()]: { ...AuthState.defaultState },
      [HELPDESK_STATE_TOKEN.getName()]: { ...HelpdeskState.defaultState },
      [ORGANIZATION_STATE_TOKEN.getName()]: { ...OrganizationState.defaultState },
      [STUDY_STATE_TOKEN.getName()]: { ...StudyState.defaultState },
      [USER_STATE_TOKEN.getName()]: { ...UserState.defaultState },
      [MEETING_STATE_TOKEN.getName()]: { ...MeetingState.defaultState},
      [CONFIGURATION_STATE_TOKEN.getName()]: { ...ConfigurationState.defaultState },
      [OPERATION_STATE_TOKEN.getName()]: { ...OperationState.defaultState},
      [DOCUMENT_STATE_TOKEN.getName()]: {...DocumentState.defaultState},
      [DOCUMENT_COUNTERSIGN_STATE_TOKEN.getName()]: {...DocumentCountersignState.defaultState},
      [TASKS_STATE_TOKEN.getName()]: {...TasksState.defaultState},
      [MEETING_POLL_STATE_TOKEN.getName()]: {...MeetingPollState.defaultState},
      [MEETING_RECORDING_STATE_TOKEN.getName()]: {...MeetingRecordingState.defaultState},
      [USER_CHAT_STATE_TOKEN.getName()]: {...UserMessageState.defaultState},
      [SIDEBAR_STATE_TOKEN.getName()]: {...SidebarState.defaultState},
      [DISCUSSION_ITEMS_STATE_TOKEN.getName()]: {...DiscussionItemsState.defaultState},
      [DECISIONS_STATE_TOKEN.getName()]: {...DecisionsState.defaultState},
      [VERSION_STATE_TOKEN.getName()]: {...VersionState.defaultState},
      [TRAINING_HISTORY_TOKEN.getName()]: {...TrainingHistoryState.defaultState},

    };
  }
  return next(state, action).pipe(finalize(() => {
    if (getActionTypeFromInstance(action) === LogoutAction.type) {
      AUTH_STATE_LOCAL_STORAGE_KEYS.forEach(key => localStorage.removeItem(key));
      OPERATION_STATE_LOCAL_STORAGE_KEYS.forEach(key => localStorage.removeItem(key));
    }
  }));
}
