import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IUser, User, usersSchema } from '../../../platform/model/user.model';
import { HttpService } from '../../../platform/services/http.service';
import { DocumentBlobDTO } from '../../dtos/document-blob.dto';
import { MeetingRecordingDTO } from '../../dtos/meeting-recording.dto';
import { MeetingRecordingsService } from '../../services/meeting-recordings.service';
import {
  DeleteRecordingAction,
  DownloadRecordingBlobAction,
  GetRecordingsAction,
  GetRecordingsCountAction,
  UploadRecordingAction,
  ViewRecordingAction,
  GetRecordingAction
} from './meeting-recording.actions';
import { MEETING_RECORDING_STATE_TOKEN } from './meeting-recording.token';

export class IMeetingRecordingState {
    recordings: MeetingRecordingDTO[];
    recordingsCount: number;
    recordingBlob: DocumentBlobDTO;
    recordingUrl: string;
    recording: MeetingRecordingDTO;
}

@State({
    name: MEETING_RECORDING_STATE_TOKEN,
    defaults: MeetingRecordingState.defaultState
})
@Injectable()
export class MeetingRecordingState {
    static defaultState: IMeetingRecordingState = {
        recordings: [],
        recordingsCount: null,
        recordingBlob: null,
        recordingUrl: null,
        recording: null
    };

    constructor(
        private meetingRecordingService: MeetingRecordingsService,
        private httpService: HttpService
    ) { }

    @Selector()
    static getRecordings(state: IMeetingRecordingState) {
        return state.recordings;
    }

    @Selector()
    static getRecording(state: IMeetingRecordingState) {
        return state.recording;
    }

    @Selector()
    static getRecordingsCount(state: IMeetingRecordingState) {
        return state.recordingsCount;
    }

    @Selector()
    static viewRecordings(state: IMeetingRecordingState) {
        return state.recordingUrl;
    }

    @Action(GetRecordingsAction)
    getRecordings({ patchState }: StateContext<IMeetingRecordingState>,
                 { organizationId, studyId, meetingId, startPage, itemsPerPage}: GetRecordingsAction) {
        return this.meetingRecordingService.getRecordings(organizationId, studyId, meetingId, startPage, itemsPerPage)
            .pipe(tap(recordings => patchState({ recordings })));
    }

    @Action(GetRecordingsCountAction)
    getRecordingsCount({ patchState }: StateContext<IMeetingRecordingState>, { organizationId, studyId, meetingId}: GetRecordingsCountAction) {
        return this.meetingRecordingService.getRecordingsCount(organizationId, studyId, meetingId)
            .pipe(tap(recordingsCount => patchState({ recordingsCount })));
    }


    // TODO Make that the BE return recoridng and save it in state
    @Action(UploadRecordingAction)
    uploadRecordingAction(_, { organizationId, studyId, meetingId, payload }: UploadRecordingAction) {
      return this.httpService.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recording`, payload);
    }

    @Action(DeleteRecordingAction)
    deleteRecording({ getState, setState }: StateContext<IMeetingRecordingState>,
                   { organizationId, studyId, meetingId, recordingId }: DeleteRecordingAction) {
        return this.meetingRecordingService.deleteRecording(organizationId, studyId, meetingId, recordingId)
            .pipe(tap(_ => {
                // Remove deleted recording from recording list
                const state = getState();
                let recordingsList = [...state.recordings];
                recordingsList = recordingsList.filter(r => r.recordingId !== recordingId);

                // Decrease the document count
                const updatedCount = state.recordingsCount - 1;

                setState({
                    ...state,
                    recordings: recordingsList,
                    recordingsCount: updatedCount,
                });
            }));
    }

    @Action(DownloadRecordingBlobAction)
    downloadRecordingBlob({ getState, setState }: StateContext<IMeetingRecordingState>,
                           { organizationId, studyId, meetingId, recordingId }: DownloadRecordingBlobAction) {
        return this.meetingRecordingService.downloadRecordingBlob(organizationId, studyId, meetingId, recordingId)
            .pipe(tap(blob => {
                const state = getState();
                const temp: DocumentBlobDTO = {
                    fileId: recordingId,
                    blob: blob
                };

                setState({
                    ...state,
                    recordingBlob: temp,
                });
            }));
    }

    @Action(ViewRecordingAction)
    viewRecording({ patchState }: StateContext<IMeetingRecordingState>,
                 { organizationId, studyId, meetingId, recordingId }: ViewRecordingAction) {
                    return this.meetingRecordingService.viewRecording(organizationId, studyId, meetingId, recordingId)
                    .pipe(tap(recordingUrl => patchState({ recordingUrl })));
                 }

    @Action(GetRecordingAction)
    GetRecordingDataAction({ patchState }: StateContext<IMeetingRecordingState>,
                { organizationId, studyId, meetingId, recordingId }: GetRecordingAction) {
                    return this.meetingRecordingService.getRecording(organizationId, studyId, meetingId, recordingId)
                    .pipe(tap(recording => patchState({ recording })),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 404) {
                            patchState({ recording: null });
                        }
                        return throwError(() => error)
                    }));
                }
}

