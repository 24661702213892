import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ConfigurationDTO } from 'src/app/platform/dtos/configuration.dto';
import { ConfigurationService } from 'src/app/platform/services/configuration.service';
import { CONFIGURATION_STATE_TOKEN } from './configuration.token';

import {
    CloneStudyConfigurationAction,
    GetDsmbConfigurationsAction,
    GetDsmbSectionsAction,
    UpdateConfigurationsAction,
    UploadTemplateAgendaAction,
    UploadTemplateOutcomeAction,
    UploadTrainingVideoAction
} from './configuration.action';

export class IConfigurationState {
    configurations: ConfigurationDTO[];
    sections: ConfigurationDTO[];
}


@State({
    name: CONFIGURATION_STATE_TOKEN,
    defaults: ConfigurationState.defaultState
})
@Injectable()
export class ConfigurationState {
    static defaultState: IConfigurationState = {
        configurations: [],
        sections: [],
    };

    constructor(
        private readonly store: Store,
        private readonly configurationService: ConfigurationService,
    ) {
    }

    @Selector()
    static getConfigurations(state: IConfigurationState) {
        return state.configurations;
    }

    @Selector()
    static GetDsmbSections(state: IConfigurationState) {
        return state.sections;
    }

    @Action(GetDsmbConfigurationsAction)
    getDsmbConfigurations({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId }: GetDsmbConfigurationsAction) {
        return this.configurationService.getDsmbConfigurations(organizationId, studyId).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }

    @Action(GetDsmbSectionsAction)
    GetDsmbSections({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId }: GetDsmbSectionsAction) {
        return this.configurationService.getDsmbSections(organizationId, studyId).pipe(tap(sections => {
            patchState({ sections });
        }));
    }
    

    @Action(UpdateConfigurationsAction)
    updateConfigurations(_, { newConfigurations, organizationId, studyId, applicationId }: UpdateConfigurationsAction) {
        return this.configurationService.updateConfigurations(newConfigurations, organizationId, studyId, applicationId);
    }

    @Action(UploadTemplateAgendaAction)
    uploadTemplateAgenda({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId, template}: UploadTemplateAgendaAction) {
        return this.configurationService.uploadTemplateAgenda(organizationId, studyId, template).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }

    @Action(UploadTemplateOutcomeAction)
    uploadTemplateOutcome({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId, template }: UploadTemplateOutcomeAction) {
        return this.configurationService.uploadTemplateOutcome(organizationId, studyId, template).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }

    @Action(UploadTrainingVideoAction)
    uploadTrainingVideo({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId, videoInfo }: UploadTrainingVideoAction) {
        return this.configurationService.uploadTrainingVideo(organizationId, studyId, videoInfo).pipe(tap(configurations => {
            patchState({ configurations }); 
        }));
    }

    @Action(CloneStudyConfigurationAction)
    cloneStudyConfiguration(_, { organizationId, applicationId, study }: CloneStudyConfigurationAction) {
        return this.configurationService.cloneStudyConfiguration(organizationId, applicationId, study);
    }
}