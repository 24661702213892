import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { ApplicationParameterDTO } from '../../dtos/application-parameter.dto';
import { OperationDTO } from '../../dtos/operation.dto';
import { ApplicationParameterService } from '../../services/application-parameter.service';
import { OperationService } from '../../services/operation.service';
import {
  GetApplicationOperationsAction,
  GetApplicationParametersAction,
  GetOperationForLogsAction,
  GetOrganizationOperationsAction,
  GetPlatformRolesOperationsAction
} from './operation.actions';
import { OPERATION_STATE_TOKEN } from './operation.token';

export interface IOperationState {
  platformRolesOperations: string[];
  applicationParameters: ApplicationParameterDTO[];
  operations: OperationDTO[];
  organization: OperationDTO[];
  application: OperationDTO[];
  logsOperations: OperationDTO[];
}

@State({
  name: OPERATION_STATE_TOKEN,
  defaults: OperationState.defaultState
})
@Injectable()
export class OperationState {
  static defaultState: IOperationState = {
    platformRolesOperations: null,
    applicationParameters: null,
    operations: [],
    organization: [],
    application: [],
    logsOperations: [],
  };

  constructor(
    private readonly operationService: OperationService,
    private readonly applicationParameterService: ApplicationParameterService
  ) {
  }

  @Selector()
  static getPlatformRolesOperations(state: IOperationState) {
    return state.platformRolesOperations;
  }

  @Selector()
  static getApplicationOperations(state: IOperationState) {
    return state.application;
  }

  @Selector()
  static getApplicationParameters(state: IOperationState) {
    return state.applicationParameters;
  }

  @Selector()
  static getLogsOperations(state: IOperationState) {
    return state.logsOperations;
  }

  @Action(GetPlatformRolesOperationsAction)
  getPlatformRolesOperations({ patchState }: StateContext<IOperationState>) {
    return forkJoin([
      this.operationService.getRolesOperations(),
      this.applicationParameterService.getApplicationParameters()
    ]).pipe(
      first(),
      tap(([platformRolesOperations, applicationParameters]) => {
        patchState({ platformRolesOperations, applicationParameters });
      })
    );
  }

  @Action(GetApplicationParametersAction)
  getApplicationParameters({patchState}: StateContext<IOperationState>) {
    return this.applicationParameterService.getApplicationParameters().pipe(
      first(),
      tap(applicationParameters => {
        patchState({ applicationParameters })
      }),
      catchError(error => {
        if (error.status === 404) {
          patchState({ applicationParameters: []});
          return of();
        }
        return throwError(() => error);
      })
    )
  }

  @Action(GetOrganizationOperationsAction)
  getOrganizationOperations({ patchState }: StateContext<IOperationState>, { organizationID, queryString }: GetOrganizationOperationsAction) {
    return this.operationService.getOperationsByOrganizationId(organizationID, queryString).pipe(tap(operations => {
      patchState({
        organization: operations
      });
    }));
  }

  @Action(GetApplicationOperationsAction)
  getApplicationOperations({ patchState }: StateContext<IOperationState>, { applicationID }: GetApplicationOperationsAction) {
    return this.operationService.getOperationsByApplicationId(applicationID).pipe(tap(operations => {
      patchState({
        application: operations
      });
    }));
  }

  @Action(GetOperationForLogsAction)
  getLogsOperations({ patchState }: StateContext<IOperationState>, { applicationId, organizationId, studyId, username }: GetOperationForLogsAction) {
    return this.operationService.getLogsOperations(applicationId, organizationId, studyId, username).pipe(tap(logsOperations => {
      patchState({ logsOperations });
    }));
  }
}
