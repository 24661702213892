import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';
import { HttpService } from '../../../platform/services/http.service';
import { DiscussionItemPresenterDTO } from '../../dtos/discussion-item-presenter.dto';
import { DiscussionItemDTO } from '../../dtos/meeting-discussion-item.dto';
import { DiscussionItemService } from '../../services/discussion-item.service';
import { AddDiscussionItemAction, GetDiscussionItemsPresentersAction, GetDiscussionItemDetailAction, 
         GetDiscussionItemsAction, GetDiscussionItemsByMeetingAction, GetDiscussionItemsCountAction, 
         GetDiscussionItemsCountByMeetingAction, 
         GetDiscussionItemsAssociatedWithDecisionsAction,
         SaveDiscussionAction, DeleteDiscussionItemAction,
         UploadDiscussionItemDocumentAction,
         EditDiscussionItemDetailsAction,
         GetDiscussionItemDocumentsAction,
         GetDiscussionItemDocumentsCountAction,
         GetDiscussionItemDocumentAction,
         GetDiscussionItemDocumentBase64Action,
         GetAuditTrailDiscussionitemDocumentAction,
         GetAuditTrailDiscussionItemDocumentCountAction,
         DeleteDiscussionItemDocumentAction,
         UpdateMeetingDiscussionItemsOrderAction,
         DownloadDiscussionItemDocumentAction,
         DeleteDiscussionItemPollAction} from './discussion-items.actions';
import { DISCUSSION_ITEMS_STATE_TOKEN } from './discussion-items.token';
import { IStudyDocument } from 'src/app/dsmb/interface/document.interface';
import { DocumentBlobDTO } from '../../dtos/document-blob.dto';
import { PropagateUpdateDocument } from '../document/document.actions';


export class IDiscussionItemsState {
    discussionItems: DiscussionItemDTO[];
    discussionItemsCount: number;
    discussionItemsPresenters: DiscussionItemPresenterDTO[];
    meetingDiscussionItems: DiscussionItemDTO[];
    meetingDiscussionItemsCount: number;
    discussionItem: DiscussionItemDTO;
    discussionItemsAssociatedWithDecisions: DiscussionItemDTO[];
    discussionItemDocuments: IStudyDocument[];
    discussionItemDocumentsCount: number;
    discussionItemDocument: IStudyDocument;
    discussionItemDocumentBase64: any;
    discussionItemDocumentBlob: any; 
    discussionItemDocumentHistory: any;
    discussionItemDocumentHistoryCount: number;
    documentBlob: DocumentBlobDTO;
}

@State({
    name: DISCUSSION_ITEMS_STATE_TOKEN,
    defaults: DiscussionItemsState.defaultState
})
@Injectable()
export class DiscussionItemsState {
    static defaultState: IDiscussionItemsState = {
        discussionItems: [],
        discussionItemsCount: null,
        discussionItemsPresenters: [],
        meetingDiscussionItems: [],
        meetingDiscussionItemsCount: null,
        discussionItem: null,
        discussionItemsAssociatedWithDecisions: [],
        discussionItemDocuments:[],
        discussionItemDocumentsCount:null,
        discussionItemDocument: null,
        discussionItemDocumentBase64:null,
        discussionItemDocumentBlob: null,
        discussionItemDocumentHistory:[],
        discussionItemDocumentHistoryCount: null,
        documentBlob: null,
    };

    constructor(
        private readonly store: Store,
        private readonly httpService: HttpService,
        private readonly discussionItemService: DiscussionItemService
    ) { }

    @Selector()
    static getDiscussionItems(state: IDiscussionItemsState){
        return state.discussionItems;
    }

    @Selector()
    static getDiscussionItemsCount(state: IDiscussionItemsState){
        return state.discussionItemsCount;
    }

    @Selector()
    static getMeetingDiscussionItems(state: IDiscussionItemsState){
        return state.meetingDiscussionItems;
    }

    @Selector()
    static getMeetingDiscussionItemsCount(state: IDiscussionItemsState){
        return state.meetingDiscussionItemsCount;
    }

    @Selector()
    static getDiscussionItem(state: IDiscussionItemsState){
        return state.discussionItem;
    }

    @Selector()
    static getDiscussionItemsPresenters(state: IDiscussionItemsState) {
        return state.discussionItemsPresenters;
    }

    @Selector()
    static getDiscussionItemsAssociatedWithDecisions(state: IDiscussionItemsState) {
        return state.discussionItemsAssociatedWithDecisions;
    }

    @Selector()
    static getDiscussionItemDocuments(state: IDiscussionItemsState) {
        return state.discussionItemDocuments;
    }

    @Selector()
    static getDiscussionItemDocumentsCount(state: IDiscussionItemsState) {
        return state.discussionItemDocumentsCount;
    }

    @Selector()
    static getDiscussionItemDocument(state: IDiscussionItemsState) {
        return state.discussionItemDocument;
    }

    @Selector()
    static getDiscussionItemDocumentBase64(state: IDiscussionItemsState) {
        return state.discussionItemDocumentBase64;
    }

    @Selector()
    static getDiscussionItemDocumentBlob(state: IDiscussionItemsState) {
        return state.discussionItemDocumentBlob;
    }

    @Selector()
    static getDiscussionItemDocumentHistory(state: IDiscussionItemsState) {
        return state.discussionItemDocumentHistory;
    }

    @Selector()
    static getDiscussionItemDocumentHistoryCount(state: IDiscussionItemsState) {
        return state.discussionItemDocumentHistoryCount;
    }

    @Action(GetDiscussionItemDetailAction)
    getDiscussionItemDetail({ patchState }: StateContext<IDiscussionItemsState>, {organizationId, studyId, discussionItemId}: GetDiscussionItemDetailAction) {
        return this.discussionItemService.getDiscussionItemDetail(organizationId, studyId, discussionItemId).pipe(tap(discussionItem => {
            patchState({ discussionItem });
        }));
    }
    
    @Action(GetDiscussionItemsAction)
    getDiscussionItems({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, filter, startPage, itemsPerPage, sorting, flagUserUnavailable}: GetDiscussionItemsAction) {
        return this.discussionItemService.getDiscussionItems(organizationId, studyId, filter, startPage, itemsPerPage, sorting, flagUserUnavailable).pipe(tap(discussionItems => {
                patchState({ discussionItems });
            })
        );
    }

    @Action(GetDiscussionItemsCountAction)
    getDiscussionItemsCount({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, filter, flagUserUnavailable }: GetDiscussionItemsCountAction) { 
        return this.discussionItemService.getDiscussionItemsCount(organizationId, studyId, filter, flagUserUnavailable)
            .pipe(tap(discussionItemsCount => patchState({ discussionItemsCount })));
    
    }

    @Action(GetDiscussionItemsByMeetingAction)
    getDiscussionItemsByMeeting({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, meetingId, filter, startPage, itemsPerPage, sorting}: GetDiscussionItemsByMeetingAction) {
        return this.discussionItemService.getDiscussionItemsByMeeting(organizationId, studyId, meetingId, filter, startPage, itemsPerPage, sorting).pipe(tap(meetingDiscussionItems => {
                patchState({ meetingDiscussionItems });
            })
        );
    }

    @Action(GetDiscussionItemsCountByMeetingAction)
    getDiscussionItemsCountByMeeting({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, meetingId, filter }: GetDiscussionItemsCountByMeetingAction) { 
        return this.discussionItemService.getDiscussionItemsCountByMeeting(organizationId, studyId, meetingId, filter)
            .pipe(tap(meetingDiscussionItemsCount => patchState({ meetingDiscussionItemsCount })));
    
    }


    @Action(AddDiscussionItemAction)
    addDiscussionItem({ getState, setState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, newDiscussionItem }: AddDiscussionItemAction) { 
        return this.discussionItemService.addDiscussionItem(organizationId, studyId, newDiscussionItem).pipe(tap(discussionItem => {
            // Add new discussion Item to the list
            const state = getState();
            const discussionItemsList = [...state.discussionItems];
            discussionItemsList.push(discussionItem);

            setState({
                ...state,
                discussionItems: discussionItemsList,
            });
        }));
    }

    @Action(SaveDiscussionAction)
    addDiscussion({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, discussionItemId, discussion }: SaveDiscussionAction) { 
        return this.discussionItemService.addDiscussion(organizationId, studyId, discussionItemId, discussion).pipe(tap(discussionItem => {
            // Add new discussion Item to the list
           patchState({ discussionItem })
        }));
    }

    @Action(EditDiscussionItemDetailsAction)
    editDiscussionItemDetails({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, discussionItem }: EditDiscussionItemDetailsAction) { 
        return this.discussionItemService.editDiscussionItemDetails(organizationId, studyId, discussionItem).pipe(tap(discussionItem => {
            // Add new discussion Item to the list
           patchState({ discussionItem })
        }));
    }

    @Action(GetDiscussionItemsPresentersAction)
    getDiscussionItemsPresenters({ patchState }: StateContext<IDiscussionItemsState>, { organizationId,studyId, filter }: GetDiscussionItemsPresentersAction) {
        return this.discussionItemService.getDiscussionItemsPresenters(organizationId, studyId, filter).pipe(tap(discussionItemsPresenters => {
            patchState({ discussionItemsPresenters });
        }));
    }

    @Action(GetDiscussionItemsAssociatedWithDecisionsAction)
    getDiscussionItemsAssociatedWithDecisions({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, filter }: GetDiscussionItemsAssociatedWithDecisionsAction) {
        return this.discussionItemService.getDiscussionItemsAssociatedWithDecisions(organizationId, studyId, filter).pipe(tap(discussionItemsAssociatedWithDecisions => {
            patchState({ discussionItemsAssociatedWithDecisions });
        }));
    }

    @Action(UploadDiscussionItemDocumentAction)
    uploadDiscussionItemDocument({ getState, setState }: StateContext<IDiscussionItemsState>, { payload: { organizationId, studyDocument, studyId, documentId, versionNo, notify } }: UploadDiscussionItemDocumentAction) {
        return this.discussionItemService.uploadDiscussionItemDocument(organizationId, studyId, documentId, versionNo, notify, studyDocument).pipe(tap(document => {
            const state = getState();
            let discussionItemDocumentsList = [... state.discussionItemDocuments];
            discussionItemDocumentsList.push(document);
            const updatedCount = state.discussionItemDocumentsCount + 1;

            setState({
                ...state,
                discussionItemDocuments: discussionItemDocumentsList,
                discussionItemDocumentsCount: updatedCount,
            })
        }));
    }
    
    @Action(GetDiscussionItemDocumentsAction)
    getDiscussionItemDocuments({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, discussionItemId, meetingId }: GetDiscussionItemDocumentsAction) {
        return this.discussionItemService.getDiscussionItemDocuments(organizationId, studyId, discussionItemId, meetingId).pipe(tap(discussionItemDocuments => {
            patchState({discussionItemDocuments})
        }));
    }

    @Action(GetDiscussionItemDocumentsCountAction)
    getDiscussionItemDocumentsCount({patchState}: StateContext<IDiscussionItemsState>, {organizationId, studyId, discussionItemId, meetingId }: GetDiscussionItemDocumentsCountAction) {
        return this.discussionItemService.getdiscussionItemDocumentsCount(organizationId, studyId, discussionItemId, meetingId).pipe(tap(discussionItemDocumentsCount => {
            patchState({discussionItemDocumentsCount})
        }));
    }

    @Action(GetDiscussionItemDocumentAction)
    getDiscussionItemDocument({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, documentId, versionNo }: GetDiscussionItemDocumentAction){
        return this.discussionItemService.getDocument(organizationId, studyId, documentId, versionNo)
            .pipe(tap(discussionItemDocument => {
                patchState({ discussionItemDocument })
            }));
    }

    @Action(GetAuditTrailDiscussionitemDocumentAction)
    getAuditTrailDiscussionItemDocument({patchState}: StateContext<IDiscussionItemsState>, { payload: { organizationId, studyId, discussionItemId, documentId, startPage, itemsPerPage, sorting } }:GetAuditTrailDiscussionitemDocumentAction){
        return this.discussionItemService.getAuditTrailDiscussionItemDocument(organizationId, studyId, discussionItemId, documentId, startPage, itemsPerPage, sorting)  
          .pipe(tap(discussionItemDocumentHistory => patchState({ discussionItemDocumentHistory })));
    }

    @Action(GetAuditTrailDiscussionItemDocumentCountAction)
    getAuditTrailDiscussionItemDocumentCount({patchState}: StateContext<IDiscussionItemsState>, { payload: { organizationId, discussionItemId, studyId, documentId } }:GetAuditTrailDiscussionItemDocumentCountAction){
        return this.discussionItemService.getAuditTrailDiscussionItemDocumentCount(organizationId, studyId, discussionItemId, documentId )  
          .pipe(tap(discussionItemDocumentHistoryCount => patchState({ discussionItemDocumentHistoryCount })));
    }

    @Action(GetDiscussionItemDocumentBase64Action)
    getDiscussionItemDocumentBase64({ patchState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, documentId, versionNo }: GetDiscussionItemDocumentBase64Action) {
        return this.discussionItemService.getDocumentBase64(organizationId, studyId, documentId, versionNo)  
          .pipe(tap(discussionItemDocumentBase64 => patchState({ discussionItemDocumentBase64 })));
      }

    @Action(DownloadDiscussionItemDocumentAction)
    downloadDiscussionItemDocumet({ getState, setState }: StateContext<IDiscussionItemsState>, { organizationId, studyId, documentId, versionNo }: DownloadDiscussionItemDocumentAction) {
        return this.discussionItemService.downloadDocumentBlob(organizationId, studyId, documentId, versionNo)
        .pipe(tap(blob => {
            const state = getState();
            const temp: DocumentBlobDTO = {
                fileId: documentId,
                blob: blob
            };

            setState({
                ...state,
                documentBlob: temp,
                discussionItemDocumentBlob: temp,
            });
        }));
    }
    

    @Action(DeleteDiscussionItemAction)
    deleteDiscussionItemAction({getState, setState}: StateContext<IDiscussionItemsState>, { organizationId, studyId, discussionItemId, comment }: DeleteDiscussionItemAction){
        return this.discussionItemService.deleteDiscussionItem(organizationId, studyId, discussionItemId, comment).pipe(tap(_ =>{
            // Remove deleted discussion item from list
            const state = getState();
            let discussionItemList = [...state.discussionItems];
            discussionItemList = discussionItemList.filter(d => d.id !== discussionItemId);

            // Decrease the document count
            const updatedCount = state.discussionItemsCount - 1;
            setState({
                ...state,
                discussionItems: discussionItemList,
                discussionItemsCount: updatedCount,
            });
        })) 
    }

    @Action(DeleteDiscussionItemDocumentAction)
    deleteDiscussionItemDocumentAction({ getState, setState }: StateContext<IDiscussionItemsState>, { payload }: DeleteDiscussionItemDocumentAction) {
        return this.discussionItemService.deleteDiscussionItemDocument(payload.organizationId, payload.studyId, payload.meetingId, payload.discussionItemId, payload.documentId)
            .pipe(tap(_ => {
                // Remove the document from the dicscussionItemDocuments list if there is
                const state = getState();
                let documentList = [...state.discussionItemDocuments];
                if (documentList.length > 0) {
                    documentList = documentList.filter(d => d.id !== payload.documentId);
                }

                // Decrease the counter
                const updatedCount = state.discussionItemDocumentsCount -1;

                setState({
                    ...state,
                    discussionItemDocuments: documentList,
                    discussionItemDocumentsCount: updatedCount
                })

                this.store.dispatch(new PropagateUpdateDocument(payload.documentId));
            }))
    }

    @Action(UpdateMeetingDiscussionItemsOrderAction)
    updateMeetingDiscussionItemsOrder({ patchState }: StateContext<IDiscussionItemsState>, {organizationId, studyId, meetingId, discussionItems}: UpdateMeetingDiscussionItemsOrderAction) {
        return this.discussionItemService.updateMeetingDiscussionItemOrder(organizationId, studyId, meetingId, discussionItems).pipe(tap(discussionItems => {
            patchState({meetingDiscussionItems: discussionItems})
        }))
    }

    @Action(DeleteDiscussionItemPollAction)
    deleteDiscussionItemPollAction(_, { payload }: DeleteDiscussionItemPollAction) {
        return this.discussionItemService.deleteDiscussionItemPoll(payload.organizationId, payload.studyId, payload.meetingId, payload.discussionItemId, payload.pollId);
    }
}
