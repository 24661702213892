import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LastPasswordChangeDTO } from '../../dtos/last-password-change.dto';
import { LoginDTO } from '../../dtos/login.dto';
import { IRoleScope } from '../../interfaces/role.scope.interface';
import { IUser, User } from '../../model/user.model';
import { CookieService } from '../../services/cookie.service';
import { UserService } from '../../services/user.service';
import { deepClone, StringEmpty } from '../../utils/global-vars';
import { AUTH_STATE_TOKEN } from '../auth/auth.token';
import {
  ActivateUserAction,
  CompleteUserRegistrationAction,
  DeactivateUserAction,
  RemoveRoleFromUserAction,
  GetLastSelfChangePasswordAction,
  GetOrganizationUsersAction,
  GetPagedUsersAction,
  GetPasswordCodeAction,
  GetSelfLoginsAction,
  GetSelfLoginsCountAction,
  GetUserInfoAction,
  GetUserLastLoginAction,
  GetUsersAction,
  GetUsersCountAction,
  RegisterUserAction,
  RegisterUserCodeAction,
  ResendResetPasswordLinkAction,
  ResendRegisterLinkAction,
  SaveUserInfoAction,
  UpdateLoggedUserAction,
  UpdateUserAction,
  GetUserRolesAction,
  EmailChangeAction,
  GetUserLoginsAction,
  GetUserLoginsCountAction,
  ResendChangeEmailLinkAction,
  ActivateUserRoleAction,
  DeactivateUserRoleAction,
  GetOrganizationUsersCountAction,
  GetStudyUsersAction,
  ChangePasswordSelfAction,
  CompleteResetPasswordAction,
  GetUserLastChangePasswordAction,
  GetPanelUserLastChangePasswordAction,
  RefuseRegistrationUserAction,
  GetStudiesUsersAction
} from './user.actions';
import { USER_STATE_TOKEN } from './user.token';
import { DropdownOptionsEnum } from '../../utils/types';

export interface IUserState {
  loggedUser: IUser;
  users: IUser[];
  userRoles: IRoleScope[];
  organizationUsers: IUser[];
  organizationUsersCount: number;
  studyUsers: IUser[];
  studyUsersCount: number;
  pagedUsers: IUser[];
  lastChangePassword: LastPasswordChangeDTO;
  selfLogins: LoginDTO[];
  userLogins: LoginDTO[];
  loginsCount: number;
  usersCount: number;
  passwordCode: any;
  lastLoginDate: string;
  
}

@State({
  name: USER_STATE_TOKEN,
  defaults: UserState.defaultState
})
@Injectable()
export class UserState {
  static defaultState: IUserState = {
    loggedUser: new User(),
    users: [],
    userRoles: [],
    organizationUsers: [],
    organizationUsersCount: 0,
    studyUsers: [],
    studyUsersCount: 0,
    pagedUsers: [],
    lastChangePassword: null,
    selfLogins: [],
    userLogins: [],
    loginsCount: 0,
    usersCount: 0,
    passwordCode: null,
    lastLoginDate: StringEmpty
  };

  constructor(
    private readonly store: Store,
    private readonly userService: UserService,
    private readonly cookieService: CookieService,
    private readonly translationService: TranslateService
  ) { }

  @Selector()
  static getLoggedUser(state: IUserState) {
    return state.loggedUser;
  }

  @Selector()
  static getUsers(state: IUserState) {
    return state.users;
  }

  @Selector()
  static getUsersCount(state: IUserState) {
    return state.usersCount;
  }

  @Selector()
  static getLastLoginDate(state: IUserState) {
    return state.lastLoginDate;
  }

  @Selector()
  static getOrganizationUsers(state: IUserState) {
    return state.organizationUsers;
  }

  @Selector()
  static getOrganizationUsersCount(state: IUserState) {
    return state.organizationUsersCount;
  }

  @Selector()
  static getStudyUsers(state: IUserState) {
    return state.studyUsers;
  }

  @Selector()
  static getStudyUsersCount(state: IUserState) {
    return state.studyUsersCount;
  }

  @Selector()
  static getPagedUsers(state: IUserState) {
    return state.pagedUsers;
  }

  @Selector()
  static getUserRoles(state: IUserState) {
    return state.userRoles;
  }

  @Selector()
  static getSelfLogins(state: IUserState) {
    return state.selfLogins;
  }

  @Selector()
  static getSelfLoginsCount(state: IUserState) {
    return state.loginsCount;
  }

  @Selector()
  static getUserLogins(state: IUserState) {
    return state.userLogins;
  }

  @Selector()
  static getUserLoginsCount(state: IUserState) {
    return state.loginsCount;
  }
  
  @Selector()
  static getLastChangePassword(state: IUserState) {
    return state.lastChangePassword;
  }

  @Action(GetUserInfoAction)
  getUserInfoActionAction({ getState, dispatch }: StateContext<IUserState>, state: IUserState) {
    const { access_token } = this.store.selectSnapshot(AUTH_STATE_TOKEN);
    const loggedUser = getState().loggedUser;

    // Avoid calling BE for getting user info if username of object is defined.
    // This means that logged user is already been fetched
    if (access_token && !loggedUser.name) {
      return this.userService.getCurrentUser().pipe(
        switchMap(user => dispatch(new SaveUserInfoAction(user)))
      );
    }
  }
    

  @Action(SaveUserInfoAction)
  saveUserInfoAction({ patchState }: StateContext<IUserState>, { user }: SaveUserInfoAction) {
    patchState({ loggedUser: new User(user)});

    const cookieLang = this.cookieService.getCookie(environment.languageCookieName);
    if (user.language !== cookieLang) {
      this.cookieService.setCookie(environment.languageCookieName, user.language);
    }

    const cookieTimeZone = this.cookieService.getCookie(environment.timezoneCookieName);
    if (user.timezone !== cookieTimeZone) {
      this.cookieService.setCookie(environment.timezoneCookieName, user.timezone);
    }

    if (this.translationService.getLangs().includes(user.language)) {
      this.translationService.use(user.language);
    }
  }

  @Action(GetUsersAction)
  getUsersAction({ patchState }: StateContext<IUserState>) {
    return this.userService.getAllUsers().pipe(tap(users => {
      patchState({ users });
    }));
  }

  @Action(GetOrganizationUsersAction)
  getOrganizationUsersAction({ patchState }: StateContext<IUserState>, { organizationID, startPage, itemsPerPage, columnSort, filter }: GetOrganizationUsersAction) {
    if (organizationID === DropdownOptionsEnum.Any) {
      patchState({ organizationUsers: [] });
      patchState({ studyUsers: [] })
    } else {
      return this.userService.getAllUsersByOrganization(organizationID, startPage, itemsPerPage, columnSort, filter).pipe(tap(organizationUsers => {
        patchState({ organizationUsers });
      }));
    }
  }

  @Action(GetStudyUsersAction)
  getStudyUsersAction({ patchState }: StateContext<IUserState>, { organizationID, studyId, application }: GetStudyUsersAction) {
    if (studyId === DropdownOptionsEnum.Any) {
      patchState({ studyUsers: [] });
    } else {
      return this.userService.getAllUsersByStudy(organizationID, studyId, application ).pipe(tap(studyUsers => {
        patchState({ studyUsers });
      }));
    }
  }

  @Action(GetStudiesUsersAction)
  GetStudiesUsersAction({ patchState }: StateContext<IUserState>, { organizationID, studyId }: GetStudiesUsersAction) {
    //if ( !(studyId != null && studyId.length > 0) ) {
    //  const studyUsers = [];
    //  patchState({ studyUsers });
    //} else {
      return this.userService.getAllUsersByStudies(organizationID, studyId).pipe(tap(studyUsers => {
        patchState({ studyUsers: studyUsers || [] });
      }));
    //}
  }

  @Action(GetOrganizationUsersCountAction)
  getOrganizationUsersCountAction({ patchState }: StateContext<IUserState>, { organizationID, filter }: GetOrganizationUsersCountAction) {
    return this.userService.getAllUsersByOrganizationCount(organizationID, filter).pipe(tap(organizationUsersCount => {
      patchState({ organizationUsersCount });
    }));
  }

  @Action(GetPagedUsersAction)
  getPagedUsersAction({ patchState }: StateContext<IUserState>, { startPage, itemsPerPage, sorting, filter }: GetPagedUsersAction) {
    return this.userService.getAllUsersPaged(startPage, itemsPerPage, sorting, filter).pipe(
      map(pagedUsers => pagedUsers.map(user => new User(user))),
      tap(pagedUsers => patchState({ pagedUsers }))
    );
  }

  @Action(GetLastSelfChangePasswordAction)
  getLastSelfChangePasswordAction({ patchState }: StateContext<IUserState>) {
    return this.userService.getSelfLastPasswordChange().pipe(tap(lastChangePassword => {
      patchState({ lastChangePassword });
    }));
  }

  @Action(GetUserLastChangePasswordAction)
  getUserLastChangePasswordAction({ patchState }: StateContext<IUserState>, { username }: GetUserLastChangePasswordAction) {
    return this.userService.getUserLastPasswordChange(username).pipe(tap(lastChangePassword => {
      patchState({ lastChangePassword });
    }));
  }

  @Action(GetPanelUserLastChangePasswordAction)
  getPanelUserLastChangePasswordAction({ patchState }: StateContext<IUserState>, { organizationId, studyId, username }: GetPanelUserLastChangePasswordAction) {
    return this.userService.getPanelUserLastPasswordChange(organizationId, studyId, username).pipe(tap(lastChangePassword => { 
      patchState({ lastChangePassword });
    }));
  }

  @Action(GetPasswordCodeAction)
  getPasswordCodeAction({ patchState }: StateContext<IUserState>, { passwordCode }: GetPasswordCodeAction) {
    return this.userService.ResetPasswordCode(passwordCode).pipe(tap(response => {
      patchState({ passwordCode: response.passwordCode });
    }));
  }

  @Action(GetUserLastLoginAction)
  getUserLastLoginAction({ patchState }: StateContext<IUserState>) {
    return this.userService.getSelfLastLogin().pipe(
      map(x => x.timestamp),
      tap(lastLoginDate => patchState({ lastLoginDate }))
    );
  }

  @Action(GetSelfLoginsAction)
  getSelfLoginsAction({ patchState }: StateContext<IUserState>, { startPage, itemsPerPage, sorting }: GetSelfLoginsAction) {
    return this.userService.getSelfLogins(startPage, itemsPerPage, sorting).pipe(tap(selfLogins => {
      patchState({ selfLogins });
    }));
  }

  @Action(GetUserLoginsAction)
  getUserLoginsAction({ patchState }: StateContext<IUserState>, { payload: { username, pageData: { startPage, itemsPerPage, sorting }}}: GetUserLoginsAction) {
    // TODO: fix empty first call
    if (startPage && itemsPerPage) {
      return this.userService.getUserLogins(username, startPage, itemsPerPage, sorting).pipe(tap(userLogins => {
        patchState({ userLogins });
      }));
    }
  }

  @Action(GetUserLoginsCountAction)
  getUserLoginsCountAction({ patchState }: StateContext<IUserState>, { payload: { username} }: GetUserLoginsCountAction) {
    return this.userService.getUserLoginsCount(username).pipe(tap(loginsCount => {
      patchState({ loginsCount });
    }));
  }

  @Action(GetSelfLoginsCountAction)
  getSelfLoginsCountAction({ patchState }: StateContext<IUserState>) {
    return this.userService.getSelfLoginsCount().pipe(tap(loginsCount => {
      patchState({ loginsCount });
    }));
  }

  @Action(GetUsersCountAction)
  getUsersCountAction({ patchState }: StateContext<IUserState>, { filter }: GetUsersCountAction) {
    return this.userService.getUsersCount(filter).pipe(tap(usersCount => {
      patchState({ usersCount });
    }));
  }

  // This method is called only in the profile, for update self user. However if we simply do the SaveUserInfoAction
  // of what is returned from the call we are going to override information saved during login.
  // So we need to keep them and update only the information that can be updated
  @Action(UpdateLoggedUserAction)
  updateLoggedUser({ getState, patchState }: StateContext<IUserState>, { user }: UpdateLoggedUserAction) {
    return this.userService.updateCurrentUser(user).pipe(
      tap(updatedUser => {
        const state = getState();
        patchState({
          loggedUser: new User(
            {
              ...state.loggedUser,
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
              email: updatedUser.email,
              address: updatedUser.address,
              mobilePrefix: updatedUser.mobilePrefix,
              mobile: updatedUser.mobile,
              twoStepAuthEnabled: updatedUser.twoStepAuthEnabled,
              language: updatedUser.language,
              timezone: updatedUser.timezone,
              mobileConfirmed: updatedUser.mobileConfirmed,
              title: updatedUser.title
            }
          )
        });
      })
    );
  }

  @Action(UpdateUserAction)
  updateUser({ getState, setState }: StateContext<IUserState>, { username: username, user }: UpdateUserAction) {
    return this.userService.updateUser(username, user).pipe(
      tap(updatedUser => {
        const state = deepClone<IUserState>(getState());
        const updatedUserIndex = state.users.findIndex(u => u.name === username);
        state.users[updatedUserIndex] = updatedUser;

        setState(state);
      })
    );
  }

  @Action(ActivateUserAction)
  activateUserAction(_, { username }: ActivateUserAction) {
    return this.userService.activateUser(username);
  }

  @Action(DeactivateUserAction)
  deactivateUser(_, { username }: DeactivateUserAction) {
    return this.userService.deactivateUser(username);
  }

  @Action(RegisterUserAction)
  registerUserAction(_, { user }: RegisterUserAction) {
    return this.userService.registerUser(user);
  }

  @Action(RegisterUserCodeAction)
  registerUserCode(_, { code }: RegisterUserCodeAction) {
    return this.userService.registerUserCode(code);
  }

  @Action(RefuseRegistrationUserAction)
  refuseRegistrationUser(_, { code }: RefuseRegistrationUserAction) {
    return this.userService.refuseRegistrationUser(code);
  }

  @Action(ResendRegisterLinkAction)
  resendRegisterLinkAction(_, { email }: ResendRegisterLinkAction) {
    return this.userService.resendRegisterLink(email);
  }

  @Action(ResendChangeEmailLinkAction)
  resendChangeEmailLinkAction(_, { email }: ResendChangeEmailLinkAction) {
    return this.userService.resendChangeEmailLink(email);
  }

  @Action(CompleteUserRegistrationAction)
  completeUserRegistration(_, { code, completeRegistration }: CompleteUserRegistrationAction) {
    return this.userService.completeRegistrationUser(code, completeRegistration);
  }

  @Action(EmailChangeAction)
  EmailChangePassword(_, { email }: EmailChangeAction) {
    return this.userService.changeEmail(email);
  }

  @Action(ResendResetPasswordLinkAction)
  resendResetPasswordLink(_, { email }: ResendResetPasswordLinkAction) {
    return this.userService.resendResetPasswordLink(email);
  }

  @Action(DeactivateUserRoleAction)
  deactivateUserRole(_, { username, roleToDeactivate }: DeactivateUserRoleAction) {
    return this.userService.deactivateUserRole(username, roleToDeactivate);
  }

  @Action(ActivateUserRoleAction)
  activateUserRole(_, { username, roleToActivate }: ActivateUserRoleAction) {
    return this.userService.activateUserRole(username, roleToActivate);
  }

  @Action(RemoveRoleFromUserAction)
  removeRoleFromUser(_, { username, roleToRemove }: RemoveRoleFromUserAction) {
    return this.userService.removeRoleFromUser(username, roleToRemove);
  }

  @Action(GetUserRolesAction)
  getUserRolesAction({ patchState }: StateContext<IUserState>, { username }: GetUserRolesAction) {
    return this.userService.getUserRoles(username).pipe(tap(userRoles => {
      patchState({ userRoles });
    }));
  }

  @Action(ChangePasswordSelfAction)
  changePasswordSelfAction(_, { passwordData } : ChangePasswordSelfAction) {
    return this.userService.changePasswordSelf(passwordData);
  }

  @Action(CompleteResetPasswordAction)
  completeResetPasswordAction(_, { passwordData, ResetPasswordCode }: CompleteResetPasswordAction) {
    return this.userService.completeResetPassword(passwordData, ResetPasswordCode);
  }
}
