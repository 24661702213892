import { IAuthentication } from '../../interfaces/authentication.interface';
import { TwoFactorAuth } from '../../model/two-factor-authentication.model';

export class LoginAction {
  static readonly type = '[Auth] [Login]';

  constructor(public username: string, public password: string) {
  }
}

export class SSOLoginAction {
  static readonly type = '[Auth] [SSOLogin]';

  constructor(public jwtId: string) {
  }
}

export class LogoutAction {
  static readonly type = '[Auth] [Logout]';

  constructor(public redirectPath?: string) {
  }
}

export class SaveUserTokenAction {
  static readonly type = '[Auth] [POST] [UserToken]';

  constructor(public authData: IAuthentication, public update?: boolean) {
  }
}

export class TwoFactorAuthAction {
  static readonly type = '[Auth] [POST] [TwoFactor]';

  constructor(public twoFactorAuth: TwoFactorAuth) {
  }
}
