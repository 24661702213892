import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DiscussionItemId, MeetingId } from '../../platform/utils/types';
import { MeetingPollDTO } from '../dtos/meeting-poll';
import { MeetingPollHistoryDTO } from '../dtos/meeting-poll-history.dto';
import { MeetingPollSetDTO, MeetingPollsTotalDTO } from '../dtos/meeting-poll';
import { MeetingPollConfigurationDTO } from '../dtos/meeting-poll-configuration.dto';

@Injectable({
  providedIn: 'root'
})
export class MeetingPollsService {
  constructor(private readonly httpClient: HttpClient) { }

  getPollsToVote(organizationId:string, studyId: string, userId: string): Observable<MeetingPollDTO[]> {
    return this.httpClient.get<MeetingPollDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${userId}/polls/false`);
  }

  getPollsToVoteCount(organizationId:string, studyId: string, userId: string): Observable<number> {
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${userId}/polls/true`);
  }

  getMeetingPolls(organizationId:string, studyId: string, meetingId: MeetingId, startPage: number, itemsPerPage: number, columnSort: string): Observable<MeetingPollsTotalDTO> {
    let odataQueryString = `?$filter=MeetingId eq '${meetingId}'&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;

    if (columnSort !== null && columnSort !== undefined) {
      if (columnSort === 'discussionItemName desc'){
        columnSort = 'DiscussionItemId desc';
      }
      else if (columnSort === 'discussionItemName asc'){
        columnSort = 'DiscussionItemId desc';
      }
      else if (columnSort === '_creationDate desc') {
        columnSort = 'CreationDate desc';
      }
      else if (columnSort === '_creationDate asc') {
        columnSort = 'CreationDate asc';
      }
      else if (columnSort === '_expirationDate desc') {
        columnSort = 'ExpirationDate desc';
      }
      else if (columnSort === '_expirationDate asc') {
        columnSort = 'ExpirationDate asc';
      }

      odataQueryString += `&$orderby=${columnSort}`;
    }

    return this.httpClient.get<MeetingPollsTotalDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/polls${odataQueryString}`);
  }

  getDiscussionItemPolls(organizationId:string, studyId: string, meetingId: MeetingId, discussionItemId: DiscussionItemId, startPage: number, itemsPerPage: number, columnSort: string) : Observable<MeetingPollsTotalDTO> 
  {
    let odataQueryString = `?$filter=MeetingId eq '${meetingId}' and DiscussionItemId eq '${discussionItemId}'&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;

    if (columnSort !== null && columnSort !== undefined) {
      if (columnSort === 'discussionItemName desc'){
        columnSort = 'DiscussionItemId desc';
      }
      else if (columnSort === 'discussionItemName asc'){
        columnSort = 'DiscussionItemId desc';
      }
      else if (columnSort === '_creationDate desc') {
        columnSort = 'CreationDate desc';
      }
      else if (columnSort === '_creationDate asc') {
        columnSort = 'CreationDate asc';
      }
      else if (columnSort === '_expirationDate desc') {
        columnSort = 'ExpirationDate desc';
      }
      else if (columnSort === '_expirationDate asc') {
        columnSort = 'ExpirationDate asc';
      }

      odataQueryString += `&$orderby=${columnSort}`;
    }

    return this.httpClient.get<MeetingPollsTotalDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussionItem/${discussionItemId}/polls${odataQueryString}`);
  }
  getPoll(organizationId:string, studyId: string, meetingId: MeetingId, pollId: string): Observable<MeetingPollSetDTO> {
    return this.httpClient.get<MeetingPollSetDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/polls/${pollId}`);
  }

  addPoll(organizationId:string, studyId: string, meetingId: MeetingId, body: any): Observable<void> {
    return this.httpClient.post<void>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/submitPoll`, body);
  }

  addDraftPoll(organizationId:string, studyId: string, meetingId: MeetingId, body: any): Observable<void> {
    return this.httpClient.post<void>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/saveDraftPoll`, body);
  }

  votePoll(organizationId:string, studyId: string, meetingId: MeetingId, pollId: string, body: any): Observable<void> {
    return this.httpClient.post<void>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/polls/${pollId}/vote`, body);
  }

  deletePoll(organizationId:string, studyId: string, meetingId: MeetingId, pollId: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/polls/${pollId}`);
  }

  getMeetingPollHistory(organizationId:string, studyId: string, meetingId: MeetingId, pollId: string, startPage: number, itemsPerPage: number): Observable<MeetingPollHistoryDTO[]> {
    const filter = `$filter=Args1 eq '${pollId}' and Operation eq 'submit-meeting-poll' or Operation eq 'update-poll-expiration-date' or Operation eq 'vote-meeting-poll'`;
    const pagination = `$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;
    const order = `$orderby=Timestamp desc`;
    const odataQueryString = `?${filter}&${pagination}&${order}`;

    return this.httpClient.get<MeetingPollHistoryDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/poll/${pollId}/history/${odataQueryString}`);
  }

  getMeetingPollHistoryCount(organizationId:string, studyId: string, meetingId: MeetingId, pollId: string): Observable<number> {
    const filter = `$filter=Args1 eq '${pollId}' and Operation eq 'submit-meeting-poll' or Operation eq 'update-poll-expiration-date' or Operation eq 'vote-meeting-poll'`;
    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/poll/${pollId}/history/${odataQueryString}`);
  }

  getMeetingPollCount(organizationId:string, studyId: string, meetingId: MeetingId): Observable<number> {
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/poll/count`);
  }

  getMeetingPollConfiguration(organizationId: string, studyId: string): Observable<MeetingPollConfigurationDTO[]>{
    return this.httpClient.get<MeetingPollConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting-polls/configuration`)
  }

  updateMeetingPollConfiguration(organizationId: string, studyId: string, meetingPolls : MeetingPollConfigurationDTO[]): Observable<MeetingPollConfigurationDTO[]> {
    return this.httpClient.post<MeetingPollConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/update-meeting-polls-config`, meetingPolls);
  }

  public removeMeetingPollConfiguration(organizationId: string, studyId: string): Observable<any> {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/remove-meeting-polls-config`);
  }
}
