export const defaultState = {
    timezones: [
        {
            name: '(UTC-12:00) International Date Line West',
            value: 'Etc/GMT+12'
        },
        {
            name: '(UTC-11:00) Coordinated Universal Time-11',
            value: 'Etc/GMT+11'
        },
        {
            name: '(UTC-10:00) Aleutian Islands',
            value: 'America/Adak'
        },
        {
            name: '(UTC-10:00) Hawaii',
            value: 'Pacific/Honolulu'
        },
        {
            name: '(UTC-09:30) Marquesas Islands',
            value: 'Pacific/Marquesas'
        },
        {
            name: '(UTC-09:00) Alaska',
            value: 'America/Anchorage'
        },
        {
            name: '(UTC-09:00) Coordinated Universal Time-09',
            value: 'Etc/GMT+9'
        },
        {
            name: '(UTC-07:00) Yukon',
            value: 'America/Whitehorse'
        },
        {
            name: '(UTC-08:00) Baja California',
            value: 'America/Tijuana'
        },
        {
            name: '(UTC-08:00) Coordinated Universal Time-08',
            value: 'Etc/GMT+8'
        },
        {
            name: '(UTC-08:00) Pacific Time (US & Canada)',
            value: 'America/Los_Angeles'
        },
        {
            name: '(UTC-07:00) Arizona',
            value: 'America/Phoenix'
        },
        {
            name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
            value: 'America/Chihuahua'
        },
        {
            name: '(UTC-07:00) Mountain Time (US & Canada)',
            value: 'America/Denver'
        },
        {
            name: '(UTC-06:00) Central America',
            value: 'America/Guatemala'
        },
        {
            name: '(UTC-06:00) Central Time (US & Canada)',
            value: 'America/Chicago'
        },
        {
            name: '(UTC-06:00) Easter Island',
            value: 'Pacific/Easter'
        },
        {
            name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
            value: 'America/Mexico_City'
        },
        {
            name: '(UTC-06:00) Saskatchewan',
            value: 'America/Regina'
        },
        {
            name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
            value: 'America/Bogota'
        },
        {
            name: '(UTC-05:00) Chetumal',
            value: 'America/Cancun'
        },
        {
            name: '(UTC-05:00) Eastern Time (US & Canada)',
            value: 'America/New_York'
        },
        {
            name: '(UTC-05:00) Haiti',
            value: 'America/Port-au-Prince'
        },
        {
            name: '(UTC-05:00) Havana',
            value: 'America/Havana'
        },
        {
            name: '(UTC-05:00) Indiana (East)',
            value: 'America/Indiana/Indianapolis'
        },
        {
            name: '(UTC-05:00) Turks and Caicos',
            value: 'America/Grand_Turk'
        },
        {
            name: '(UTC-04:00) Asuncion',
            value: 'America/Asuncion'
        },
        {
            name: '(UTC-04:00) Atlantic Time (Canada)',
            value: 'America/Halifax'
        },
        {
            name: '(UTC-04:00) Caracas',
            value: 'America/Caracas'
        },
        {
            name: '(UTC-04:00) Cuiaba',
            value: 'America/Cuiaba'
        },
        {
            name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
            value: 'America/La_Paz'
        },
        {
            name: '(UTC-04:00) Santiago',
            value: 'America/Santiago'
        },
        {
            name: '(UTC-03:30) Newfoundland',
            value: 'America/St_Johns'
        },
        {
            name: '(UTC-03:00) Araguaina',
            value: 'America/Araguaina'
        },
        {
            name: '(UTC-03:00) Brasilia',
            value: 'America/Sao_Paulo'
        },
        {
            name: '(UTC-03:00) Cayenne, Fortaleza',
            value: 'America/Cayenne'
        },
        {
            name: '(UTC-03:00) City of Buenos Aires',
            value: 'America/Argentina/Buenos_Aires'
        },
        {
            name: '(UTC-03:00) Greenland',
            value: 'America/Nuuk'
        },
        {
            name: '(UTC-03:00) Montevideo',
            value: 'America/Montevideo'
        },
        {
            name: '(UTC-03:00) Punta Arenas',
            value: 'America/Punta_Arenas'
        },
        {
            name: '(UTC-03:00) Saint Pierre and Miquelon',
            value: 'America/Miquelon'
        },
        {
            name: '(UTC-03:00) Salvador',
            value: 'America/Bahia'
        },
        {
            name: '(UTC-02:00) Coordinated Universal Time-02',
            value: 'Etc/GMT+2'
        },
        {
            name: '(UTC-01:00) Azores',
            value: 'Atlantic/Azores'
        },
        {
            name: '(UTC-01:00) Cabo Verde Is.',
            value: 'Atlantic/Cape_Verde'
        },
        {
            name: '(UTC) Coordinated Universal Time',
            value: 'Etc/UTC'
        },
        {
            name: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
            value: 'Europe/London'
        },
        {
            name: '(UTC+00:00) Monrovia, Reykjavik',
            value: 'Atlantic/Reykjavik'
        },
        {
            name: '(UTC+00:00) Sao Tome',
            value: 'Africa/Sao_Tome'
        },
        {
            name: '(UTC+01:00) Casablanca',
            value: 'Africa/Casablanca'
        },
        {
            name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
            value: 'Europe/Berlin'
        },
        {
            name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
            value: 'Europe/Budapest'
        },
        {
            name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
            value: 'Europe/Paris'
        },
        {
            name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
            value: 'Europe/Warsaw'
        },
        {
            name: '(UTC+01:00) West Central Africa',
            value: 'Africa/Lagos'
        },
        {
            name: '(UTC+02:00) Amman',
            value: 'Asia/Amman'
        },
        {
            name: '(UTC+02:00) Athens, Bucharest',
            value: 'Europe/Bucharest'
        },
        {
            name: '(UTC+02:00) Beirut',
            value: 'Asia/Beirut'
        },
        {
            name: '(UTC+02:00) Cairo',
            value: 'Africa/Cairo'
        },
        {
            name: '(UTC+02:00) Chisinau',
            value: 'Europe/Chisinau'
        },
        {
            name: '(UTC+02:00) Damascus',
            value: 'Asia/Damascus'
        },
        {
            name: '(UTC+02:00) Gaza, Hebron',
            value: 'Asia/Hebron'
        },
        {
            name: '(UTC+02:00) Harare, Pretoria',
            value: 'Africa/Johannesburg'
        },
        {
            name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
            value: 'Europe/Kiev'
        },
        {
            name: '(UTC+02:00) Jerusalem',
            value: 'Asia/Jerusalem'
        },
        {
            name: '(UTC+02:00) Kaliningrad',
            value: 'Europe/Kaliningrad'
        },
        {
            name: '(UTC+02:00) Khartoum',
            value: 'Africa/Khartoum'
        },
        {
            name: '(UTC+02:00) Tripoli',
            value: 'Africa/Tripoli'
        },
        {
            name: '(UTC+02:00) Windhoek',
            value: 'Africa/Windhoek'
        },
        {
            name: '(UTC+03:00) Baghdad',
            value: 'Asia/Baghdad'
        },
        {
            name: '(UTC+03:00) Istanbul',
            value: 'Europe/Istanbul'
        },
        {
            name: '(UTC+03:00) Kuwait, Riyadh',
            value: 'Asia/Riyadh'
        },
        {
            name: '(UTC+03:00) Minsk',
            value: 'Europe/Minsk'
        },
        {
            name: '(UTC+03:00) Moscow, St. Petersburg',
            value: 'Europe/Moscow'
        },
        {
            name: '(UTC+03:00) Nairobi',
            value: 'Africa/Nairobi'
        },
        {
            name: '(UTC+03:30) Tehran',
            value: 'Asia/Tehran'
        },
        {
            name: '(UTC+04:00) Abu Dhabi, Muscat',
            value: 'Asia/Dubai'
        },
        {
            name: '(UTC+04:00) Astrakhan, Ulyanovsk',
            value: 'Europe/Astrakhan'
        },
        {
            name: '(UTC+04:00) Baku',
            value: 'Asia/Baku'
        },
        {
            name: '(UTC+04:00) Izhevsk, Samara',
            value: 'Europe/Samara'
        },
        {
            name: '(UTC+04:00) Port Louis',
            value: 'Indian/Mauritius'
        },
        {
            name: '(UTC+04:00) Saratov',
            value: 'Europe/Saratov'
        },
        {
            name: '(UTC+04:00) Tbilisi',
            value: 'Asia/Tbilisi'
        },
        {
            name: '(UTC+04:00) Volgograd',
            value: 'Europe/Volgograd'
        },
        {
            name: '(UTC+04:00) Yerevan',
            value: 'Asia/Yerevan'
        },
        {
            name: '(UTC+04:30) Kabul',
            value: 'Asia/Kabul'
        },
        {
            name: '(UTC+05:00) Ashgabat, Tashkent',
            value: 'Asia/Tashkent'
        },
        {
            name: '(UTC+05:00) Ekaterinburg',
            value: 'Asia/Yekaterinburg'
        },
        {
            name: '(UTC+05:00) Islamabad, Karachi',
            value: 'Asia/Karachi'
        },
        {
            name: '(UTC+05:00) Qyzylorda',
            value: 'Asia/Qyzylorda'
        },
        {
            name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
            value: 'Asia/Kolkata'
        },
        {
            name: '(UTC+05:30) Sri Jayawardenepura',
            value: 'Asia/Colombo'
        },
        {
            name: '(UTC+05:45) Kathmandu',
            value: 'Asia/Kathmandu'
        },
        {
            name: '(UTC+06:00) Astana',
            value: 'Asia/Almaty'
        },
        {
            name: '(UTC+06:00) Dhaka',
            value: 'Asia/Dhaka'
        },
        {
            name: '(UTC+06:00) Omsk',
            value: 'Asia/Omsk'
        },
        {
            name: '(UTC+06:30) Yangon (Rangoon)',
            value: 'Asia/Yangon'
        },
        {
            name: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
            value: 'Asia/Bangkok'
        },
        {
            name: '(UTC+07:00) Barnaul, Gorno-Altaysk',
            value: 'Asia/Barnaul'
        },
        {
            name: '(UTC+07:00) Hovd',
            value: 'Asia/Hovd'
        },
        {
            name: '(UTC+07:00) Krasnoyarsk',
            value: 'Asia/Krasnoyarsk'
        },
        {
            name: '(UTC+07:00) Novosibirsk',
            value: 'Asia/Novosibirsk'
        },
        {
            name: '(UTC+07:00) Tomsk',
            value: 'Asia/Tomsk'
        },
        {
            name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
            value: 'Asia/Shanghai'
        },
        {
            name: '(UTC+08:00) Irkutsk',
            value: 'Asia/Irkutsk'
        },
        {
            name: '(UTC+08:00) Kuala Lumpur, Singapore',
            value: 'Asia/Singapore'
        },
        {
            name: '(UTC+08:00) Perth',
            value: 'Australia/Perth'
        },
        {
            name: '(UTC+08:00) Taipei',
            value: 'Asia/Taipei'
        },
        {
            name: '(UTC+08:00) Ulaanbaatar',
            value: 'Asia/Ulaanbaatar'
        },
        {
            name: '(UTC+08:45) Eucla',
            value: 'Australia/Eucla'
        },
        {
            name: '(UTC+09:00) Chita',
            value: 'Asia/Chita'
        },
        {
            name: '(UTC+09:00) Osaka, Sapporo, Tokyo',
            value: 'Asia/Tokyo'
        },
        {
            name: '(UTC+09:00) Pyongyang',
            value: 'Asia/Pyongyang'
        },
        {
            name: '(UTC+09:00) Seoul',
            value: 'Asia/Seoul'
        },
        {
            name: '(UTC+09:00) Yakutsk',
            value: 'Asia/Yakutsk'
        },
        {
            name: '(UTC+09:30) Adelaide',
            value: 'Australia/Adelaide'
        },
        {
            name: '(UTC+09:30) Darwin',
            value: 'Australia/Darwin'
        },
        {
            name: '(UTC+10:00) Brisbane',
            value: 'Australia/Brisbane'
        },
        {
            name: '(UTC+10:00) Canberra, Melbourne, Sydney',
            value: 'Australia/Sydney'
        },
        {
            name: '(UTC+10:00) Guam, Port Moresby',
            value: 'Pacific/Port_Moresby'
        },
        {
            name: '(UTC+10:00) Hobart',
            value: 'Australia/Hobart'
        },
        {
            name: '(UTC+10:00) Vladivostok',
            value: 'Asia/Vladivostok'
        },
        {
            name: '(UTC+10:30) Lord Howe Island',
            value: 'Australia/Lord_Howe'
        },
        {
            name: '(UTC+11:00) Bougainville Island',
            value: 'Pacific/Bougainville'
        },
        {
            name: '(UTC+11:00) Chokurdakh',
            value: 'Asia/Srednekolymsk'
        },
        {
            name: '(UTC+11:00) Magadan',
            value: 'Asia/Magadan'
        },
        {
            name: '(UTC+11:00) Norfolk Island',
            value: 'Pacific/Norfolk'
        },
        {
            name: '(UTC+11:00) Sakhalin',
            value: 'Asia/Sakhalin'
        },
        {
            name: '(UTC+11:00) Solomon Is., New Caledonia',
            value: 'Pacific/Guadalcanal'
        },
        {
            name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
            value: 'Asia/Kamchatka'
        },
        {
            name: '(UTC+12:00) Auckland, Wellington',
            value: 'Pacific/Auckland'
        },
        {
            name: '(UTC+12:00) Coordinated Universal Time+12',
            value: 'Etc/GMT-12'
        },
        {
            name: '(UTC+12:00) Fiji',
            value: 'Pacific/Fiji'
        },
        {
            name: '(UTC+12:45) Chatham Islands',
            value: 'Pacific/Chatham'
        },
        {
            name: '(UTC+13:00) Coordinated Universal Time+13',
            value: 'Etc/GMT-13'
        },
        {
            name: '(UTC+13:00) Nuku\'alofa',
            value: 'Pacific/Tongatapu'
        },
        {
            name: '(UTC+13:00) Samoa',
            value: 'Pacific/Apia'
        },
        {
            name: '(UTC+14:00) Kiritimati Island',
            value: 'Pacific/Kiritimati'
        }
    ]
}
