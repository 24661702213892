import { DiscussionItemId, MeetingId } from '../../../platform/utils/types';
import { MeetingPollConfigurationDTO } from '../../dtos/meeting-poll-configuration.dto';

export class GetMeetingPollsForDiscussionItemAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Polls For Discussion Item]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public discussionItemId: DiscussionItemId,
        public startPage?: number,
        public itemsPerPage?: number ) {
    }
}


export class GetEditMeetingPollAction {
    static readonly type = '[Meeting Poll] [GET] [Get Edit Meeting Poll]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public pollId: string) {
    }
}

export class GetMeetingPollHistoryAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Poll History]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public pollId: string,
        public startPage?: number,
        public itemsPerPage?: number) {
    }
}

export class GetMeetingPollHistoryCountAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Poll History Count]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public pollId: string) {
    }
}

export class GetMeetingPollConfigurationAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Poll Configuration]';

    constructor(
        public organizationId:string,
        public studyId: string) {
    }
}

export class UpdateMeetingPollConfigurationAction {
    static readonly type = '[Meeting] [POST] [Update Meeting Poll Configuration]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingPolls: MeetingPollConfigurationDTO[]
    ) {}
}

export class GetMeetingPollsToVoteAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Polls To Vote]';
    constructor(
        public organizationId:string,
        public studyId: string, 
        public userId: string) {
    }
}

export class GetMeetingPollsToVoteCountAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Poll to Vote Count]';
    constructor(
        public organizationId:string,
        public studyId: string, 
        public userId: string){
        
    }

    
}

export class GetMeetingPollCountAction {
    static readonly type = '[Meeting Poll] [GET] [Get Meeting Poll Count]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId,
        startPage?: number, 
        itemsPerPage?: number) {
    }
}
