import { NgModule } from '@angular/core';
import { IdleComponent } from './idle.component';
import { NgIdleModule } from '@ng-idle/core';



@NgModule({
  declarations: [ IdleComponent ],
  imports: [ NgIdleModule.forRoot(), ],
  exports: [ IdleComponent ]
})
export class IdleModule { 


}
