import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as moment from 'moment';
import { catchError, takeWhile, tap } from 'rxjs/operators';
import { DateFormatEnum } from '../../enum/dates-format.enum';
import { LogResponseDTO } from '../../dtos/log-response.dto';
import { AuthService } from '../../services/auth.service';
import { FilesService } from '../../services/files.service';
import { LoggingService } from '../../services/logging.service';
import { deepClone } from '../../utils/global-vars';
import { DownloadEmailAsMsg, ExportLogsAction, ExportOrganizationLogsAction, ExportStudyLogsAction, GetLogsAction, GetLogsCountAction, GetOrganizationLogsAction, GetOrganizationLogsCountAction, PostLogAction } from './log.actions';
import { LOG_STATE_TOKEN } from './log.token';
import { DocumentBlobDTO } from 'src/app/dsmb/dtos/document-blob.dto';

export interface ILogState {
  logs: LogResponseDTO[];
  organization: {
    [id: string]: LogResponseDTO[];
  };
  count: {
    filter: number;
    organization: {
      [id: string]: {
        filter: {
          [value: string]: number
        }
      }
    };
  };
  emailBlob: DocumentBlobDTO;
}

@State({
  name: LOG_STATE_TOKEN,
  defaults: LogState.defaultState
})
@Injectable()
export class LogState {
  static defaultState: ILogState = {
    logs: [],
    organization: {},
    count: {
      filter: 0,
      organization: {}
    },
    emailBlob: null
  };

  constructor(
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService,
    private readonly filesService: FilesService
  ) {
  }

  @Selector()
  static getLogs(state: ILogState) {
    return state.logs;
  }

  @Selector()
  static getLogsCount(state: ILogState) {
    return state.count.filter;
  }

  @Action(GetLogsAction)
  getLogsAction({ patchState }: StateContext<ILogState>, { applicationId, startPage, itemsPerPage, sorting, filter }: GetLogsAction) {
    return this.loggingService.getLogs(applicationId, startPage, itemsPerPage, sorting, filter).pipe(tap(logs => {
      patchState({ logs });
    }), catchError((error: HttpErrorResponse) => {
      if (error.status === 404) {
        patchState({ logs: [] });
      }

      throw error;
    }));
  }

  @Action(GetOrganizationLogsAction)
  getOrganizationLogs({ getState, setState }: StateContext<ILogState>, {
    applicationID,
    organizationID,
    startPage,
    itemsPerPage,
    sorting,
    filter
  }: GetOrganizationLogsAction) {
    return this.loggingService.getLogsByOrganizationId(applicationID,
      organizationID,
      startPage,
      itemsPerPage,
      sorting, filter).pipe(tap(logs => {
        const state = deepClone<ILogState>(getState());
        state.organization[organizationID] = logs;

        setState(state);
    }));
  }

  @Action(GetLogsCountAction)
  getLogsCount({ getState, setState }: StateContext<ILogState>, { applicationID,  filter }: GetLogsCountAction) {
    return this.loggingService.getLogsCount(applicationID, filter).pipe(tap(count => {
      const state = deepClone<ILogState>(getState());
      state.count.filter = count;

      setState(state);
    }));
  }

  @Action(GetOrganizationLogsCountAction)
  getOrganizationLogsCount({ getState, setState }: StateContext<ILogState>,
                           { applicationID, organizationID, filter }: GetOrganizationLogsCountAction) {
    return this.loggingService.getLogsCountByOrganizationId(applicationID, organizationID, filter).pipe(tap(count => {
      const state = deepClone<ILogState>(getState());
      state.count.organization[organizationID].filter[filter] = count;

      setState(state);
    }));
  }

  @Action(PostLogAction)
  postLog(_, { log }: PostLogAction) {
    return this.loggingService.writeLog(log);
  }

  @Action(ExportLogsAction)
  exportLogs(_, { applicationID, sorting, filter, config }: ExportLogsAction) {
    return this.loggingService.exportLogs(applicationID, sorting, filter, config )
    .pipe(
      takeWhile(() => this.authService.userIsLogged()),
      tap(response => {
      const timestamp = moment().format(DateFormatEnum.YMDhms);
      let filename = `fullaudittrail_${timestamp}.csv`;

      if (response.body.type === 'application/pdf')
      {
        filename = `fullaudittrail_${timestamp}.pdf`;
      }

      this.filesService.download(response, filename).subscribe();
    }));
  }

  @Action(ExportOrganizationLogsAction)
  exportOrganizationLogs(_, {
    applicationID,
    organizationID,
    sorting,
    filter,
    config
  }: ExportOrganizationLogsAction) {
    return this.loggingService.exportOrganizationLogs(organizationID, applicationID, sorting, filter, config )
    .pipe(
      takeWhile(() => this.authService.userIsLogged()),
      tap(response => {
      if (response) {
        const timestamp = moment().format(DateFormatEnum.YMDhms);
        let filename = `fullaudittrail_${timestamp}.csv`;

        if (response.body.type === 'application/pdf')
        {
          filename = `fullaudittrail_${timestamp}.pdf`;
        }

        this.filesService.download(response, filename).subscribe();
      }
    }));
  }

  @Action(ExportStudyLogsAction)
  exportStudyLogs(_, {
    organizationId,
    studyId,
    applicationID,
    sorting,
    filter,
    config
  }: ExportStudyLogsAction) {
    return this.loggingService.exportStudyApplicationLogs(organizationId, studyId, applicationID, sorting, filter, config )
    .pipe(
      takeWhile(() => this.authService.userIsLogged()),
      tap(response => {
      if (response) {
        const timestamp = moment().format(DateFormatEnum.YMDhms);
        let filename = `fullaudittrail_${timestamp}.csv`;

        if (response.body.type === 'application/pdf')
        {
          filename = `fullaudittrail_${timestamp}.pdf`;
        }

        this.filesService.download(response, filename).subscribe();
      }
    }));
  }


  @Action(DownloadEmailAsMsg)
  downloadEmailAsMsgAction({ getState, setState }: StateContext<ILogState>,  { application, emailTitle, emailBody }: DownloadEmailAsMsg ){
    return this.loggingService.downlaodEmailAsMsg(application, emailTitle, emailBody)
    .pipe(tap(blob => {
      const state = getState();
      const temp: DocumentBlobDTO = {
          fileId: '',
          blob: blob
      };

      setState({
          ...state,
          emailBlob: temp,
      });
  }));
  }

}