import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IStudyDocument } from "../interface/document.interface";
import { environment } from "src/environments/environment";
import { map, Observable } from "rxjs";
import { StringEmpty } from "src/app/platform/utils/global-vars";
import { DocumentHistoryDTO } from "../dtos/document-history.dto";
import { IUser } from "src/app/platform/model/user.model";
import { IUserTask } from "../interface/task.interface";

@Injectable({
    providedIn: 'root'
})
export class TasksService {

    constructor(
        private readonly httpClient: HttpClient
    ) { }

    public changeTaskStatus(organizationId: string, studyId: string, taskId: string, payload: any): Observable<IUserTask> {
        return this.httpClient.post<IUserTask>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/change-status`, payload);
    }

    public uploadTaskDocument(organizationId: string, studyId: string, documentId: string, versionNo: number, document: any): Observable<IStudyDocument> {
        var endpointUrl = `${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}`;
        const meetingId = document.get('meetingId');
        if (meetingId) {
            endpointUrl += `/meeting/${meetingId}`;
        }
        const discussionItemId = document.get('discussionItemId');
        if (discussionItemId) {
            endpointUrl += `/discussion-item/${discussionItemId}`;
        }
        const decisionid = document.get('decisionId');
        if (decisionid) {
            endpointUrl += `/decision/${decisionid}`;
        }
        const taskId = document.get('taskId');
        endpointUrl += `/task/${taskId}/upload/${documentId}${versionNo > 0 ? `/file/${versionNo}/` : StringEmpty}`;
        return this.httpClient.post<IStudyDocument>(endpointUrl, document)
        .pipe(map( document => {
            return {
            ...(document.lastVersion || document),
            children: document.oldVersions || [],
            open: false,
            };
        }));
    }

    public getTaskDocuments(organizationId: string, studyId: string, taskId: string): Observable<IStudyDocument[]> {
        return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/documents`)
        .pipe(this.mapDocuments());
    }

    public getTaskDocumentsCount(organizationId:string, studyId:string, taskId:string): Observable<number>{
        return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/documents?$count=true`)
    }

    public getDocument(organizationId:string, studyId:string, documentId:string, versionNo:string){
        return this.httpClient.get<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/document/${documentId}/version/${versionNo}/view`)
    }

    public getDocumentBase64(organizationId: string, studyId:string, documentId:string, versionNo:string){
        return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/document/${documentId}/version/${versionNo}?isBase64=true`, {
          responseType: 'text'})
    }

    public downloadDocumentBlob(organizationId: string, studyId:string, documentId:string, versionId: string) {
        return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/document/${documentId}/version/${versionId}?isBase64=false`, {
          responseType: 'blob'
        });
    }

    public deleteTaskDocument(organizationId:string, studyId: string, taskId:string, documentId: string) {
        var endpointUrl = `${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}`;
        endpointUrl += `/task/${taskId}/document/${documentId}`;
        return this.httpClient.delete(`${endpointUrl}`);
    }

    public getAuditTrailTaskDocument(organizationId: string, studyId: string, documentId: string, startPage: number, itemsPerPage: number, sorting: string){
        const filter = `$filter=Args1 eq '${documentId}' and Operation eq 'download-task-document' or Operation eq 'view-task-document' or Operation eq 'upload-task-document' or Operation eq 'update-task-document'`;
        const order = `$orderby=Timestamp desc`;
        let odataQueryString = `?${filter}&${order}`;
        const isStudyDocument = false; //ALERT
        const params = [];
    
        if (itemsPerPage !== null && itemsPerPage !== undefined) {
          params.push(`$top=${itemsPerPage}`);
        }
    
        if (startPage !== null && startPage !== undefined) {
          params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
        }
    
        const temp = params.join('&');
        odataQueryString += `&${temp}`;
        return this.httpClient.get<DocumentHistoryDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
    }

    public getAuditTrailTaskDocumentCount(organizationId:string, studyId:string, documentId:string){
        const filter = `?$filter=Args1 eq '${documentId}' and Operation eq 'download-task-document' or Operation eq 'view-task-document' or Operation eq 'upload-task-document' or Operation eq 'update-task-document'`;
        const odataQueryString = filter + `&$count=true`;
        const isStudyDocument = false;
        return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
    }

    public getTaskPossibleAssignees(organizationId: string, studyId: string): Observable<IUser[]> { 
        return this.httpClient.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/possible-assignees`);
    }

    public sendTaskReminderNotification(organizationId: string, studyId: string, taskId: string, payload: any) {
        return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/remind`, payload);
    }


    private mapDocuments() {
        return map((documents: any[]) => {
          return documents.map(document => {
            if (!document.oldVersions) {
              console.error('document.oldVersions is empty');
            }
    
            return {
              ...(document.lastVersion || document),
              children: document.oldVersions || [],
              open: false,
            };
          });
        });
    }
}