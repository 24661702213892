import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WINDOW } from '../utils/browser-api';
import { StringEmpty } from '../utils/global-vars';
import { HttpHeaderEnum, HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(WINDOW) private readonly window,
    @Inject(DOCUMENT) private readonly document
  ) { }

  public download(item: HttpResponse<Blob> | Blob, name?: string): Observable<void> {
    let documentBlob;
    let filename = StringEmpty;
    let contentType = StringEmpty;

    if (item instanceof HttpResponse) {
      const headers = this.httpService.readResponseHeaders(item);

      contentType = headers.get(HttpHeaderEnum.contentType);
      filename = name || headers.get(`${HttpHeaderEnum.contentDisposition}.filename`);

      documentBlob = item.body;
    } else {
      contentType = item.type;
      filename = name;

      documentBlob = item;
    }

    return new Observable(observer => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new this.window.Blob([documentBlob], { type: contentType });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveBlob
      if (this.window.navigator.msSaveBlob) {
        this.window.navigator.msSaveBlob(newBlob, filename);
        return;
      }

      const url = this.window.URL.createObjectURL(newBlob);
      const tempLink = this.document.createElement('a');

      tempLink.style.display = 'none';
      tempLink.setAttribute('href', url);
      tempLink.setAttribute('download', filename);

      // Safari thinks _blank anchor are popups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      this.document.body.appendChild(tempLink);
      tempLink.click();
      this.document.body.removeChild(tempLink);

      this.window.setTimeout(() => {
        // For Firefox, it is necessary to delay revoking the ObjectURL
        this.window.URL.revokeObjectURL(url);

        observer.next();
      }, 100);
    });
  }
}
