import { Injectable } from '@angular/core';
import { StringEmpty } from '../utils/global-vars';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  setCookie(name: string, value: string, expiration?: Date) {
    if (expiration) {
      document.cookie = `${name}=${value}; expires= ${expiration.toUTCString()}`;
    } else {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const expirationDay = new Date(year + 1, month, day);
      document.cookie = `${name}=${value}; expires= ${expirationDay.toUTCString()}`;
    }
  }

  getCookie(name: string) {
    if (document.cookie) {
      const cookies = document.cookie.split(';');
      const matchingKeyValue = cookies.filter(c => c.trim().split('=')[0] === name);
      if (matchingKeyValue && matchingKeyValue.length > 0) {
        return matchingKeyValue[0].trim().split('=')[1];
      } else {
        return StringEmpty;
      }
    } else {
      return StringEmpty;
    }
  }

  deleteCookie(name: string) {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
