import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IStudy, StudyDTO } from '../dtos/study.dto';
import { IUser } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  constructor(private readonly httpClient: HttpClient) { }

  getStudiesByOrganizationId(organizationId: string): Observable<StudyDTO[]> {
    return this.httpClient.get<StudyDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/studies`);
  }
  getStudiesTableByOrganizationId(organizationId: string): Observable<IStudy[]> {
    return this.httpClient.get<IStudy[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/get-studies-table`);
  }
  getSelfStudiesByOrganizationId(organizationId: string): Observable<StudyDTO[]> {
    return this.httpClient.get<StudyDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/user/self-studies`);
  }
  // Method to request list with all the study
  getAllStudies(): Observable<StudyDTO[]> {
    return this.httpClient.get<StudyDTO[]>(`${environment.platformHost}/platform/studies`);
  }
  activateStudy(organizationId: string, studyId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/activate`, studyId);
  }
  deactivateStudy(organizationId: string, studyId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/deactivate`, studyId);
  }
  addStudy(study: StudyDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/add-study`, study);
  }

  getSelfStudies(): Observable<StudyDTO[]> {
    return this.httpClient.get<StudyDTO[]>(`${environment.platformHost}/platform/user/self-studies`);
  }

  getPanelUsers(orgId: string, studyId: string, startPage: number, itemsPerPage: number, sorting: string, filter: string): Observable<IUser[]> {
    if (sorting === null || sorting.toLowerCase() === 'name asc') {
      sorting = '&$orderby=Name asc';
    }
    else if (sorting.toLowerCase() === 'name desc') {
      sorting = '&$orderby=Name desc';
    }
    else {
      sorting = `&$orderby=${sorting}, Name asc`;
    }

    const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;

    return this.httpClient.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/users${odataQueryString}`);
  }

  getPanelUsersCount(orgId: string, studyId: string, filter: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/users${odataQueryString}`);
  }

  getStudiesWithApplication(applicationId: string):Observable<StudyDTO[]> {
    return this.httpClient.get<StudyDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/get-studies`)
  }

  getOrganizationStudiesWithApplication(applicationId: string, organizationId: string):Observable<StudyDTO[]> {
    return this.httpClient.get<StudyDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/organization/${organizationId}/get-studies`)
  }
}
