import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DocumentsSigned, IDocument } from '../interface/document.interface';
import { StringEmpty } from '../../platform/utils/global-vars';
import { MeetingId } from '../../platform/utils/types';
import { DocumentUploaderDTO } from '../dtos/document-uploader.dto';
import { IStudyDocument } from '../interface/document.interface';
import { DocumentHistoryDTO } from '../dtos/document-history.dto';
import { TextareaImageUrlDTO } from '../dtos/textarea-image-url.dto';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(
    private readonly httpClient: HttpClient
  ) { }

  getDocuments(organizationId:string, studyId: string, meetingId: MeetingId, filter = StringEmpty, startPage?: number, itemsPerPage?: number,
               sorting?: string): Observable<IDocument[]> {
    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      // TODO: leave only the last else block
      if (sorting.toLowerCase() === 'name asc') {
        sorting = '$orderby=Name asc';
      }
      else if (sorting.toLowerCase() === 'name desc') {
        sorting = '$orderby=Name desc';
      }
      else if (sorting.toLowerCase() === 'versionno asc') {
        sorting = '$orderby=VersionNo asc';
      }
      else if (sorting.toLowerCase() === 'versionno desc') {
        sorting = '$orderby=VersionNo desc';
      } else {
        sorting = `$orderby=${sorting}`;
      }

      params.push(sorting);
    }

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&'))
    {
      temp = '&' + temp;
    }

    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `&${odataQueryString}`;
    }

    return this.httpClient.get<IDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/documents?$filter='d.MeetingId' eq '${meetingId}' and 'd.TaskId' eq \'NULL\'${odataQueryString}`)
      .pipe(this.mapDocuments());
  }

  getDocumentsCount(organizationId: string, studyId: string, meetingId: MeetingId, filter: string): Observable<number> {
    const odataQueryString = (filter) ? `${filter}&$count=true` : `&$count=true`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/documents?$filter='d.MeetingId' eq '${meetingId}' and 'd.TaskId' eq \'NULL\'${odataQueryString}`);
  }

  downloadDocumentBlob(organizationId:string, studyId: string, meetingId: MeetingId, documentId: string, versionId: string) {
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/document/${documentId}/file/${versionId}?isBase64=false`, {
      responseType: 'blob'
    });
  }

  downloadStudyDocumentBlob(organizationId:string, studyId: string, documentId: string, versionId: string) {
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/file/${versionId}?isBase64=false`, {
      responseType: 'blob'
    });
  }

  downloadDocumentBase64(organizationId:string, studyId: string, meetingId: MeetingId, documentId: string, versionId: string) {
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/document/${documentId}/file/${versionId}?isBase64=true`, {
      responseType: 'text'
    });
  }

  downloadStudyDocumentBase64(organizationId: string, studyId: string, documentId: string, versionId: string) {
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/file/${versionId}?isBase64=true`, {
      responseType: 'text'
    });
  }

  deleteDocument(organizationId:string, studyId: string, meetingId: MeetingId, documentId: string) {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/document/${documentId}`);
  }

  deleteStudyDocument(organizationId:string, studyId: string, documentId: string) {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}`);
  }

  getDocument(organizationId:string, studyId: string, meetingId: MeetingId, documentId: string, versionNo: string): Observable<IDocument> {
    return this.httpClient.get<IDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/document/${documentId}/version/${versionNo}`);
  }

  uploadDocument(meetingDocument: FormData, organizationId:string, studyId: string, meetingId: MeetingId, documentId: string, versionNo: number): Observable<IDocument> {
    return this.httpClient.post<IDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/document/${documentId}${versionNo > 0 ? `/file/${versionNo}` : StringEmpty}`, meetingDocument);
  }

  uploadStudyDocument(studyDocument: FormData, organizationId:string, studyId, documentId, versionNo): Observable<IStudyDocument> {
    return this.httpClient.post<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}${versionNo > 0 ? `/file/${versionNo}` : StringEmpty}`, studyDocument);
  }

  uploadImageTextarea(payload: FormData, isPublic: boolean): Observable<TextareaImageUrlDTO> {
    return this.httpClient.post<TextareaImageUrlDTO>(`${environment.platformHost}/platform/upload-textarea-image${isPublic ? '/public' : ''}`, payload);
  }

  getStudyDocuments(organizationId:string, studyId: string, filter = StringEmpty, startPage?: number, itemsPerPage?: number, sorting?: string): Observable<IStudyDocument[]> {
    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting === null) {
      sorting = '$orderby=MeetingTitle desc, CreatedAt desc';
    }
    else {
      sorting = `$orderby=${sorting}, CreatedAt desc`;
    }

    params.push(sorting);
    // }

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&'))
    {
      temp = '&' + temp;
    }

    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents${odataQueryString}`)
      .pipe(this.mapDocuments());
  }

  getStudyDocumentCount(organizationId:string, studyId: string, filter: string): Observable<number> {
    const odataQueryString = (filter) ? `?${filter}&$count=true` : `?$count=true`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents${odataQueryString}`);
  }

  getUploadedStudyDocumentCount(organizationId:string, studyId: string): Observable<number> {
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents/true`);
  }

  getStudyDocument(organizationId:string, studyId: string, documentId: string, versionNo: string): Observable<IStudyDocument> {
    return this.httpClient.get<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/version/${versionNo}`);
  }

  getDocumentUploaders(organizationId:string, studyId: string): Observable<DocumentUploaderDTO[]> {
    return this.httpClient.get<DocumentUploaderDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents/uploaders`);
  }

  getDocumentMeetingTitles(organizationId: string, studyId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents/meeting-titles`);
  }

  getDocumentHistory(organizationId:string, studyId: string, documentId: string, isStudyDocument: boolean, startPage?: number, itemsPerPage?: number, sorting?: string): Observable<DocumentHistoryDTO[]> {
    const filter = `$filter=Args1 eq '${documentId}' and Operation eq 'download-discussion-item-document' or Operation eq 'view-discussion-item-document' or Operation eq 'upload-discussion-item-document' or Operation eq 'update-discussion-item-document' or Operation eq 'countersign-document' or Operation eq 'view-meeting-document' or Operation eq 'download-meeting-document' or Operation eq 'view-study-document' or Operation eq 'download-study-document' or Operation eq 'upload-meeting-document' or Operation eq 'update-meeting-document' or Operation eq 'upload-study-document' or Operation eq 'update-study-document' or Operation eq 'view-decision-document' or Operation eq 'download-decision-document' or Operation eq 'upload-decision-document' or Operation eq 'update-decision-document' or Operation eq 'view-task-document' or Operation eq 'download-task-document' or Operation eq 'upload-task-document' or Operation eq 'update-task-document'`;
    const order = `$orderby=Timestamp desc`;
    let odataQueryString = `?${filter}&${order}`;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    const temp = params.join('&');
    odataQueryString += `&${temp}`;
    return this.httpClient.get<DocumentHistoryDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
  }

  getDocumentHistoryCount(organizationId:string, studyId: string, documentId: string, isStudyDocument: boolean): Observable<number> {
    const filter = `?$filter=Args1 eq '${documentId}' and Operation eq 'download-discussion-item-document' or Operation eq 'view-discussion-item-document' or Operation eq 'upload-discussion-item-document' or Operation eq 'update-discussion-item-document' or Operation eq 'countersign-document' or Operation eq 'view-meeting-document' or Operation eq 'download-meeting-document' or Operation eq 'view-study-document' or Operation eq 'download-study-document' or Operation eq 'upload-meeting-document' or Operation eq 'update-meeting-document' or Operation eq 'upload-study-document' or Operation eq 'update-study-document' or Operation eq 'view-decision-document' or Operation eq 'download-decision-document' or Operation eq 'upload-decision-document' or Operation eq 'update-decision-document' or Operation eq 'view-task-document' or Operation eq 'download-task-document' or Operation eq 'upload-task-document' or Operation eq 'update-task-document'`;
    const odataQueryString = filter + `&$count=true`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
  }

  getDocumentsToCountersign(organizationId:string, studyId: string, startPage?: number, itemsPerPage?: number,): Observable<IStudyDocument[]> {
    let odataQueryString = '?$filter=CompletedCountersign eq false and RequireCountersign eq true';

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      odataQueryString = odataQueryString + `&$top=${itemsPerPage}`;
    }

    if (startPage !== null && startPage !== undefined) {
      odataQueryString = odataQueryString + `&$skip=${(startPage - 1) * itemsPerPage}`;
    }
    return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents${odataQueryString}`);
  }

  getDocumentsToCountersignCount(organizationId:string, studyId: string): Observable<number> {
    const odataQueryString = '?$filter=CompletedCountersign eq false and RequireCountersign eq true&$count=true';
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents${odataQueryString}`);
  }

  getLastDocumentUploaded(organizationId:string, studyId: string): Observable<IStudyDocument[]> {
    const odataQueryString = '?$orderby=CreatedAt desc&$top=5';
    return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/documents${odataQueryString}`)
      .pipe(this.mapDocuments());
  }

  private mapDocuments() {
    return map((documents: any[]) => {
      return documents.map(document => {
        if (!document.oldVersions) {
          console.error('document.oldVersions is empty');
        }

        return {
          ...(document.lastVersion || document),
          children: document.oldVersions || [],
          open: false,
          get documentSignStatus() {
            let doc = document.lastVersion || document;
            return (doc.requireCountersign?
              (doc.completedCountersign? DocumentsSigned.COMPLETED : DocumentsSigned.INCOMPLETED) 
              : DocumentsSigned.NOT_ACTIVE)
          }
        };
      });
    });
  }
}
