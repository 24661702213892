import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable, of, throwError, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Store } from '@ngxs/store';
import { IAuthentication } from '../interfaces/authentication.interface';
import { AUTH_STATE_TOKEN } from '../store/auth/auth.token';
import { LogoutAction, SaveUserTokenAction } from '../store/auth/auth.actions';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private redirectURL: string;

  public refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private readonly refreshTokenSubject = new BehaviorSubject<string>(null);

  constructor(
    private readonly store: Store,
    private readonly httpClient: HttpClient,
    private readonly cookieService: CookieService,
  ) { }

  public addAuthenticationToken(request) {
    if (!this.accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.accessToken}`
      }
    });
  }

  public refreshTokens(callback?, catchHandler?): Observable<unknown> {
    const repeatRequest$ = callback ? switchMap(() => callback()) : tap();
    const catchHandler$ = catchHandler ? catchHandler : tap();

    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // – which means the new token is ready, and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        repeatRequest$
      );
    } else {
      this.refreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      // Call auth.refreshAccessToken(this is an Observable that will be returned)
      return this.refreshAccessToken().pipe(
        switchMap((response: any) => {
          // When the call to refreshToken completes we reset the refreshTokenInProgress to false
          // for the next time the token needs to be refreshed

          const data = {
            access_token: response.token,
            refresh_token: response.refreshToken
          };

          return this.store.dispatch(new SaveUserTokenAction(data as IAuthentication, true)).pipe(tap(() => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(response.refresh_token);
          }));
        }),
        repeatRequest$,
        catchHandler$
      );
    }
  }

  private get authenticationData() {
    return this.store.selectSnapshot(AUTH_STATE_TOKEN);
  }

  get accessToken() {
    return this.authenticationData?.access_token;
  }

  refreshAccessToken() {
    const payload = {
      accessToken: this.authenticationData.access_token,
      refreshToken: this.authenticationData.refresh_token,
    };

    return this.httpClient.post<IAuthentication>(`${environment.platformHost}/platform/user/refresh-token`, payload);
  }

  login(username: string, password: string) {
    const payload = { username, password };

    return this.httpClient.post<any>(`${environment.platformHost}/platform/user/login`, payload);
  }

  loginSso(jwtIdToken: string) {
    const payload = {JwtToken : jwtIdToken};
    return this.httpClient.post<any>(`${environment.platformHost}/platform/user/sso/saml2/login`, payload);
  }

  twoFactorAuth(twoFactorAuth: any) {
    return this.httpClient.post<any>
    (`${environment.platformHost}/platform/user/2falogin`,
    twoFactorAuth);
  }

  verifyMobilePhone(username: string) {
    return this.httpClient.get(`${environment.platformHost}/platform/user/${username}/send-mobile-code`);
  }

  confirmMobilePhone(username: string, code: string) {
    return this.httpClient.post(`${environment.platformHost}/platform/user/${username}/verify-mobile-code`, { code });
  }

  twoFactorAuthResend(username: string) {
    return this.httpClient.post(`${environment.platformHost}/platform/user/2falogin/resend`, { username });
  }

  logout(redirectPath?: string) {
    return this.store.dispatch(new LogoutAction(redirectPath));
  }

  userIsLogged(): boolean {
    return !!this.accessToken;
  }

  getRedirectURL(): string{
    return this.redirectURL;
  }

  setRedirectUrl(url: string) {
    this.redirectURL = url;
  }
}
