import { ChangePasswordDTO } from '../../dtos/change-password.dto';
import { CompleteRegistrationDTO } from '../../dtos/complete-registration.dto';
import { EmailDTO } from '../../dtos/email.dto';
import { RegisterDTO } from '../../dtos/register.dto';
import { IRoleScope } from '../../interfaces/role.scope.interface';
import { IUser } from '../../model/user.model';

export class GetUserInfoAction {
  static readonly type = '[User] [GET] [UserInfo]';
}

export class SaveUserInfoAction {
  static readonly type = '[User] [POST] [UserInfo]';

  constructor(
    public user: IUser
  ) { }
}

export class GetUsersAction {
  static readonly type = '[User] [GET] [Users]';
}

export class GetPagedUsersAction {
  static readonly type = '[User] [GET] [Paged Users]';

  constructor(
    public startPage: number,
    public itemsPerPage: number,
    public sorting: string,
    public filter: string
  ) { }
}

export class GetLastSelfChangePasswordAction {
  static readonly type = '[User] [GET] [Last Self Change Password]';
}

export class GetUserLastChangePasswordAction {
  static readonly type = '[User] [GET] [User Last Change Password]';

  constructor(
    public username: string
  ) { }
}

export class GetPanelUserLastChangePasswordAction {
  static readonly type = '[User] [GET] [Panel User Last Change Password]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public username: string
  ) {}
}

export class GetPasswordCodeAction {
  static readonly type = '[User] [GET] [Password Code]';

  constructor(
    public passwordCode: string
  ) { }
}

export class GetSelfLoginsAction {
  static readonly type = '[User] [GET] [Self Logins]';

  constructor(
    public startPage: number,
    public itemsPerPage: number,
    public sorting: string
  ) { }
}

export class GetUserLoginsAction {
  static readonly type = '[User] [GET] [User Logins]';

  constructor(
    public payload: {
      username: string,
      pageData: {
        startPage: number,
        itemsPerPage: number,
        sorting: string
      }
    }
  ) { }
}

export class GetUserLoginsCountAction {
  static readonly type = '[User] [GET] [User Logins Count]';

  constructor(
    public payload: {
      studyId: string,
      username: string
    }
  ) { }
}

export class GetSelfLoginsCountAction {
  static readonly type = '[User] [GET] [Self Logins Count]';
}

export class GetUsersCountAction {
  static readonly type = '[User] [GET] [Count]';

  constructor(
    public filter: string
  ) { }
}

export class GetOrganizationUsersAction {
  static readonly type = '[User] [GET] [Organization Users]';

  constructor(
    public organizationID: string,
    public startPage: number,
    public itemsPerPage: number, 
    public columnSort: string,
    public filter: string,
  ) { }
}

export class GetStudyUsersAction
{
  static readonly type = '[User] [GET] [Study Users]';

  constructor(
    public organizationID: string,
    public studyId: string,
    public application: string,
  ) { }
}

export class GetStudiesUsersAction
{
  static readonly type = '[User] [GET] [Studies Users]';

  constructor(
    public organizationID: string,
    public studyId: string[]
  ) { }
}

export class GetOrganizationUsersCountAction {
  static readonly type = '[User] [GET] [Organization Users Count]';

  constructor(
    public organizationID: string,
    public filter: string,
  ) { }
}

export class GetUserLastLoginAction {
  static readonly type = '[User] [GET] [Last Login]';
}

export class UpdateLoggedUserAction {
  static readonly type = '[User] [POST] [Update Logged User]';

  constructor(
    public user: IUser
  ) { }
}

export class UpdateUserAction {
  static readonly type = '[User] [POST] [Update User]';

  public readonly post = {
    notification: {
      success: 'platform.profile.successMessage'
    }
  };

  constructor(
    public username: string,
    public user: IUser
  ) { }
}

export class ActivateUserAction {
  static readonly type = '[User] [POST] [Activate]';

  public readonly post = {
    notification: {
      success: 'platform.users.activate-user.toastr-message'
    }
  };

  constructor(
    public username: string
  ) { }
}

export class DeactivateUserAction {
  static readonly type = '[User] [POST] [Deactivate]';

  public readonly post = {
    notification: {
      success: 'platform.users.deactivate-user.toastr-message'
    }
  };

  constructor(
    public username: string
  ) { }
}

export class RegisterUserAction {
  static readonly type = '[User] [POST] [Register]';

  constructor(
    public user: RegisterDTO
  ) { }
}

export class RegisterUserCodeAction {
  static readonly type = '[User] [POST] [Register User Code]';

  constructor(
    public code: string
  ) { }
}

export class RefuseRegistrationUserAction {
  static readonly type = '[User] [POST] [Refuse Registration]';

  constructor(
    public code: string
  ) { }
}

export class ResendRegisterLinkAction {
  static readonly type = '[User] [POST] [Resend Register Link]';

  public readonly post = {
    notification: {
      success: 'platform.users.resend-confirmed-registration.toastr-message'
    }
  };

  constructor(public email: EmailDTO) {
  }
}

export class ResendChangeEmailLinkAction {
  static readonly type = '[User] [POST] [Resend Change Email Link]';

  public readonly post = {
    notification: {
      success: 'platform.resend-change-email-link.toastr-message'
    }
  };

  constructor(
    public email: EmailDTO
  ) { }
}

export class CompleteUserRegistrationAction {
  static readonly type = '[User] [POST] [Complete Registration]';

  constructor(
    public code: string,
    public completeRegistration: CompleteRegistrationDTO
  ) { }
}

export class EmailChangeAction {
  static readonly type = '[User] [POST] [Email Change]';

  constructor(
    public email: EmailDTO
  ) { }
}

export class ResendResetPasswordLinkAction {
  static readonly type = '[User] [POST] [Resend Reset Password Link]';

  public readonly post = {
    notification: {
      success: 'platform.users.resend-reset-password-link.toastr-message'
    }
  };

  constructor(
    public email: EmailDTO
  ) { }
}

export class DeactivateUserRoleAction {
  static readonly type = '[User] [POST] [Deactivate Role]';

  constructor(
    public username: string,
    public roleToDeactivate: IRoleScope
  ) { }
}

export class ActivateUserRoleAction {
  static readonly type = '[User] [POST] [Activate Role]';

  constructor(
    public username: string,
    public roleToActivate: IRoleScope
  ) { }
}

export class RemoveRoleFromUserAction {
  static readonly type = '[User] [POST] [Remove Role]';

  constructor(
    public username: string,
    public roleToRemove: IRoleScope
  ) { }
}


export class GetUserRolesAction {
  static readonly type = '[User] [GET] [User Roles]';

  constructor(
    public username: string
  ) { }
}

export class ChangePasswordSelfAction {
  static readonly type = '[User] [POST] [Change Password Self]';

  constructor(
    public passwordData:  ChangePasswordDTO
  ) { }
}

export class CompleteResetPasswordAction {
  static readonly type = '[User] [POST] [Complete Reset Password]';

  constructor(
    public passwordData: ChangePasswordDTO,
    public ResetPasswordCode: string
  ) {}
}