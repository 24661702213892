import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ISidebarItem } from '../../../platform/interfaces/sidebar-item.interface';
import { SidebarItem } from '../../../platform/model/sidebar.model';
import { SidebarService } from '../../../platform/services/sidebar.service';
import { deepClone } from '../../utils/global-vars';
import { GetSidebarAction, GetSidebarTreeAction, ToggleSidebarAction, ToggleSidebarItemAction, UpdateDsmbSidebarAction } from './sidebar.actions';
import { SIDEBAR_STATE_TOKEN } from './sidebar.token';

export interface ISidebarState {
  readonly sidebar: ISidebarItem[];
  readonly sidebarTree: ISidebarItem[];
  readonly open: boolean;
}

@State({
  name: SIDEBAR_STATE_TOKEN,
  defaults: SidebarState.defaultState
})
@Injectable()
export class SidebarState {
  static defaultState: ISidebarState = {
    sidebar: [],
    sidebarTree: [],
    open: false
  };

  constructor(
    private readonly sidebarService: SidebarService
  ) { }

  @Selector()
  static getSidebar(state: ISidebarState) {
    return state.sidebar;
  }

  @Selector()
  static getSidebarTree(state: ISidebarState) {
    return state.sidebarTree;
  }

  @Selector()
  static getSidebarOpen(state: ISidebarState) {
    return state.open;
  }

  @Action(ToggleSidebarItemAction)
  toggleSidebarItem({ getState, patchState }: StateContext<ISidebarState>, { selectedItemId }: ToggleSidebarItemAction) {
    const { sidebar } = getState();

    const items = deepClone<SidebarItem[]>(sidebar);

    this.findAndToggleItem(items, selectedItemId);

    patchState({ sidebar: items });
  }

  @Action(GetSidebarAction)
  getSidebar({ patchState }: StateContext<ISidebarState>) {
    return this.sidebarService.getSidebar().pipe(
      tap(sidebar => patchState({ sidebar })),
      catchError(error => {
        if (error.status === 404) {
          patchState({ sidebar: [] });

          return of();
        }

        return throwError(() => error);
      })
    );
  }

  @Action(GetSidebarTreeAction)
  getSidebarTree({ patchState }: StateContext<ISidebarState>) {
    return this.sidebarService.getSidebar().pipe(tap(sidebarTree => {
      patchState({ sidebarTree });
    }));
  }

  @Action(UpdateDsmbSidebarAction)
  updateDsmbSidebar({patchState, getState} : StateContext<ISidebarState>) {
    return this.sidebarService.getActionRequired().pipe(
      tap(map => { 
        const { sidebar } = getState();
        let temp = deepClone<ISidebarItem[]>(sidebar);
        this.sidebarService.updateDsmbSidebar(temp, map);
        patchState({
          sidebar: temp
        });
      })
    )
  }

  @Action(ToggleSidebarAction)
  toggleSidebar({ getState, patchState }: StateContext<ISidebarState>) {
    const { open } = getState();

    patchState({
      open: !open
    });
  }

  private findAndToggleItem(items: SidebarItem[], toggleItemId: string, close = false): void {
    const toggleItem = items.find(({ id }) => id === toggleItemId);

    if (toggleItem) {
      toggleItem.open = !toggleItem.open;

      items
        .filter(i => i.id !== toggleItemId)
        .forEach(i => i.open = false);
    }

    if (close) {
      items.forEach(i => i.open = false);
    }

    items.forEach(i => i.children && this.findAndToggleItem(i.children, toggleItemId, !!toggleItem));
  }
}
