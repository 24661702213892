import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  public isLoading = this.loaderService.isLoading;
  public isEnvLocal = environment.local;

  constructor(
    private readonly loaderService: LoaderService
  ) { }
}
