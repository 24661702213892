import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>(
  'Abstraction over the window object',
  {
    providedIn: 'root',
    factory: (): Window & {
      URL: typeof URL
    } => {
      const { defaultView } = inject(DOCUMENT);

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      return defaultView;
    }
  }
);
