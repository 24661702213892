import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationsEnum } from '../dtos/application.dto';
import { CheckAllowedOperationDTO } from '../dtos/check-allowed-operation.dto';
import { OperationDTO } from '../dtos/operation.dto';

@Injectable({
  providedIn: 'root'
})
export class OperationService {
  constructor(private readonly httpClient: HttpClient) {
  }

  checkAllowedOperation(operation: CheckAllowedOperationDTO): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.platformHost}/platform/operation/allowed`, operation);
  }

  getRolesOperations(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.platformHost}/platform/permissions`);
  }

  // Method to request list of operations relative to an organization
  getOperationsByOrganizationId(organizationId: string, odataQueryString?: string): Observable<OperationDTO[]> {
    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/operations${odataQueryString}`);
  }

  // Method to request list with all the operations relative to an application
  getOperationsByApplicationId(applicationId: string): Observable<OperationDTO[]> {
    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/operations`);
  }

  getExportOperationsByApplicationId(applicationId: string): Observable<OperationDTO[]> {
    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/data-export-operations`);
  }

  getOperationsByApplicationIdPaged(startPage: number, itemsPerPage: number,
                                    filter: string, applicationId: string): Observable<OperationDTO[]> {
    const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;

    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/operations${odataQueryString}`);
  }

  getOperationsByApplicationIdCount(filter: string, applicationId: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.platformHost}/platform/application/${applicationId}/operations${odataQueryString}`);
  }

  getOperationsByRoleIdAndApplicationId(applicationId: string, roleId: string): Observable<OperationDTO[]> {
    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/role/${roleId}/operations`);
  }

  getOperationsByRoleIdAndApplicationIdPaged(startPage: number, itemsPerPage: number, filter: string,
                                             applicationId: string, roleId: string): Observable<OperationDTO[]> {
    const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;

    if (applicationId === ApplicationsEnum.DSMB)
    {
      return this.httpClient.get<OperationDTO[]>(`${environment.dsmbHost}/dsmb/role/${roleId}/operations${odataQueryString}`);
    }

    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/role/${roleId}/operations${odataQueryString}`);
  }

  getOperationsByRoleIdAndApplicationIdCount(filter: string, applicationId: string, roleId: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;

    if (applicationId === ApplicationsEnum.DSMB)
    {
      return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/role/${roleId}/operations${odataQueryString}`);
    }

    return this.httpClient.get<number>(`${environment.platformHost}/platform/application/${applicationId}/role/${roleId}/operations${odataQueryString}`);
  }


  addOperationToRole(applicationId: string, roleId: string, operationId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/application/${applicationId}/role/${roleId}/add-operation/${operationId}`,
      operationId);
  }

  getLogsOperations(applicationId: string, organizationId: string, studyId: string, username: string): Observable<OperationDTO[]> {
    return this.httpClient.get<OperationDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/organization/${organizationId}/study/${studyId}/user/${username}/get-logs-operations`);
  }

}
