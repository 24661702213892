import { IUserChatMessageDTO } from '../../../dsmb/interface/user-message.interface';

export class GetUserChatMessagesAction {
    static readonly type = '[User Chat] [GET] [All Messages]';

    constructor(
        public userId: string,
        public senderId: string,
        public organizationId: string,
        public studyId: string
    ) { }
}

export class GetLastChatMessagesAction {
    static readonly type = '[User Chat] [GET] [Last Messages]';

    constructor(
      public senderId: string,
      public orgId: string,
      public studyId: string
    ) { }
}

export class SaveUserChatMessageAction {
  static readonly type = '[User Chat] [POST] [Message]';

  constructor(
    public senderId: string,
    public orgId: string,
    public studyId: string,
    public newMessage: IUserChatMessageDTO
  ) { }
}

export class GetUserChatMessageCountAction {
  static readonly type = '[User Chat] [GET] [Count]';

  constructor(
    public userId: string,
    public organizationId: string,
    public studyId: string
  ) { }
}


