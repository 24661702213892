import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { IOrganization } from '../../../platform/interfaces/organization.interface';
import { OrganizationState } from '../../store/organization/organization.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StudyState } from '../../store/study/study.state';
import { StudyDTO } from '../../../platform/dtos/study.dto';
import { SidebarState } from '../../store/sidebar/sidebar.state';
import { ISidebarItem } from '../../../platform/interfaces/sidebar-item.interface';
import { MeetingState } from 'src/app/dsmb/store/meeting/meeting.state';
import { MeetingDTO } from 'src/app/dsmb/dtos/meeting.dto';
import { DiscussionItemDTO } from 'src/app/dsmb/dtos/meeting-discussion-item.dto';
import { DecisionDTO } from 'src/app/dsmb/dtos/decision.dto';
import { DiscussionItemsState } from 'src/app/dsmb/store/discussion-items/discussion-items.state';
import { DecisionsState } from 'src/app/dsmb/store/decisions/decisions.state';
import { TasksService } from 'src/app/dsmb/services/tasks.service';
import { TasksState } from 'src/app/dsmb/store/tasks/tasks.state';
import { IUserTask } from 'src/app/dsmb/interface/task.interface';

export interface Breadcrumb {
  label: string;
  url?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Select(OrganizationState.getSelfOrganizations) private readonly organizations$: Observable<IOrganization[]>;
  @Select(StudyState.getStudies) private readonly studies$: Observable<StudyDTO[]>;
  @Select(SidebarState.getSidebar) private readonly sidebarTree$: Observable<ISidebarItem[]>;
  @Select(MeetingState.getMeetings) private readonly meetings$: Observable<MeetingDTO[]>;
  @Select(MeetingState.getMeeting) private readonly meeting$: Observable<MeetingDTO>;
  @Select(DiscussionItemsState.getDiscussionItem) private readonly discussionItem$: Observable<DiscussionItemDTO>
  @Select(DecisionsState.getDecisionDetails) private readonly decision$: Observable<DecisionDTO>
  @Select(TasksState.getUserTaskDetails) private readonly task$: Observable<IUserTask>

  public breadcrumbs: Breadcrumb[] = [];
  private organizations: IOrganization[] = [];
  private studies: StudyDTO[] = [];
  private meetings: MeetingDTO[] = [];
  private meeting: MeetingDTO;
  private isMultiOrganizations = false;
  private discussionItem: DiscussionItemDTO;
  private decision: DecisionDTO;
  private task: IUserTask;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store
  ) { }

  public ngOnInit(): void {
    this.subscribeData();
  }

  private subscribeData(): void {
    combineLatest([
      this.organizations$,
      this.studies$,
      this.sidebarTree$,
      this.meetings$,
      this.meeting$,
      this.discussionItem$,
      this.decision$,
      this.task$
    ])
      .pipe(
        untilDestroyed(this),
        tap((...args) => this.setData.apply(this, args)),
        filter(() => !!this.organizations && !!this.studies),
        switchMap(() => this.buildBreadcrumbs())
      )
      .subscribe();
  }


  private setData([organizations, studies, sidebarTree, meetings, meeting, discussionItem, decision, task]) {
    this.organizations = organizations;
    this.studies = studies;
    this.isMultiOrganizations = sidebarTree?.length > 1;
    this.meetings = meetings;
    this.meeting = meeting;
    this.discussionItem = discussionItem;
    this.decision = decision;
    this.task = task;
  }

  private buildBreadcrumbs() {
    return combineLatest([
      this.activatedRoute.params,
      this.getLastChildRoute(this.activatedRoute).params,
      this.getLastChildRoute(this.activatedRoute).data
    ])
      .pipe(
        first(),
        tap(([params, childParams, { breadcrumbs }]: [Params, Params, { breadcrumbs: Breadcrumb[] }]) => {
          this.breadcrumbs = (breadcrumbs || [])
            // .filter(breadcrumb => this.isMultiOrganizations || breadcrumb.label !== ':organizationId')
            .map((breadcrumb) => this.replaceSegments(Object.assign({}, params, childParams), { ...breadcrumb }));
        })
      );
  }


  getLastChildRoute(route: ActivatedRoute): ActivatedRoute {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute; // Return the deepest child
  };

  private replaceSegments(params: Params, breadcrumb: Breadcrumb): Breadcrumb {
    if (breadcrumb.label === ':organizationId') {
      breadcrumb.label = (
        this.organizations.find(o => o.id === params.organizationId) ||
        this.organizations.find(o => o.id === this.findNextSegment('organizations'))
      )?.name;
    } else if (breadcrumb.label === ':studyId') {
      breadcrumb.label = (
        this.studies.find(s => s.id === params.studyId) ||
        this.studies.find(s => s.id === this.findNextSegment('study'))
      )?.name;
    }
    else if (breadcrumb.label === ':meetingTitle') {
      if (this.meeting) {
        breadcrumb.label = this.meeting?.title + ' details';
      } else {
        breadcrumb.label = (
          this.meetings.find(m => m.id === params.meetingId) ||
          this.meetings.find(m => m.id === this.findNextSegment('meeting'))
        )?.title + ' details';
      }
    }
    else if (breadcrumb.label === ':discussionItemId') {
        breadcrumb.label = (
          this.discussionItem
        )?.title + ' details';
    }
    else if (breadcrumb.label === ':decisionId') {
      breadcrumb.label = (
        this.decision
      )?.title + ' details';
    }
    else if (breadcrumb.label === ':taskId') {
      breadcrumb.label = (
        this.task
      )?.task.title + ' details';
    }
    if (breadcrumb.url) {
      const segments = [
        [':username', params.username || this.findNextSegment('users')],
        [':organizationId', params.organizationId || this.findNextSegment('org')],
        [':studyId', params.studyId || this.findNextSegment('study')],
        [':applicationId', params.applicationId || this.findNextSegment('app')],
        [':roleId', params.roleId || this.findNextSegment('role')],
        [':meetingId', params.meetingId || this.findNextSegment('meeting')],
        [':actionId', params.actionId || this.findNextSegment('actionId')],
        [':discussionItemId', params.discussionItemId || this.findNextSegment('discussionItemId')],
        [':decisionId', params.decisionId || this.findNextSegment('decisionId')],
        [':taskId', params.taskId || this.findNextSegment('taskId')]
      ];

      segments.forEach(([segment, value]) => {
        breadcrumb.url = breadcrumb.url.replace(segment, value);
      });
    }

    return breadcrumb;
  }

  private findNextSegment(name: string) {
    const segments = this.router.url.split('/');

    return segments[segments.indexOf(name) + 1];
  }
}
