import { JsonValidatorSchema } from '../services/JsonValidator.service';

const loginSchema: JsonValidatorSchema = {
  type: 'object',
  properties: {
    browser: { type: 'string' },
    device: { type: 'string' },
    ip: { type: 'string' },
    timestamp: { type: 'string' },
  },
  required: ['browser', 'device', 'ip', 'timestamp'],
  additionalProperties: false
}

export const loginsSchema: JsonValidatorSchema = {
  type: 'array',
  items: loginSchema
}
