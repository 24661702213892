import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ISidebarItem } from '../../interfaces/sidebar-item.interface';
import { SidebarItem } from '../../model/sidebar.model';
import { AuthService } from '../../services/auth.service';
import { BroadcastMessageEnum, BroadcastService } from '../../services/broadcast.service';
import { SidebarService } from '../../services/sidebar.service';
import { ToggleSidebarAction, ToggleSidebarItemAction, UpdateDsmbSidebarAction } from '../../store/sidebar/sidebar.actions';
import { SidebarState } from '../../store/sidebar/sidebar.state';
import { StringEmpty } from '../../utils/global-vars';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Select(SidebarState.getSidebarOpen) public readonly sidebarOpen$: Observable<boolean>;
  @Select(SidebarState.getSidebar) private readonly sidebar$: Observable<ISidebarItem[]>;

  public sidebarItems: ISidebarItem[] = [];

  constructor(
    public readonly sidebarService: SidebarService,
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly broadcastService: BroadcastService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  public ngOnInit(): void {
    this.subscribeData();

    if (!!this.sidebarItems.length){
      this.store.dispatch(UpdateDsmbSidebarAction);
    }

    this.broadcastService.channel$
      .pipe(
        untilDestroyed(this),
        filter(message => [
          BroadcastMessageEnum.countersigned.valueOf(),
          BroadcastMessageEnum.taskDone.valueOf()
        ].includes(message)),
        tap(() => {
          this.store.dispatch(UpdateDsmbSidebarAction);
        })
      )
      .subscribe();
  }

  private subscribeData(): void {
    this.sidebar$
      .pipe(
        untilDestroyed(this),
        tap(sidebarItems => {
          this.sidebarItems = sidebarItems;
        })
      )
      .subscribe();
  }

  public toggleSidebar() {
    this.store.dispatch(ToggleSidebarAction);
  }

  public toggleItem(toggleItem: SidebarItem): void {
    this.store.dispatch(new ToggleSidebarItemAction(toggleItem.id));
  }

  public async open(item: SidebarItem): Promise<void> {
    await this.router.navigate([item.link]);

    if (!item.open) {
      this.toggleItem(item);
    }
  }

  public logout(): void {
    this.authService.logout();
  }
}
