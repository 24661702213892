export const StringEmpty = '';

// TODO: delete, use an immutable helper instead
export function deepClone<T>(source): T {
  // If the source isn't an Object or Array, throw an error.
  if (!(source instanceof Object) || source instanceof Date || source instanceof String) {
    throw new Error('Only Objects or Arrays are supported.');
  }

  // Set the target data type before copying.
  const target = source instanceof Array ? [] : {};

  for (const prop in source) {
    // Make sure the property isn't on the prototype
    if ( source instanceof Object && !(source instanceof Array) && !(source.hasOwnProperty(prop)) ) {
      continue;
    }

    // If the current property is an Array or Object, recursively clone it, else copy its value
    if (source[prop] instanceof Object && !(source[prop] instanceof Date) && !(source[prop] instanceof String) )  {
      target[prop] = deepClone(source[prop]);
    } else {
      target[prop] = source[prop];
    }
  }

  return target as T;
}

export function insert(arr, index, ...newItems) {
  return [
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index)
  ];
}
