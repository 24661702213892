import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { HelpdeskService } from 'src/app/platform/services/helpdesk.service';
import { HelpdeskCategoryDTO } from '../../dtos/helpdesk-category.dto';
import { GetHelpdeskCategoriesAction, SendAnonymousHelpdeskFormAction, SendHelpdeskFormAction } from './helpdesk.actions';
import { HELPDESK_STATE_TOKEN } from './helpdesk.token';

export class IHelpdeskState {
  categories: HelpdeskCategoryDTO[];
}

@State({
  name: HELPDESK_STATE_TOKEN,
  defaults: HelpdeskState.defaultState
})
@Injectable()
export class HelpdeskState {
  static defaultState: IHelpdeskState = {
    categories: []
  };

  constructor(
    private readonly store: Store,
    private readonly helpdeskService: HelpdeskService
  ) {
  }

  @Selector()
  static getCategories(state: IHelpdeskState) {
    return state.categories;
  }

  @Action(GetHelpdeskCategoriesAction)
  helpdeskGetCategories({ patchState }: StateContext<IHelpdeskState>) {
    const currentState = this.store.selectSnapshot(HELPDESK_STATE_TOKEN);

    if (!currentState.categories.length) {
      return this.helpdeskService.getHelpdeskCategories()
        .pipe(tap(categories => patchState({ categories })));
    }
  }

  @Action(SendAnonymousHelpdeskFormAction)
  addAnonymousHelpdeskRequests({ dispatch }: StateContext<IHelpdeskState>, { helpdeskRequest }: SendAnonymousHelpdeskFormAction) {
    return this.helpdeskService.addHelpdeskAnonymous(helpdeskRequest);
  }

  @Action(SendHelpdeskFormAction)
  addHelpdeskRequests({ dispatch }: StateContext<IHelpdeskState>, { helpdeskRequest }: SendHelpdeskFormAction) {
    return this.helpdeskService.addHelpdesk(helpdeskRequest);
  }
}
