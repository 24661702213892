import { StringPartToDelimiterCase } from "type-fest/source/delimiter-case";
import { DiscussionItemDTO } from "../../dtos/meeting-discussion-item.dto";


export class GetDiscussionItemsAction {
    static readonly type = '[DiscussionItem] [GET] [Get Discussion Items]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string,
        public startPage?: number,
        public itemsPerPage?: number,
        public sorting?: string
    ){

    }
}

export class GetDiscussionItemsByMeetingAction {
    static readonly type = '[DiscussionItem] [GET] [Get Meeting Items]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public filter: string,
        public startPage?: number,
        public itemsPerPage?: number,
        public sorting?: string
    ){

    }
}

export class GetDiscussionItemsCountAction {
    static readonly type = '[DiscussionItem] [GET] [Get Discussion Items Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string
    ) {
        
    }
}

export class GetDiscussionItemsCountByMeetingAction {
    static readonly type = '[DiscussionItem] [GET] [Get Meeting Items Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public filter: string
    ) {
        
    }
}

export class AddDiscussionItemAction {
    static readonly type = '[DiscussionItem] [GET] [Add Discussion Item]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public newDiscussionItem: DiscussionItemDTO,
    ) {
        
    }
}

export class GetDiscussionItemDocumentsAction {
    static readonly type = '[DiscussionItem] [GET] [Get Discussion Item Documents]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
        public meetingId: string,
    ) {
        
    }
}

export class GetDiscussionItemDocumentsCountAction {
    static readonly type = '[DiscussionItem] [Get] [Get Discussion Item Documents Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
        public meetingId: string,
    ) {
        
    }
}

export class GetDiscussionItemDocumentAction {
    static readonly type = '[DiscussionItem] [Get] [Get Discussion Item Document]';
    constructor(
        public organizationId: string,
        public studyId: string,
        public documentId: string, 
        public versionNo: string, 
    ) {
        
    }
}

export class GetDiscussionItemDocumentBase64Action {
    static readonly type = '[DiscussionItem] [Get] [Get Discussion Item Document Base 64]';
    constructor(
        public organizationId: string,
        public studyId: string,
        public documentId: string, 
        public versionNo: string, 
    ) {
        
    }
}

export class DownloadDiscussionItemDocument {
    static readonly type = '[DiscussionItem] [Get] [Download Discussion Item Document]';
    constructor(
        public organizationId: string,
        public studyId: string,
        public documentId: string, 
        public versionNo: string, 
    ) {
        
    }
}

export class UploadDiscussionItemDocumentAction {
    static readonly type = '[Discussion Item] [POST] [Upload Document]';
      
    public readonly post = {
        notification: {
            success: 'dsmb.upload-document.toastr-message'
        }
    };
      
    constructor(
        public payload: {
            studyDocument: FormData,
            organizationId:string,
            studyId: string,
            meetingId: string,
            discussionItemId: string,
            documentId: string,
            versionNo: number,
            notify: boolean
        }
    ) { }
}

export class GetDiscussionItemDetailAction {
    static readonly type = '[DiscussionItem] [GET] [Get Discussion Item]';
    
    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
    ) { }
}
export class GetDiscussionItemsPresentersAction {
    static readonly type = '[DiscussionItemsPresenters] [GET] [Get Discussion Items Presenters]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string
    ) {

    }
}

export class GetDiscussionItemsAssociatedWithDecisionsAction {
    static readonly type = '[DiscussionItems] [Get] [Get Discussion Items Associated With Decisions';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string 
    ) {
        
    }
}

export class SaveDiscussionAction {
    static readonly type = '[DiscussionItems] [Get] [Add Discussion for Discussion Item]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
        public discussion: string 
    ) {
        
    }
}

export class GetAuditTrailDiscussionitemDocumentAction {
    static readonly type = '[DiscussionItems] [GET] [Get Discussion Item Document Audit Trail]';

    constructor(
      public payload: {
        organizationId:string,
        studyId: string,
        discussionItemId: string,
        documentId: string,
        isStudyDocument: boolean,
        startPage?: number,
        itemsPerPage?: number,
        sorting?: string
      }
    ) { }
}

export class GetAuditTrailDiscussionItemDocumentCountAction {
    static readonly type = '[DiscussionItems] [Get] [Get Count Discussion Item Document Audit Trail]';

    constructor(
      public payload: {
        organizationId: string,
        studyId: string,
        discussionItemId: string,
        documentId: string,
        isStudyDocument: boolean
      }
    ) { }
}

export class DeleteDiscussionItemCascadeAction {
    static readonly type = '[DiscussionItems] [Delete] [Delete Discussion Item]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
        public comment: string,
    ){

    }
}

export class EditDiscussionItemDetails {
    static readonly type = '[DiscussionItems] [Edit] [Edit Discussion Item]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItem: DiscussionItemDTO,
    ){

    }
}

export class DeleteDiscussionItemDocumentAction {
    static readonly type = '[Document] [Delete] [Delete Discussion Document Action]';

    public readonly post = {
      notification: {
        success: 'dsmb.delete-document.toastr-message'
      }
    };

    constructor(
      // Payload { organizationId, studyId, documentId}
      public payload: any
    ) { }
}

export class UpdateMeetingDiscussionItemsOrder {
    static readonly type = '[DiscussionItem] [POST] [Update Meeting Discussion Item Order]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public discussionItems: DiscussionItemDTO[]
    ) {}
}

export class DeleteDiscussionItemPollAction {
    static readonly type = '[Poll] [Delete] [Delete Discussion Item Poll Action]';

    public readonly post = {
        notification: {
            success: 'dsmb.delete-discussion-item-poll.toastr-message'
        }
    };

    constructor(
        public payload: any
    ) { }
}