import { ApplicationScopeEnum, ApplicationsEnum } from '../dtos/application.dto';
import { IRoleScope } from '../interfaces/role.scope.interface';
import { JsonValidatorSchema } from '../services/JsonValidator.service';
import { StringEmpty } from '../utils/global-vars';

export interface IUserDTO {
  readonly name: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly address: string | null;
  readonly language: UserLanguageEnum;
  readonly timezone: string;
  readonly changeEmailCode: string | null;
  readonly blinded: boolean;
  readonly active: boolean;
  readonly roleActive: boolean;
  readonly roles: IRoleScope[];
  readonly role: string;
  readonly roleName: string;
  readonly mobile: string;
  readonly mobilePrefix: string;
  readonly mobileConfirmed: boolean;
  readonly twoStepAuthEnabled: string;
  readonly registrationCode?: string | null;
  readonly ResetPasswordCode?: string | null;
  readonly associatedOrganization?: string | null;
  readonly requireTraining: boolean;
  readonly trainingCompletedAt: string | null;
}

export interface IUser extends IUserDTO {
    isCoordinator(studyId: string): boolean;
    isBrowser(studyId: string): boolean;
    isSuper(): boolean;
    isSupervisor(organizationId: string): boolean;
    isClientAdmin(): boolean;
    findRole(valueId: string, appEnum: ApplicationsEnum, scopeEnum: ApplicationScopeEnum): IRoleScope;
}

export const userSchema: JsonValidatorSchema = {
  type: 'object',
  properties: {
    name: { type: 'string' },
    active: { type: 'boolean' },
    address: { type: 'string', nullable: true },
    blinded: { type: 'boolean' },
    changeEmailCode: { type: 'string', nullable: true },
    changeEmailConfirmed: { type: 'boolean' },
    email: { type: 'string' },
    firstName: { type: 'string' },
    language: { type: 'string', nullable: true },
    lastName: { type: 'string' },
    loginAttemptsNumber: { type: 'number' },
    ResetPasswordCode: { type: 'string', nullable: true },
    ResetPasswordConfirmed: { type: 'boolean' },
    password: { type: 'string', nullable: true},
    passwordExpiresOn: { type: 'string', nullable: true },
    registrationCode: { type: 'string', nullable: true },
    registrationConfirmed: { type: 'boolean' },
    role: { type: 'string' },
    mobile: { type: 'string' },
    mobilePrefix: { type: 'string' },
    mobileConfirmed: { type: 'boolean' },
    twoStepAuthEnabled: { type: 'string' },
    roleActive: { type: 'boolean' },
    timezone: { type: 'string', nullable: true },
    unlockCode: { type: 'string', nullable: true },
    associatedOrganization: { type: 'string', nullable: true },
    requireTraining: { type: 'boolean'},
    trainingCompletedAt: { type: 'string', nullable: true}
  },
  required: ['name'],
  //To be changed after demo 19/07/22
  additionalProperties: true
};

export const usersSchema: JsonValidatorSchema = {
  type: 'array',
  items: userSchema
};

export enum UserRoleEnum {
  BOARD_BLINDED = 'board-blinded',
  BOARD_UNBLINDED = 'board-unblinded',
  BROWSER = 'browser',
  CHAIRPERSON = 'chairperson',
  COORDINATOR = 'coordinator',
  SUPERVISOR = 'supervisor',
  CUSTOM_ROLE = 'custom-role',
  GUEST = 'guest',
  MEMBER = 'member',
  STATISTICIAN = 'statistician',
  ADMIN = 'administrator',
  SUPER_USER = 'super-user',
  USER = 'user',
  CLIENT_ADMIN = 'client-admin'
}

export enum UserLanguageEnum {
  EN = 'en',
  IT = 'it'
}

export class User implements IUser {
  /** @description primary key */
  public readonly name: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly address: string;
  public readonly language: UserLanguageEnum;
  public readonly timezone: string;
  public readonly changeEmailCode: string;
  public readonly active: boolean;
  public readonly roleActive: boolean;
  public readonly blinded: boolean;
  public readonly role: string;
  public readonly roles: IRoleScope[];
  public readonly roleName: string;
  public readonly mobile: string;
  public readonly mobilePrefix: string;
  public readonly mobileConfirmed: boolean;
  public readonly twoStepAuthEnabled: string;
  public readonly associatedOrganization: string;
  public readonly requireTraining: boolean;
  public readonly trainingCompletedAt: string;

  constructor(user: Partial<IUser> = {}) {
    this.name = user.name || StringEmpty;
    this.firstName = user.firstName || StringEmpty;
    this.lastName = user.lastName || StringEmpty;
    this.email = user.email || StringEmpty;
    this.address = user.address || StringEmpty;
    this.language = user.language || UserLanguageEnum.EN;
    this.timezone = user.timezone || StringEmpty;
    this.changeEmailCode = user.changeEmailCode || StringEmpty;
    this.active = user.active || false;
    this.roleActive = user.roleActive || false;
    this.blinded = user.blinded || false;
    this.role = user.role || StringEmpty;
    this.roles = user.roles || [];
    this.roleName = user.roleName || StringEmpty;
    this.mobile = user.mobile || StringEmpty;
    this.mobilePrefix = user.mobilePrefix || StringEmpty;
    this.mobileConfirmed = !!user.mobileConfirmed;
    this.twoStepAuthEnabled = user.twoStepAuthEnabled || StringEmpty;
    this.associatedOrganization = user.associatedOrganization || null;
    this.requireTraining = user.requireTraining || false;
    this.trainingCompletedAt = user.trainingCompletedAt || null;
  }

  public isCoordinator(studyId: string): boolean {
    return this.findRole(studyId, ApplicationsEnum.DSMB, ApplicationScopeEnum.STUDY)?.role === UserRoleEnum.COORDINATOR;
  }

  public isSupervisor(organizationId: string): boolean {
    return this.findRole(organizationId, ApplicationsEnum.DSMB, ApplicationScopeEnum.ORGANIZATION)?.role === UserRoleEnum.SUPERVISOR;
  }

  public isBrowser(studyId: string): boolean {
    return this.findRole(studyId, ApplicationsEnum.DSMB, ApplicationScopeEnum.STUDY)?.role === UserRoleEnum.BROWSER;
  }

  public isSuper(): boolean {
    return this.roles.some(({ role, application }) => role === UserRoleEnum.SUPER_USER && application === ApplicationsEnum.PLATFORM);
  }

  public isClientAdmin(): boolean {
    return this.roles.some(({ role, application }) => role === UserRoleEnum.CLIENT_ADMIN && application === ApplicationsEnum.PLATFORM);
  }

  public findRole(valueId: string, appEnum: ApplicationsEnum, scopeEnum: ApplicationScopeEnum): IRoleScope {
    return this.roles.find(({ active, application, scope, value }) => {
      return active &&
        application === appEnum &&
        scope === scopeEnum &&
        value === valueId;
    });
  }
}
