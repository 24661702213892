import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { IOrganization } from '../../interfaces/organization.interface';
import { OrganizationService } from '../../services/organization.service';
import {
  ActivateOrganizationAction, CreateNewOrganizationAction, DeactivateOrganizationAction,
  GetOrganizationsAction, GetOrganizationWithApplicationAction, GetSelfOrganizationsAction
} from './organization.actions';
import { ORGANIZATION_STATE_TOKEN } from './organization.token';

export type IOrganizationState = Record<'organizations' | 'selfOrganizations', IOrganization[]>;

@State({
  name: ORGANIZATION_STATE_TOKEN,
  defaults: OrganizationState.defaultState,
})
@Injectable()
export class OrganizationState {
  static defaultState: IOrganizationState = {
    organizations: [],
    selfOrganizations: [],
  };

  constructor(
    private readonly organizationService: OrganizationService
  ) {
  }

  @Selector()
  static getOrganizations(state: IOrganizationState) {
    return state.organizations;
  }

  @Selector()
  static getSelfOrganizations(state: IOrganizationState) {
    return state.selfOrganizations;
  }

  @Action(GetOrganizationsAction)
  getAllOrganizations({ patchState }: StateContext<IOrganizationState>) {
    return this.organizationService.getOrganizations().pipe(tap(organizations => {
      patchState({ organizations });
    }));
  }

  @Action(GetSelfOrganizationsAction)
  getAllSelfOrganizations({ patchState, getState }: StateContext<IOrganizationState>) {
    const state = getState()
    if (!state.selfOrganizations || (state.selfOrganizations && state.selfOrganizations.length == 0)) {
      return this.organizationService.getSelfOrganizations().pipe(tap(selfOrganizations => {
        patchState({ selfOrganizations });
      }));
    }
  }

  @Action(CreateNewOrganizationAction)
  createNewOrganization(_, { organization }: CreateNewOrganizationAction) {
    return this.organizationService.addOrganization(organization);
  }

  @Action(ActivateOrganizationAction)
  activateOrganization(_, { organizationId }: ActivateOrganizationAction) {
    return this.organizationService.activateOrganization(organizationId);
  }

  @Action(DeactivateOrganizationAction)
  deactivateOrganization(_, { organizationId }: DeactivateOrganizationAction) {
    return this.organizationService.deactivateOrganization(organizationId);
  }

  @Action(GetOrganizationWithApplicationAction)
  getOrganizationWithApplication({ patchState }: StateContext<IOrganizationState>, { applicationId }: GetOrganizationWithApplicationAction) {
    return this.organizationService.getOrgsWithApplication(applicationId).pipe(tap(organizations => {
      patchState({ organizations });
    }));
  }

}

