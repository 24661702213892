import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { UserState } from "../store/user/user.state";
import { combineLatest, Observable, of, switchMap, } from "rxjs";
import { IUser } from "../model/user.model";
import { ApplicationParameterDTO } from "../dtos/application-parameter.dto";
import { OperationState } from "../store/operation/operation.state";
import { GetPlatformRolesOperationsAction } from "../store/operation/operation.actions";
import { GetUserInfoAction } from "../store/user/user.actions";

@Injectable({ providedIn: 'root' })
export class FAGuard implements CanActivate {
  

    constructor(
      private readonly router: Router,
      private readonly store: Store,
    ) {}


    @Select(UserState.getLoggedUser) public readonly loggedUser$: Observable<IUser>;
    @Select(OperationState.getApplicationParameters) public readonly applicationParameters$: Observable<ApplicationParameterDTO[]>;
    
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
      // console.log('FAGUARD')
      // return true;
      // Added filter to avoid that during logout we get inside again, and the navigate to profile is saved in the redirectUrl,
      // Resulting that on next login user get redirected back into the profile
      //   filter(([loggedUser, _]) => !!loggedUser?.name),  // Ensure that the user is logged in
      // console.log('route', route);
      // console.log('route.data', route.data);
      // console.log('route.data[isForced];', route.data['isForced']);
      // console.log('route.data[userMethod];', route.data['userMethod']);
      // #9364 - IF application param 2FA Force Login is set to true, then if user does not have already set 
      // a 2FA method it will be forced to be redirected to profile page

      // Allow navigation
      return of(true).pipe(
        switchMap(() => this.store.dispatch(new GetUserInfoAction())),
        switchMap(() => combineLatest([this.loggedUser$])),
        switchMap(([user]: [IUser]) => {
          return of(user.twoStepAuthEnabled);
        }),
        switchMap(userTwoStepAuthEnabled => {
            return combineLatest([of(userTwoStepAuthEnabled), this.applicationParameters$])
        }),
        switchMap(([userTwoStepAuthEnabled, appParams]: [string, ApplicationParameterDTO[]]) => {
            if (appParams.length == 0) {
                console.log("appParams.length")
                return this.store.dispatch(new GetPlatformRolesOperationsAction).pipe(
                    switchMap(() =>  {
                      return of({
                        userMethod: userTwoStepAuthEnabled,
                        isForced: appParams.find(p => p.id === "2fa-force-login")?.value === "1"
                    })
                    })
                );
            }
            // const is2FAForced = appParams.find(p => p.id === "2fa-force-login")?.value === "1";
            return of({
                userMethod: userTwoStepAuthEnabled,
                isForced: appParams.find(p => p.id === "2fa-force-login")?.value === "1"
            });
        }),
        switchMap((ret) => { 

          const is2FAForced = ret.isForced;
          const user2FAMethod =  ret.userMethod;
          
          if (is2FAForced && !user2FAMethod.length) {
            this.router.navigate(['/profile']);
            return of(false);
          }

          return of(true);
        })
      );
    }
}