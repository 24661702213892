export const environment = {
  production: false,
  debug: true,
  local: false,
  environment: 'Development',
  apiClientId: 'd443d2b277ac4df99a38ce3ea7ed4d1b',
  platformHost: 'https://platform-edsmb-net.ethical.ch',
  dsmbHost: 'https://dsmb-edsmb-net.ethical.ch',
  languageCookieName: 'eclinicalsuite-lang',
  timezoneCookieName: 'eclinicalsuite-timezone',
  recaptchaSiteKey: '6Ld77RoUAAAAAG_kZAe3rLJXrB16Ld307ST_mrbv',
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  maxFileSizeLimitMB: 2000
};
