import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IUser } from '../../model/user.model';
import { AuthService } from '../../services/auth.service';
import { SidebarService } from '../../services/sidebar.service';
import { Select, Store } from '@ngxs/store';
import { SidebarState } from '../../store/sidebar/sidebar.state';
import { UserState } from '../../store/user/user.state';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() public readonly showCollapseButton = false;

  @Select(UserState.getLoggedUser) loggedUser$: Observable<IUser>;
  @Select(SidebarState.getSidebarOpen) public readonly sidebarOpen$: Observable<boolean>;

  public loggedUser: IUser;
  private helpdeskUrl: string;

  constructor(
    public readonly sidebarService: SidebarService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly cookieService: CookieService,
  ) { }

  public ngOnInit(): void {
    this.helpdeskUrl = '/login/new-helpdesk-public';

    if (this.authService.userIsLogged()) {
      this.loggedUser$.subscribe(loggedUser => {
          this.loggedUser = loggedUser;
      });

      this.helpdeskUrl = '/new-helpdesk';
    }
  }

  public logout(): void {
    this.authService.logout();
  }
}
