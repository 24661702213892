import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './platform/services/auth.service';
import { CookieService } from './platform/services/cookie.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isBackgroundImage = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly cookieService: CookieService,
    private readonly router: Router,
    public readonly authService: AuthService,
    private ccService: NgcCookieConsentService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects == "/login") {
          this.isBackgroundImage = true;
        } 
        else {
          this.isBackgroundImage = false;
        }
        $(() => {
          $('body').tooltip({ selector: '[data-toggle=tooltip]' });
        });
      }

      if (event instanceof NavigationStart) {
        $('body > .tooltip').remove();
      }
    });
  }

  ngOnInit() {
    this.translate.addLangs(['en', 'it']);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|it/) ? browserLang : 'en').pipe(
      tap(_ => {
        this.translate//
        .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
        .subscribe(data => {
          this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
          // Override default messages with the translated ones
          this.ccService.getConfig().content.header = data['cookie.header'];
          this.ccService.getConfig().content.message = data['cookie.message'];
          this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
          this.ccService.getConfig().content.allow = data['cookie.allow'];
          this.ccService.getConfig().content.deny = data['cookie.deny'];
          this.ccService.getConfig().content.link = data['cookie.link'];
          this.ccService.getConfig().content.policy = data['cookie.policy'];

          this.ccService.destroy(); // remove previous cookie bar (with default messages)
          this.ccService.init(this.ccService.getConfig()); // update config with translated messages
        });
      })
    );
  }
}
