import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { MeetingPollDTO, MeetingPollSetDTO, MeetingPollsTotalDTO } from '../../dtos/meeting-poll';
import { MeetingPollHistoryDTO } from '../../dtos/meeting-poll-history.dto';
import { MeetingPollsService } from '../../services/meeting-polls.service';
import { GetEditMeetingPollAction, GetMeetingPollConfigurationAction, GetMeetingPollCountAction, GetMeetingPollHistoryAction, GetMeetingPollHistoryCountAction, GetMeetingPollsToVoteAction, GetMeetingPollsToVoteCountAction, UpdateMeetingPollConfigurationAction } from './meeting-poll.actions';
import { MEETING_POLL_STATE_TOKEN } from './meeting-poll.token';
import { MeetingPollConfigurationDTO } from '../../dtos/meeting-poll-configuration.dto';
import { MeetingPollOptionConfigurationDTO } from '../../dtos/meeting-poll-option-configuration.dto';

export class IMeetingPollState {
    editMeetingPoll: MeetingPollSetDTO;
    meetingPollHistory: MeetingPollHistoryDTO[];
    meetingPollHistoryCount: number;
    meetingPollToVote: MeetingPollDTO[];
    meetingPollToVoteCount: number;
    meetingPollCount: number;
    meetingPollForDecisionItem: MeetingPollsTotalDTO;
    meetingPollTemplate: MeetingPollConfigurationDTO[];
    meetingPollOptionTemplate: MeetingPollOptionConfigurationDTO[];
}

@State({
    name: MEETING_POLL_STATE_TOKEN,
    defaults: MeetingPollState.defaultState
})
@Injectable()
export class MeetingPollState {
    static defaultState: IMeetingPollState = {
        editMeetingPoll: null,
        meetingPollHistory: [],
        meetingPollHistoryCount: null,
        meetingPollToVote: [],
        meetingPollToVoteCount: null,
        meetingPollCount: null,
        meetingPollForDecisionItem: null,
        meetingPollTemplate: [],
        meetingPollOptionTemplate: [],
    };

    constructor(
        private readonly store: Store,
        private readonly meetingPollService: MeetingPollsService
    ) {
    }

    @Selector()
    static getEditMeetingPoll(state: IMeetingPollState) {
        return state.editMeetingPoll;
    }

    @Selector()
    static getMeetingPollHistory(state: IMeetingPollState) {
        return state.meetingPollHistory;
    }

    @Selector()
    static getMeetingPollTemplate(state: IMeetingPollState) {
        return state.meetingPollTemplate;
    }

    @Selector()
    static getMeetingPollOptionTemplate(state: IMeetingPollState) {
        return state.meetingPollOptionTemplate;
    }

    @Selector()
    static getMeetingPollHistoryCount(state: IMeetingPollState) {
        return state.meetingPollHistoryCount;
    }

    @Selector()
    static getMeetingPollsToVote(state: IMeetingPollState) {
        return state.meetingPollToVote;
    }

    @Selector()
    static getMeetingPollsCountToVote(state: IMeetingPollState) {
        return state.meetingPollToVoteCount;
    }

    @Selector()
    static getMeetingPollCount(state: IMeetingPollState) {
        return state.meetingPollCount;
    }
    

    @Action(GetEditMeetingPollAction)
    getEditMeetingPollHistory({ patchState }: StateContext<IMeetingPollState>,
                          { organizationId,studyId, meetingId, pollId }: GetMeetingPollHistoryAction) {
        return this.meetingPollService.getPoll(organizationId,studyId, meetingId, pollId)
                                      .pipe(tap(editMeetingPoll => patchState({editMeetingPoll})));
    }

    @Action(GetMeetingPollHistoryAction)
    getMeetingPollHistory({ patchState }: StateContext<IMeetingPollState>,
                          { organizationId,studyId, meetingId, pollId, startPage, itemsPerPage }: GetMeetingPollHistoryAction) {
        return this.meetingPollService.getMeetingPollHistory(organizationId,studyId, meetingId, pollId, startPage, itemsPerPage)
                                      .pipe(tap(meetingPollHistory => patchState({meetingPollHistory})));
    }

    @Action(GetMeetingPollHistoryCountAction)
    getMeetingPollHistoryCount({ patchState }: StateContext<IMeetingPollState>,
                               { organizationId,studyId, meetingId, pollId}: GetMeetingPollHistoryCountAction) {
        return this.meetingPollService.getMeetingPollHistoryCount(organizationId,studyId, meetingId, pollId)
                                      .pipe(tap(meetingPollHistoryCount => patchState({meetingPollHistoryCount})));
    }

    @Action(GetMeetingPollsToVoteAction)
    getMeetingPollsToVote({ patchState }: StateContext<IMeetingPollState>,
                               { organizationId,studyId, userId}: GetMeetingPollsToVoteAction) {
        return this.meetingPollService.getPollsToVote(organizationId,studyId, userId)
                                      .pipe(
                                        tap(meetingPollToVote => patchState({meetingPollToVote}))
                                      );
    }

    @Action(GetMeetingPollsToVoteCountAction)
    getMeetingPollsToVoteCount({ patchState }: StateContext<IMeetingPollState>,
                               { organizationId,studyId, userId}: GetMeetingPollsToVoteCountAction) {
        return this.meetingPollService.getPollsToVoteCount(organizationId,studyId, userId)
                                      .pipe(tap(meetingPollToVoteCount => patchState({meetingPollToVoteCount})));
    }

    @Action(GetMeetingPollCountAction)
    getMeetingPollCount({ patchState }: StateContext<IMeetingPollState>,
                               { organizationId,studyId, meetingId}: GetMeetingPollCountAction) {
        return this.meetingPollService.getMeetingPollCount(organizationId, studyId, meetingId)
                                      .pipe(tap(meetingPollCount => patchState({meetingPollCount})));
    }

    @Action(GetMeetingPollConfigurationAction)
    getMeetingPollConfiguration({ patchState }: StateContext<IMeetingPollState>,
        { organizationId,studyId}: GetMeetingPollConfigurationAction) {
            return this.meetingPollService.getMeetingPollConfiguration(organizationId, studyId)
               .pipe(tap(meetingPollTemplate => patchState({meetingPollTemplate})));
    }

    @Action(UpdateMeetingPollConfigurationAction)
    updateMeetingPollConfiguration({ patchState }: StateContext<IMeetingPollState>, { organizationId, studyId, meetingPolls }: UpdateMeetingPollConfigurationAction) {
        return  this.meetingPollService.updateMeetingPollConfiguration(organizationId, studyId, meetingPolls)
            .pipe(tap(meetingPollTemplate => {
                patchState({ meetingPollTemplate })
            }));
    }
}
