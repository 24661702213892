import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigurationDTO } from '../dtos/configuration.dto';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private readonly httpClient: HttpClient) { }

  getConfigurations(organizationId: string, studyId: string, applicationId: string): Observable<ConfigurationDTO[]> {
    return this.httpClient.get<ConfigurationDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/application/${applicationId}/study/${studyId}/configurations`);
  }

  getDsmbConfigurations(organizationId: string, studyId: string): Observable<ConfigurationDTO[]> {
    return this.httpClient.get<ConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/configurations`);
  }

  updateConfigurations(newConfigurations: ConfigurationDTO[],organizationId: string, studyId: string, applicationId: string) {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/application/${applicationId}/study/${studyId}/configurations/update`, newConfigurations);
  }

  uploadTemplateAgenda(organizationId: string, studyId: string, template: FormData): Observable<ConfigurationDTO[]> {
    return this.httpClient.post<ConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/configurations/upload-template-agenda`, template);
  }

  uploadTemplateOutcome(organizationId: string, studyId: string, template: FormData): Observable<ConfigurationDTO[]> {
    return this.httpClient.post<ConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/configurations/upload-template-outcome`, template);
  }

  uploadTrainingVideo(organizationId: string, studyId: string, videoInfo: FormData): Observable<ConfigurationDTO[]> {
    return this.httpClient.post<ConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/configurations/upload-training-video`, videoInfo);
  }
}
