import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Store} from '@ngxs/store';

// This code was deeply based on these links:
// Implementation Idea: https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store,
    private readonly authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    if (request.url.endsWith('/platform/user/login')) {
      return next.handle(request);
    }

    // If not an authentication call, add token and retry in case of failure
    request = this.authService.addAuthenticationToken(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.endsWith('/refresh-token')) {
          this.authService.logout();
        }

        if (this.authService.userIsLogged() && error.status === 401) {
          return this.authService.refreshTokens(
            () => {
              next.handle(this.authService.addAuthenticationToken(request));
            },
            catchError(() => {
              this.authService.refreshTokenInProgress = false;
              this.authService.logout();
              return throwError(() => error);
            })
          );
        }

        return throwError(() => error);
      }
    ));
  }
}
