import { MeetingId } from '../../../platform/utils/types';
import { CancelMeetingDTO } from '../../dtos/cancel-meeting.dto';
import { MeetingAgendaDTO } from '../../dtos/meeting-agenda.dto';
import { MeetingAttendeeDTO } from '../../dtos/meeting-attendee.dto';
import { MeetingOutcomeDTO } from '../../dtos/meeting-outcome.dto';
import { IMeetingResendNotifications } from '../../dtos/meeting-resend-notifications';
import { MeetingDTO } from '../../dtos/meeting.dto';
import { ActivateMeetingDTO } from '../../dtos/activate-meeting';
import { ScheduleMeetingDTO } from '../../dtos/schedule-meeting.dto';
import { CompleteMeetingDTO } from '../../dtos/complete-meeting';
import { RemoveMeetingDTO } from '../../dtos/remove-meeting.dto';

// Action relative to meetings -----------------------------------------------------------------------------
export class GetMeetingsAction {
    static readonly type = '[Meeting] [GET] [Get Meetings]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public filter: string, 
        public startPage?: number,
        public itemsPerPage?: number, 
        public sorting?: string) {

    }
}

export class GetMeetingsCountAction {
    static readonly type = '[Meeting] [GET] [Get Meetings Count]';

    constructor(
        public organizationId:string,
        public studyId: string,
        public filter: string) {

    }
}

export class GetMeetingsByOrganizationAction{
    static readonly type = '[Meeting] [GET] [Get Organization Meetings]'
    constructor(
        public organizationId:string,
        public filter: string, 
        public startPage?: number,
        public itemsPerPage?: number, 
        public sorting?: string) {
    }
}

export class GetUpcomingMeetingsAction {
    static readonly type = '[Meeting] [GET] [Get Upcoming Meetings]';

    constructor(
        public organizationId:string,
        public studyId: string,
        public topCondition: string,) {

    }
}

export class GetUpcomingMeetings4OrganizationAction {
    static readonly type = '[Meeting] [GET] [Get Upcoming Meetings For Organization]';
    constructor(public organizationId: string, public timeCondition: string) {

    }
}

export class GetPastMeetings4OrganizationAction {
    static readonly type = '[Meeting] [GET] [Get Past Meetings For Organization]';
    constructor(public organizationId: string, public timeCondition: string) {

    }
}

export class GetUpcomingMeetingsCountAction {
    static readonly type = '[Meeting] [GET] [Get Upcoming Meetings Count]';

    constructor(
        public organizationId:string,
        public studyId: string){

    }
}

export class GetPastMeetingsAction {
    static readonly type = '[Meeting] [GET] [Get Past Meetings]';

    constructor(
        public organizationId:string,
        public studyId: string) {

    }
}

export class GetClosedMeetingsCountAction {
    static readonly type = '[Meeting] [GET] [Get Closed Meetings Count]';

    constructor(
        public organizationId:string,
        public studyId: string) {

    }
}

export class GetMeetingsAssociatedWithDiscussionItemsAction {
    static readonly type = '[Meeting] [GET] [Get Meetings Assicuated With Discussion Items]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public filter: string) {

    }
}

export class GetMeetingsAssociatedWithDecisionsAction {
    static readonly type = '[Meeting] [GET] [Get Meetings Associated With Decisions';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string
    ) {

    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Action relative to meeting ------------------------------------------------------------------------------
export class GetMeetingAction {
    static readonly type = '[Meeting] [GET] [Get Meeting]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId) {

    }
}

export class AddMeetingAction {
    static readonly type = '[Meeting] [POST] [Add Meeting]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meeting: MeetingDTO) {

    }
}

export class UpdateMeetingDetailsAction {
    static readonly type = '[Meeting] [POST] [Update Meeting Details]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meeting: MeetingDTO) {

    }
}

export class UpdateMeetingLinkAction {
    static readonly type = '[Meeting] [POST] [Update Meeting Link]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: string, 
        public payload: {
            link: string,
            password: string
        }
    ) { }
}

export class GetMeetingAttendeesAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Attenddees]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId | string) {

    }
}

export class SaveMeetingAttendeesAction {
    static readonly type = '[Meeting] [POST] [Save Meeting Atttendees]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public newAttendees: MeetingAttendeeDTO[]) {

    }
}

export class ScheduleMeetingAction {
    static readonly type = '[Meeting] [POST] [Schedule Meeting]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingScheduled: ScheduleMeetingDTO) {

    }
}

export class ActivateMeetingAction {
    static readonly type = '[Meeting] [POST] [Activate Asynchronous Meeting]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meeting: ActivateMeetingDTO) {

    }
}

export class CompleteMeetingAction {
    static readonly type = '[Meeting] [POST] [Complete Asynchronous Meeting]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meeting: CompleteMeetingDTO) {

    }
}

export class ConfirmMeetingAction {
    static readonly type = '[Meeting] [POST] [Confirm Meeting]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingConfirmed: MeetingDTO) {

    }
}

export class CancelMeetingAction {
    static readonly type = '[Meeting] [POST] [Cancel Meeting]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingCancelled: CancelMeetingDTO) {

    }
}

export class RemoveMeetingAction {
    static readonly type = '[Meeting] [POST] [Remove Meeting]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingRemoved: RemoveMeetingDTO) {

    }
}

export class ReOpenMeetingAction {
    static readonly type = '[Meeting] [POST] [Re-open Meeting]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingReOpened: MeetingDTO) {
    }
}

export class SendMeetingReminderNotificationAction {
    static readonly type = '[Meeting] [POST] [Send Reminder Notification]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public payload: {
          attendees: MeetingAttendeeDTO[]
        }
      ) { }
}

// Meeting Status action ------------------------------------------------------------------------------------
export class GetMeetingStatusesAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Statuses]';
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Meeting Status action ------------------------------------------------------------------------------------
export class GetMeetingSessionsAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Sessions]';
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////



// Meeting Action Relative to Meeting Agenda ---------------------------------------------------------------------------------

// Action to add a new agenda to a meeting
export class AddMeetingAgendaAction {
    static readonly type = '[Meeting] [POST] [Add Meeting Agenda]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingAgenda: MeetingAgendaDTO) {

    }
}

// Action to get a meeting agenda
export class GetMeetingAgendaAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Agenda]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId) {
    }
}

// Action to update a meeting agenda
export class UpdateMeetingAgendaAction {
    static readonly type = '[Meeting] [POST] [Update Meeting Agenda]';

    constructor(        
        public organizationId: string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingAgenda: MeetingAgendaDTO) {

    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Meeting Action Relative to Meeting Outcome ---------------------------------------------------------------------------------

// Action to add a new outcome to a meeting
export class AddMeetingOutcomeAction {
    static readonly type = '[Meeting] [POST] [Add Meeting Outcome]';

    constructor(
        public organizationId:string, 
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingOutcome: MeetingOutcomeDTO) {

    }
}

// Action to get a meeting outcome
export class GetMeetingOutcomeAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Outcome]';

    constructor(
        public organizationId:string, 
        public studyId: string, 
        public meetingId: MeetingId) {

    }
}

// Action to update a meeting outcome
export class UpdateMeetingOutcomeAction {
    static readonly type = '[Meeting] [POST] [Update Meeting Outcome]';

    constructor(
        public organizationId:string,
        public studyId: string, 
        public meetingId: MeetingId, 
        public meetingOutcome: MeetingOutcomeDTO) {

    }
}

// Action to get a meeting template configuration
export class GetMeetingTemplateConfigurationAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Template Configuration]';

    constructor(
        public organizationId:string, 
        public studyId: string, 
        public meetingSession: string) {

    }
}

export class GetPossibleNewMeetingAttendeesAction {
    static readonly type = '[Meeting] [GET] [Get Possible Meeting Attendees]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public meetingSession: string) {

    }
}

export class ResendMeetingAttendeeEmailAction {
  static readonly type = '[Meeting] [POST] [Resend Attendee Email]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public meetingId: MeetingId,
    public meetingResendNotifications: IMeetingResendNotifications
  ) { }
}

// Meeting Type
export class GetMeetingTypesAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Types]';

    constructor(
        public organizationId: string,
        public studyId: string
    ) {}
}

export class GetMeetingDiscussionItemConfigurationAction {
    static readonly type = '[Meeting] [GET] [Get Meeting Discussion Item Configuration]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingType: string
    ) { }
}
