import { ApplicationsEnum } from '../../dtos/application.dto';

export class GetPlatformRolesOperationsAction {
  static readonly type = '[Operation] [GET] [Platform Roles Operations]';
}

export class GetApplicationParameters {
  static readonly type = '[Application] [GET] [Parameters]';
}

export class GetApplicationParametersAction {
  static readonly type = '[Operation] [GET] [Application Parameters]';
}

export class GetOrganizationOperationsAction {
  static readonly type = '[Operation] [GET] [Organization Logs]';

  constructor(public organizationID: string, public queryString?: string) {
  }
}

export class GetApplicationOperationsAction {
  static readonly type = '[Operation] [GET] [Application Logs]';

  constructor(public applicationID: ApplicationsEnum) {
  }
}

export class GetOperationForLogsAction {
  static readonly type = '[Operation] [GET] [Operation Logs]';

  constructor(public applicationId: string,
              public organizationId: string,
              public studyId: string[],
              public username: string){
  }
}
