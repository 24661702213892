import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StringEmpty } from '../utils/global-vars';
import { JsonValidatorSchema, JsonValidatorService } from './JsonValidator.service';
import { LoggingService } from './logging.service';
import { ODataQueryParams, ODataQueryParamsMap, ODataQueryParamsOptions, ODataService } from './odata.service';

export interface DsmbHttpOptions {
  odataQueryParams?(builder: (params?: ODataQueryParams, options?: ODataQueryParamsOptions) => ODataQueryParamsMap): string;
  validateSchema?: JsonValidatorSchema;
  error(error?: HttpErrorResponse): void;
  success(result: any): void;
}

export interface HttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
}

export enum HttpHeaderEnum {
  contentType = 'content-type',
  contentDisposition = 'content-disposition'
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly odataService: ODataService,
    private readonly jsonValidatorService: JsonValidatorService,
    private readonly loggingService: LoggingService
  ) { }

  public get<T>(url: string, options: HttpOptions & DsmbHttpOptions): Observable<T> {
    const queryParams = options?.odataQueryParams?.(this.odataService.serialize) || StringEmpty;

    return this.httpClient.get<T>(url + queryParams, options).pipe(
      switchMap(result => {
        if (!options.validateSchema) {
          return of(result);
        }

        const { valid, errors } = this.jsonValidatorService.validate(options.validateSchema, result);

        if (valid) {
          return of(result);
        } else {
          if (environment.debug) {
            return throwError(() => errors);
          } else {
            // TODO - Use Action PostLogAction
            this.loggingService.writeLog({
              operation: StringEmpty,
              application: StringEmpty,
              study: null,
              success: false,
              error: `API invalid response: ${url + queryParams} \n
                      Errors: ${JSON.stringify(errors)}`
            }).subscribe();

            return of(result);
          }
        }
      }),
      tap(result => options.success(result)),
      catchError(error => {
        options.error(error);

        return throwError(() => error);
      })
    );
  }

  public post<T>(url: string, body?, options: HttpOptions & DsmbHttpOptions = {
    error: () => {},
    success: () => {}
  }): Observable<T> {
    return this.httpClient.post<T>(url, body).pipe(
      tap(result => options.success(result)),
      catchError(error => {
        options.error(error);

        return throwError(() => error);
      })
    );
  }

  public readResponseHeaders<T>(httpResponse: HttpResponse<T>): Map<string, string> {
    const headers = new Map([
      [HttpHeaderEnum.contentType.valueOf(), httpResponse.headers.get(HttpHeaderEnum.contentType)]
    ]);

    const contentDisposition = httpResponse.headers.get(HttpHeaderEnum.contentDisposition);

    contentDisposition.split('; ').forEach(header => {
      const [key, value] = header.split('=');

      headers.set(`${HttpHeaderEnum.contentDisposition}.${key}`, value);
    });

    return headers;
  }
}
