import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountersignVerificationData } from '../dtos/countersign-verification-data.dto';
import { DocumentCountersignDTO } from '../dtos/document-countersign.dto';

@Injectable({
    providedIn: 'root'
  })
  export class DocumentCountersignService {

    constructor(
      private readonly httpClient: HttpClient
    ) { }


    getDocumentCountersignInfo(organizationId: string, studyId: string, documentId: string, versionNo: number): Observable<DocumentCountersignDTO[]> {
        return this.httpClient.get<DocumentCountersignDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/version/${versionNo}/countersign-info`);
    }

    verifyCountersignPassword(organizationId: string, studyId: string, verificationData: CountersignVerificationData) {
      return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/countersign/verify-password`, verificationData);
    }

    countersignDocument(organizationId: string, studyId: string, documentId: string, versionNo: number, documentCountersign: DocumentCountersignDTO): Observable<DocumentCountersignDTO> {
        return this.httpClient.post<DocumentCountersignDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/version/${versionNo}/countersign`, documentCountersign);
    }
  }
