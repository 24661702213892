import { ConfigurationDTO } from 'src/app/platform/dtos/configuration.dto';
import { StudyDTO } from 'src/app/platform/dtos/study.dto';

export class GetDsmbConfigurationsAction {
    static readonly type = '[Configuration] [GET] [Dsmb Configurations]';

    constructor(
        public organizationId: string,
        public studyId: string
        ) {
    }
}

export class GetDsmbSectionsAction {
    static readonly type = '[Configuration] [GET] [Dsmb Sections]';

    constructor(
        public organizationId: string,
        public studyId: string
        ) {
    }
}


export class UpdateConfigurationsAction {
    static readonly type = '[Configuration] [POST] [Update Configurations]';

    constructor(
        public newConfigurations: ConfigurationDTO[], 
        public organizationId: string,
        public studyId: string, 
        public applicationId: string) {
    }
}

export class UploadTemplateAgendaAction {
    static readonly type = '[Configuration] [POST] [Upload Template Agenda]';

    constructor (
        public organizationId: string,
        public studyId: string,
        public template: FormData
    ) {}
}

export class UploadTemplateOutcomeAction {
    static readonly type = '[Configuration] [POST] [Upload Template Output]';

    constructor (
        public organizationId: string,
        public studyId: string,
        public template: FormData
    ) {}
}

export class UploadTrainingVideoAction {
    static readonly type = '[Configuration] [POST] [Upload Training Video]';

    constructor (
        public organizationId: string,
        public studyId: string,
        public videoInfo: FormData
    ) {}
}

export class CloneStudyConfigurationAction {
    static readonly type = '[Configuration] [POST] [Clone Study Configuration]';

    constructor (
        public organizationId: string,
        public applicationId: string,
        public study: StudyDTO
    ) {}
}