import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, filter, Observable, tap } from 'rxjs';
import { ISidebarItem } from '../../../platform/interfaces/sidebar-item.interface';
import { SidebarState } from '../../store/sidebar/sidebar.state';
import { ApplicationParameterDTO } from '../../dtos/application-parameter.dto';
import { IUser } from '../../model/user.model';
import { OperationState } from '../../store/operation/operation.state';
import { UserState } from '../../store/user/user.state';
import { Router } from '@angular/router';


@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent /*implements OnInit*/  {
  @Select(SidebarState.getSidebarOpen) public readonly sidebarOpen$: Observable<boolean>;
  @Select(SidebarState.getSidebar) public readonly sidebar$: Observable<ISidebarItem[]>;
  @Select(UserState.getLoggedUser) public readonly loggedUser$: Observable<IUser>;
  @Select(OperationState.getApplicationParameters) public readonly applicationParameters$: Observable<ApplicationParameterDTO[]>;
  
  constructor( private readonly router: Router) { }

  // ngOnInit(): void {
    
    // combineLatest([
    //   this.loggedUser$,
    //   this.applicationParameters$
    // ]).pipe(
    //   // Added filter to avoid that during logout we get inside again, and the navigate to profile is saved in the redirectUrl,
    //   // Resulting that on next login user get redirected back into the profile
    //   filter(([loggedUser, _]) => !!loggedUser.name),
    //   tap(([loggedUser, appParams]) => {
    //     // #9364 - IF application param 2FA Force Login is set to true, then if user does not have already set 
    //     // a 2FA method it will be forced to be redirected to profile page
    //     const is2FAForced = appParams.find(p => p.id === "2fa-force-login").value === "1";
    //     const user2FAMethod = loggedUser.twoStepAuthEnabled;
    //     // To avoid to be stack in loop of going to profile and be redirected to profile,
    //     // we check that we are not already redirecting to it
    //     const url = this.router.url;
    //     const segments = url.split('/');
    //     const lastSegment = segments[segments.length - 1];
    //     if (is2FAForced && !user2FAMethod && lastSegment !== 'profile') {
    //       // Force user on profile page
    //       setTimeout(() => {
    //         void this.router.navigate(['/profile']);
    //       }, 50);

    //     }
    //   })
    // ).subscribe();


  // }
}
