import { ISidebarItem } from '../interfaces/sidebar-item.interface';
import { StringEmpty } from '../utils/global-vars';

export class SidebarItem implements ISidebarItem {
 id = StringEmpty;
 label = StringEmpty;
 link = StringEmpty;
 open = false;
 icon = StringEmpty;
 type = StringEmpty;
 numberActionRequired = null;
 children: SidebarItem[] = [];
}
