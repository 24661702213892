import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { StudyDTO } from '../../dtos/study.dto';
import { StudyService } from '../../services/study.service';
import { deepClone } from '../../utils/global-vars';
import {
  ActivateStudyAction, CloneOrganizationStudyAction, CreateNewOrganizationStudyAction, DeactivateStudyAction,
  GetOrganizationStudiesAction, GetOrganizationStudiesWithApplication, GetSelfStudiesAction, GetStudiesAction, GetStudiesTableAction, GetStudiesWithApplication
} from './study.actions';
import { STUDY_STATE_TOKEN } from './study.token';
import { ApplicationDTO } from '../../dtos/application.dto';
import { CloneStudyConfigurationAction } from 'src/app/dsmb/store/configurations/configuration.action';
import { AddApplicationToStudyAction, GetApplicationsAction } from '../application/application.actions';
import { ApplicationService } from '../../services/application.service';

type IStudyStateOrganization = Record<'studies' | 'selfStudies', StudyDTO[]>;

// https://www.ngxs.io/recipes/style-guide#avoid-saving-class-based-instances-in-your-state
function StudyStateOrganizationDTO() {
  return {
    studies: [],
    selfStudies: []
  };
}

export interface IStudyState {
  organizations: {
    [id: string]: IStudyStateOrganization
  };
  studies: StudyDTO[];
}

@State({
  name: STUDY_STATE_TOKEN,
  defaults: StudyState.defaultState
})
@Injectable()
export class StudyState {
  static defaultState: IStudyState = {
    organizations: {},
    studies: []
  };

  constructor(
    private readonly studyService: StudyService,
    private readonly applicationService: ApplicationService,
  ) {
  }

  @Selector()
  static getStudies(state: IStudyState) {
    return state.studies;
  }

  @Selector()
  static getOrganizationStudies(state: IStudyState) {
    return (organizationId: string) => state.organizations[organizationId]?.studies;
  }

  @Action(GetStudiesAction)
  getStudies({ patchState }: StateContext<IStudyState>) {
    return this.studyService.getAllStudies().pipe(tap(studies => {
      patchState({ studies });
    }));
  }

  @Action(GetOrganizationStudiesAction)
  getOrganizationStudies({ getState, setState, patchState }: StateContext<IStudyState>, { organizationId }: GetOrganizationStudiesAction) {
    return this.studyService.getStudiesByOrganizationId(organizationId).pipe(tap(studies => {
      const state = deepClone<IStudyState>(getState());

      state.organizations[organizationId] = state.organizations[organizationId] || StudyStateOrganizationDTO();
      state.organizations[organizationId].studies = studies;
      
      setState(state);
      patchState({ studies });
    }));
  }

  @Action(GetStudiesTableAction)
  getStudiesTableAction({ getState, setState }: StateContext<IStudyState>, { organizationId }: GetStudiesTableAction) {
    return this.studyService.getStudiesTableByOrganizationId(organizationId).pipe(tap(studies => {
      const state = deepClone<IStudyState>(getState());

      state.organizations[organizationId] = state.organizations[organizationId] || StudyStateOrganizationDTO();
      state.organizations[organizationId].studies = studies;

      setState(state);
    }));
  }

  @Action(CreateNewOrganizationStudyAction)
  createNewOrganizationStudy({ dispatch }: StateContext<IStudyState>, { organizationId, study }: CreateNewOrganizationStudyAction) {
    return this.studyService.addStudy(organizationId, study).pipe(
      tap(newStudy => {
        this.applicationService.getAllApplications().pipe(tap(app => {
          app.filter(a => a.appDefault).forEach(a => {
            dispatch(new AddApplicationToStudyAction(newStudy.organization, newStudy.id, a.id));
          })
        })).subscribe()
      })
    );
  }

  @Action(CloneOrganizationStudyAction)
  cloneOrganizationStudy({ dispatch }: StateContext<IStudyState>, { organizationId, study }: CloneOrganizationStudyAction) {
    return this.studyService.cloneStudy(organizationId, study).pipe( 
      switchMap((applications: ApplicationDTO[]) => {
        applications.forEach(a => {
          study.id = a.studyId;
          dispatch(new CloneStudyConfigurationAction(organizationId, a.id, study))
        })
        return applications;
      })
    );
  }

  @Action(ActivateStudyAction)
  activateOrganizationStudy({ setState }: StateContext<IStudyState>, { organizationId, studyId }: ActivateStudyAction) {
    return this.studyService.activateStudy(organizationId, studyId);
  }


  @Action(DeactivateStudyAction)
  deactivateOrganizationStudy({ setState }: StateContext<IStudyState>, { organizationId, studyId }: DeactivateStudyAction) {
    return this.studyService.deactivateStudy(organizationId, studyId);
  }

  @Action(GetSelfStudiesAction)
  getSelfStudies({ patchState }: StateContext<IStudyState>) {
    return this.studyService.getSelfStudies().pipe(tap(studies => {
      patchState({ studies });
    }));
  }

  @Action(GetStudiesWithApplication)
  getStudiesWithApplication({ patchState }: StateContext<IStudyState>,  { applicationId }: GetStudiesWithApplication) {
    return this.studyService.getStudiesWithApplication(applicationId).pipe(tap(studies => {
      patchState({ studies });
    }));
  }

  @Action(GetOrganizationStudiesWithApplication)
  getOrganizationStudiesWithApplication({ patchState }: StateContext<IStudyState>,  { applicationId, organizationId}: GetOrganizationStudiesWithApplication) {
    return this.studyService.getOrganizationStudiesWithApplication(applicationId, organizationId).pipe(tap(studies => {
      patchState({ studies });
    }));
  }
}
