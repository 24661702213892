import { ApplicationsEnum } from '../../dtos/application.dto';
import { ExportConfigDTO } from '../../dtos/export-config.dto';
import { LogDTO } from '../../dtos/log.dto';

export class GetLogsAction {
  static readonly type = '[Log] [GET]';

  constructor(
    public applicationId: string,
    public startPage: number,
    public itemsPerPage: number,
    public sorting: string,
    public filter: string
  ) {
  }
}

export class GetOrganizationLogsAction {
  static readonly type = '[Log] [GET] [Organization Logs]';

  constructor(
    public applicationID: ApplicationsEnum,
    public organizationID: string,
    public startPage: number,
    public itemsPerPage: number,
    public sorting: string,
    public filter: string
  ) {
  }
}

export class GetLogsCountAction {
  static readonly type = '[Log] [GET] [Count]';

  constructor(public applicationID: ApplicationsEnum, public filter: string) {
  }
}

export class GetOrganizationLogsCountAction {
  static readonly type = '[Log] [GET] [Organization Logs Count]';

  constructor(public applicationID: ApplicationsEnum, public organizationID: string, public filter: string) {
  }
}

export class PostLogAction {
  static readonly type = '[Log] [POST]';

  constructor(public log: LogDTO) {
  }
}


export class ExportLogsAction {
  static readonly type = '[Log] [POST] [Export Logs]';

  constructor(
    public applicationID: ApplicationsEnum,
    public sorting: string,
    public filter: string,
    public config: ExportConfigDTO
  ) { }
}

export class ExportOrganizationLogsAction {
  static readonly type = '[Log] [POST] [Export Organization Logs]';

  constructor(
    public applicationID: ApplicationsEnum,
    public organizationID: string,
    public sorting: string,
    public filter: string,
    public config: ExportConfigDTO
  ) { }
}

export class ExportStudyLogsAction {
  static readonly type = '[Log] [POST] [Export Study Logs]';

  constructor(
    public applicationID: ApplicationsEnum,
    public organizationId: string,
    public studyId: string,
    public sorting: string,
    public filter: string,
    public config: ExportConfigDTO
  ) { }
}

export class DownloadEmailAsMsg {
  static readonly type = '[Log] [Get] [Downlaod Email as Msg]';

  public readonly post = {
    notification: {
        success: 'platform.download-email.toastr-message'
    }
  };

  constructor(
    public application: string,
    public emailTitle: string,
    public emailBody: string
  ) {}
}
