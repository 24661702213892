import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TRAINING_HISTORY_TOKEN } from './training-history.token';
import { TrainingHistoryDTO } from '../../dtos/training-history.dto';
import { GetSelfTrainingHistoryAction, GetTrainingVideoAction, SignTrainingAction, VerifyTrainingPasswordAction } from './training-history.actions';
import { TrainingHistoryService } from '../../services/training-history.service';
import { TrainingVideoDTO } from '../../dtos/training-video.dto';

export interface ITrainingHistoryState {
  selfTrainingHistory: TrainingHistoryDTO,
  selfTrainingVideo: TrainingVideoDTO
}

@State({
  name: TRAINING_HISTORY_TOKEN,
  defaults: TrainingHistoryState.defaultState
})
@Injectable()
export class TrainingHistoryState {
  static defaultState: ITrainingHistoryState = {
    selfTrainingHistory: null,
    selfTrainingVideo: null
  };

  constructor(
    private readonly trainingHistoryService: TrainingHistoryService,
  ) { }

  @Selector()
  static getSelfTrainingHistory(state: ITrainingHistoryState){
    return state.selfTrainingHistory;
  }

  @Selector()
  static getSelfTrainingVideo(state: ITrainingHistoryState){
    return state.selfTrainingVideo;
  }

  @Action(GetSelfTrainingHistoryAction)
  getSelfTrainingHistory({ patchState }: StateContext<ITrainingHistoryState>, { organizationId, studyId }: GetSelfTrainingHistoryAction) { 
    return this.trainingHistoryService.getSelfTrainingHistory(organizationId, studyId).pipe(tap(selfTrainingHistory => patchState({ selfTrainingHistory })));
  }

  @Action(VerifyTrainingPasswordAction)
  verifyCountersignPassword(_, { organizationId, studyId, verificationData }: VerifyTrainingPasswordAction) {
      return this.trainingHistoryService.verifyTrainingPassword(organizationId, studyId, verificationData);
  }

  @Action(SignTrainingAction)
  signTraining({ patchState }: StateContext<ITrainingHistoryState>, { organizationId, studyId }: SignTrainingAction) {
    return this.trainingHistoryService.signTraining(organizationId, studyId).pipe(tap(trainingHistoryInfo => patchState({ selfTrainingHistory: trainingHistoryInfo })))
  }

  @Action(GetTrainingVideoAction)
  getTrainingVideo({ patchState }: StateContext<ITrainingHistoryState>, { organizationId, studyId }: GetTrainingVideoAction) {
    return this.trainingHistoryService.getTrainingVideo(organizationId, studyId).pipe(tap(trainingVideo => patchState({ selfTrainingVideo: trainingVideo })));
  }
}