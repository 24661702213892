import { StudyDTO } from '../../dtos/study.dto';

export class GetOrganizationStudiesAction {
  static readonly type = '[Study] [GET] [Studies by ord.id]';

  constructor(public organizationId: string) {
  }
}

export class GetStudiesTableAction {
  static readonly type = '[Study] [GET] [Studies Table by ord.id]';

  constructor(public organizationId: string) {
  }
}

export class GetOrganizationSelfStudiesAction {
  static readonly type = '[Study] [GET] [Self Studies by ord.id]';

  constructor(public organizationId: string) {
  }
}

export class GetStudiesAction {
  static readonly type = '[Study] [GET] [Studies]';
}

export class CreateNewOrganizationStudyAction {
  static readonly type = '[Study] [POST] [Create Study by ord.id]';

  constructor(public study: StudyDTO) {
  }
}

export class ActivateStudyAction {
  static readonly type = '[Study] [POST] [Activate Study]';

  constructor(
    public organizationId: string,
    public studyId: string) {
  }
}

export class DeactivateStudyAction {
  static readonly type = '[Study] [POST] [Deactivate Study]';

  constructor(
    public organizationId: string,
    public studyId: string) {
  }
}

export class GetSelfStudiesAction {
  static readonly type = '[Study] [GET] [Get Self Studies]';
}

export class GetStudiesWithApplication {
  static readonly type = '[Study] [GET] [Get Studies With Application]'

  constructor(
    public applicationId: string,
    ){}
}

export class GetOrganizationStudiesWithApplication {
  static readonly type = '[Study] [GET] [Get Organization Studies With Application]'

  constructor(
    public applicationId: string,
    public organizationId: string,
    ){}
}
