import { TrainingPasswordVerificationDTO } from '../../dtos/training-password-verification.dto';


export class GetSelfTrainingHistoryAction {
  static readonly type = '[Training History] [GET] [Self Training History]';

  constructor(
    public organizationId: string,
    public studyId: string,
  ) { }
}

export class VerifyTrainingPasswordAction {
  static readonly type = '[Training History] [POST] [Verify Password]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public verificationData: TrainingPasswordVerificationDTO
  ) { }
}

export class SignTrainingAction {
  static readonly type = '[Training History] [POST] [Sign Training]';

  constructor(
    public organizationId: string,
    public studyId: string
  ) {}
}

export class GetTrainingVideoAction {
  static readonly type = '[Training History] [GET] [Training Video]';

  constructor(
    public organizationId: string,
    public studyId: string
  ) {}
}