import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import { ITask, IUserTask } from '../../interface/task.interface';
import { IUser, User } from '../../../platform/model/user.model';
import { HttpService } from '../../../platform/services/http.service';
import {
  CloseUserTaskAction,
  DeleteUserTaskAction,
  GetRequiredTasksAction,
  GetUserTaskDetailsAction,
  GetUserTasksAction,
  CreateUserTaskAction, AddUserTaskMessageAction, GetTasksByDiscussionItemAction, GetTasksByMeetingIdAction, GetTasksByDecisionAction, EditDueDateTaskAction,
  GetTaskMeetingUsersAction,
  UploadTaskDocumentAction,
  GetActionDocumentsAction,
  GetTaskDocumentsCountAction,
  GetTaskDocumentAction,
  GetTaskDocumentBase64Action,
  DownloadTaskDocumentAction,
  DeleteTaskDocumentAction,
  GetAuditTrailTaskDocumentAction,
  GetAuditTrailTaskDocumentCountAction,
  GetTaskPossibleAssigneeAction,
  ChangeTaskStatusAction,
  SendTaskReminderNotificationAction
} from './tasks.actions';
import { TASKS_STATE_TOKEN } from './tasks.token';
import { MeetingService } from '../../services/meeting.service';
import { catchError, tap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IStudyDocument } from '../../interface/document.interface';
import { TasksService } from '../../services/tasks.service';
import { DocumentBlobDTO } from '../../dtos/document-blob.dto';
import { PropagateUpdateDocument } from '../document/document.actions';

export class ITasksState {
    requiredTasks: ITask[];
    userTasks: IUserTask[];
    taskUsers: IUser[];
    userTaskDetails: IUserTask;
    discussionItemTasks: IUserTask[];
    meetingTasks: IUserTask[];
    decisionTasks: IUserTask[];
    taskDocuments: IStudyDocument[]
    taskDocumentsCount: number;
    taskDocument: IStudyDocument;
    taskDocumentBase64: any;
    taskDocumentBlob: any; 
    documentBlob: DocumentBlobDTO;
    taskDocumentHistory: any;
    taskDocumentHistoryCount: number;
}

@State({
    name: TASKS_STATE_TOKEN,
    defaults: TasksState.defaultState
})
@Injectable()
export class TasksState {
    static defaultState: ITasksState = {
      requiredTasks: [],
      userTasks: [],
      taskUsers: [],
      userTaskDetails: null,
      discussionItemTasks:[],
      meetingTasks:[],
      decisionTasks:[],
      taskDocuments: [],
      taskDocumentsCount: null,
      taskDocument: null,
      taskDocumentBase64: null,
      taskDocumentBlob: null,
      documentBlob: null,
      taskDocumentHistory: [],
      taskDocumentHistoryCount: null,
    };

    constructor(
        private readonly httpService: HttpService,
        private readonly meetingService: MeetingService,
        private readonly taskService: TasksService,
        private readonly store: Store
    ) { }

    @Selector()
    static getRequiredTasks(state: ITasksState) {
        return state.requiredTasks;
    }

    @Selector()
    static getTaskByDiscussionItem(state: ITasksState) {
        return state.discussionItemTasks;
    }

    @Selector()
    static getTaskByMeetingId(state: ITasksState) {
        return state.meetingTasks;
    }

    @Selector()
    static getUserTasks(state: ITasksState) {
        return state.userTasks;
    }

    @Selector()
    static getTaskUsers(state: ITasksState) {
      return state.taskUsers;
    }

    @Selector()
    static getUserTaskDetails(state: ITasksState) {
        return state.userTaskDetails;
    }

    @Selector()
    static getDecisionTasks(state: ITasksState){
      return state.decisionTasks
    }

    @Selector()
    static getTaskDocuments(state: ITasksState) {
      return state.taskDocuments;
    }

    @Selector()
    static getTaskDocumentsCount(state: ITasksState) {
      return state.taskDocumentsCount;
    }

    @Selector()
    static getTaskDocument(state: ITasksState) {
      return state.taskDocument;
    }

    @Selector()
    static getTaskDocumentBase64(state: ITasksState) {
        return state.taskDocumentBase64;
    }

    @Selector()
    static getTaskDocumentBlob(state: ITasksState) {
      return state.documentBlob;
    }

    
    @Selector()
    static getTaskDocumentHistory(state: ITasksState) {
        return state.taskDocumentHistory;
    }

    @Selector()
    static getTaskDocumentHistoryCount(state: ITasksState) {
        return state.taskDocumentHistoryCount;
    }

    @Action(GetRequiredTasksAction)
    public getRequiredTasksAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId }: GetRequiredTasksAction) {
      return this.httpService.get<ITask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/tasks/dashboard`, {
        error: () => patchState({ requiredTasks: [] }),
        success: requiredTasks => patchState({ requiredTasks })
      });
    }

    @Action(GetUserTasksAction)
    public getUserTasksAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, oDataQuery }: GetUserTasksAction) {
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/tasks/user${oDataQuery ? `?${oDataQuery}` : ''}`, {
        error: () => patchState({ userTasks: [] }),
        success: userTasks => patchState({ userTasks })
      });
    }

    @Action(GetUserTaskDetailsAction)
    public getUserTaskDetailsAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId }: GetUserTaskDetailsAction) {
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}`, {
        error: () => patchState({ userTaskDetails: null }),
        success: userTaskDetails => patchState({ userTaskDetails })
      });
    }

    @Action(GetTaskPossibleAssigneeAction)
    public getTaskPossibleAssigneeAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId }: GetTaskPossibleAssigneeAction) {
      return this.taskService.getTaskPossibleAssignees(organizationId, studyId).pipe(tap(taskUsers => patchState({ taskUsers })));
    }

    @Action(GetTaskMeetingUsersAction)
    public getTaskMeetingUsersAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, meetingId }: GetTaskMeetingUsersAction) {
      return this.meetingService.getMeetingAttendees(organizationId, studyId, meetingId).pipe(tap(meetingAttendees => {
        const temp: IUser[] = [];
        meetingAttendees.forEach(attendee => { 
          temp.push(new User({
            name: attendee.user,
            firstName: attendee.firstName,
            lastName: attendee.lastName,
            roleName: attendee.roleName
          }))
        });          
        
        patchState({ taskUsers: temp })
      }),
      // So that in case method return empty list, attendees list get emptied
      catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
              patchState({ taskUsers: [] });
          }

          return throwError(() => error);
      }));
    }

    @Action(CreateUserTaskAction)
    public createUserTaskAction({ getState, setState, patchState }: StateContext<ITasksState>, { organizationId, studyId, payload }: CreateUserTaskAction) {
      return this.httpService.post<IUserTask>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/add`, payload, {
        error: () => patchState({ userTaskDetails: null }),
        success: discussionItemTasks => {
          if (!discussionItemTasks.task.discussionItemId && !discussionItemTasks.task.decisionId) {
            // Action created but not linked to anything yet
            const state = getState();
            const userTasksList = [...state.userTasks];
            userTasksList.push(discussionItemTasks.task);
  
            setState({
              ...state,
              userTasks: userTasksList,
            });
          }
          else if (discussionItemTasks.task.discussionItemId && !discussionItemTasks.task.decisionId) {
            // It is an action linked to a discussion item
            const state = getState();
            const discussionItemList = [...state.discussionItemTasks];
            discussionItemList.push(discussionItemTasks.task);
  
            setState({
              ...state,
              discussionItemTasks: discussionItemList,
            });
          }
          else if (discussionItemTasks.task.discussionItemId && discussionItemTasks.task.decisionId) {
            // It is an action linked directly to a decision
            const state = getState();
            const decisionTasksList = [...state.decisionTasks];
            decisionTasksList.push(discussionItemTasks.task);
  
            setState({
              ...state,
              decisionTasks: decisionTasksList,
            });
          }
          
        }
      });
    }

    @Action(AddUserTaskMessageAction)
    public addUserTaskMessageAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, payload }: AddUserTaskMessageAction) {
      return this.httpService.post<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/addMessage/${payload.notify}`, payload, {
        error: () => {},
        success: () => {}
      });
    }

    @Action(DeleteUserTaskAction)
    public deleteUserTaskAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId }: DeleteUserTaskAction) {
      return this.httpService.post<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/remove`, {}, {
        error: () => {},
        success: () => {}
      });
    }

    @Action(GetTasksByDiscussionItemAction)
    public getTasksByDiscussionItemAction({ patchState, getState }: StateContext<ITasksState>, {organizationId, studyId, discussionItemId, username, allDecisions}: GetTasksByDiscussionItemAction){
      let filter = allDecisions ? '?$filter=DecisionId ne \'NULL\'' : '?$filter=DecisionId eq null';
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/tasks/${username}${filter}`, {
        error: () => {},
        success: discussionItemTasks => {
          if (allDecisions){
            patchState({decisionTasks: discussionItemTasks});
          }
          else {
            patchState({ discussionItemTasks });
          }
        }
      });
    }

    @Action(GetTasksByDecisionAction)
    public getTasksByDecisionAction({ patchState }: StateContext<ITasksState>, {organizationId, studyId, decisionId}: GetTasksByDecisionAction){
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/${decisionId}/tasks`, {
        error: () => {},
        success: decisionTasks => patchState({ decisionTasks })
      });
    }

    @Action(GetTasksByMeetingIdAction)
    public getTasksByMeetingIdAction({ patchState }: StateContext<ITasksState>, {organizationId, studyId, meetingId, user}: GetTasksByMeetingIdAction){
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/tasks/${user.name}`, {
        error: () => {},
        success: meetingTasks => {  
          meetingTasks.map(meetingTask => ({
            ...meetingTask
          }));        
          
          patchState({ meetingTasks })

        }
      });
    }

    @Action(CloseUserTaskAction)
    public closeUserTaskAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, payload }: CloseUserTaskAction) {
      return this.httpService.post<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/close`, payload, {
        error: () => {},
        success: () => {}
      });
    }

    @Action(EditDueDateTaskAction)
    public editDueDateAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, dueDate }: EditDueDateTaskAction) {
      return this.httpService.post<any>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/edit`, { dueDate: dueDate }, {
        error: () => patchState({ userTaskDetails: null }),
        success: userTaskDetails => patchState({ userTaskDetails })
      });
    }

    @Action(ChangeTaskStatusAction)
    public changeTaskStatusAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, payload }: ChangeTaskStatusAction) {
      return this.taskService.changeTaskStatus(organizationId, studyId, taskId, payload).pipe(tap(userTaskDetails => patchState({ userTaskDetails })));
    }

    @Action(SendTaskReminderNotificationAction) 
    public sendTaskReminderNotificationAction(_, {organizationId, studyId, taskId, payload }: SendTaskReminderNotificationAction) {
      return this.taskService.sendTaskReminderNotification(organizationId, studyId, taskId, payload);
    }

    @Action(UploadTaskDocumentAction)
    public uploadTaskDocument({ getState, setState }: StateContext<ITasksState>, { payload: { organizationId, studyDocument, studyId, documentId, versionNo } }: UploadTaskDocumentAction) {
      return this.taskService.uploadTaskDocument(organizationId, studyId, documentId, versionNo, studyDocument).pipe(tap(document => {
        const state = getState();
        let taskDocumentsList = [...state.taskDocuments];
        taskDocumentsList.push(document);
        const updatedCount = state.taskDocumentsCount + 1;

        setState({
          ...state,
          taskDocuments: taskDocumentsList,
          taskDocumentsCount: updatedCount,
        })
      }))
    }

    @Action(GetActionDocumentsAction)
    getActionDocuments({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId }: GetActionDocumentsAction) {
      return this.taskService.getTaskDocuments(organizationId, studyId, taskId).pipe(tap(taskDocuments => {
        patchState({ taskDocuments });
      }));
    }

    @Action(GetTaskDocumentsCountAction)
    getTaskDocumentsCount({patchState}: StateContext<ITasksState>, {organizationId, studyId, taskId }: GetTaskDocumentsCountAction) {
      return this.taskService.getTaskDocumentsCount(organizationId, studyId, taskId).pipe(tap(taskDocumentsCount => {
          patchState({taskDocumentsCount})
      }));
  }

  @Action(GetTaskDocumentAction)
  getTaskDocument({ patchState }: StateContext<ITasksState>, { organizationId, studyId, documentId, versionNo }: GetTaskDocumentAction){
    return this.taskService.getDocument(organizationId, studyId, documentId, versionNo)
        .pipe(tap(taskDocument => {
            patchState({ taskDocument })
        }));
  }

  @Action(GetTaskDocumentBase64Action)
  getTaskDocumentBase64({ patchState }: StateContext<ITasksState>, { organizationId, studyId, documentId, versionNo }: GetTaskDocumentBase64Action) {
      return this.taskService.getDocumentBase64(organizationId, studyId, documentId, versionNo)  
        .pipe(tap(taskDocumentBase64 => patchState({ taskDocumentBase64 })));
  }

  @Action(DownloadTaskDocumentAction)
  downloadTaskDocumentAction({ getState, setState}: StateContext<ITasksState>,  { payload: {organizationId, studyId, documentId, versionId} }: DownloadTaskDocumentAction){
      return this.taskService.downloadDocumentBlob(organizationId, studyId, documentId, versionId)
      .pipe(tap(blob => {
          const state = getState();
          const temp: DocumentBlobDTO = {
              fileId: documentId,
              blob: blob
          };

          setState({
              ...state,
              documentBlob: temp,
              taskDocumentBlob: temp,
          });
      }));
  }

  @Action(DeleteTaskDocumentAction)
  deleteTaskDocumentAction({ getState, setState }: StateContext<ITasksState>, { payload }: DeleteTaskDocumentAction) {
      return this.taskService.deleteTaskDocument(payload.organizationId, payload.studyId, payload.taskId, payload.documentId)
          .pipe(tap(_ => {
              // Remove the document from the dicscussionItemDocuments list if there is
              const state = getState();
              let documentList = [...state.taskDocuments];
              if (documentList.length > 0) {
                  documentList = documentList.filter(d => d.id !== payload.documentId);
              }

              // Decrease the counter
              const updatedCount = state.taskDocumentsCount -1;

              setState({
                  ...state,
                  taskDocuments: documentList,
                  taskDocumentsCount: updatedCount
              });

              this.store.dispatch(new PropagateUpdateDocument(payload.documentId));
          }))
  }

  @Action(GetAuditTrailTaskDocumentAction)
  getAuditTrailDecisionDocument({patchState}: StateContext<ITasksState>, { payload: { organizationId, studyId, documentId, startPage, itemsPerPage, sorting } }:GetAuditTrailTaskDocumentAction){
      return this.taskService.getAuditTrailTaskDocument(organizationId, studyId, documentId, startPage, itemsPerPage, sorting)  
        .pipe(tap(taskDocumentHistory => patchState({ taskDocumentHistory })));
  }

  @Action(GetAuditTrailTaskDocumentCountAction)
  getAuditTrailDecisionDocumentCount({patchState}: StateContext<ITasksState>, { payload: { organizationId, studyId, documentId } }:GetAuditTrailTaskDocumentCountAction){
      return this.taskService.getAuditTrailTaskDocumentCount(organizationId, studyId, documentId )  
        .pipe(tap(taskDocumentHistoryCount => patchState({ taskDocumentHistoryCount })));
  }
}