import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute, UrlTree, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { OperationState } from '../store/operation/operation.state';
import { Select } from '@ngxs/store';
import { LoggingService } from '../services/logging.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly zone: NgZone,
    protected readonly loggingService: LoggingService,
  ) { }

  @Select(OperationState.getPlatformRolesOperations) private readonly platformRolesOperations$: Observable<string[]>;

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!this.authService.userIsLogged()) {
      this.authService.setRedirectUrl(state.url);
      return of(this.router.parseUrl('/login'));
    }

    // Given the new implementation of the routing we needed to add this piece so to consider only
    // the last step of the route, otherwise the authGard would check at each section if the user 
    // was allowed to see page or not. This however, would create problem of permission denied since that
    // for reaching an allowed page, the user might path over a page in which does not have permission
    if (childRoute.children.length > 0) {
      return of(true);
    }

    const { operation, application } = childRoute.data;

    if (!operation) {
      return of(false);
    }

    return this.platformRolesOperations$.pipe(
      map(operations => operations.includes(operation)),
      tap(isAllowed => {
        if (isAllowed) {
          // TODO - Use Action PostLogAction
          this.loggingService.writeLog({
            operation,
            application,
            study: '',
            success: true
          });
        }
      }),
      map(isAllowed => {
        //console.log("🚀 ~ AuthGuard ~ canActivateChild ~ isAllowed:", isAllowed);
        if (isAllowed) {
          return true;
        } else {
          this.router.navigate(['/error/permission-denied'], {
            state: { error: { message: `"${operation}" operation is not allowed for this child route` } }
          });
          return false;
        }
      }),
      catchError(() => {
        this.router.navigate(['/error']);
        return of(false);
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.userIsLogged()) {
      return true;
    }

    this.authService.setRedirectUrl(state.url);
    return this.router.createUrlTree(['/login']);
  }
}
