<nav
  id="sidebarMenu"
  class="sidebar col-md-2 col-lg-1 d-md-block"
  [ngClass]="{'sidebar-small': sidebarOpen$ | async}"
>
    <div class="sidebar-sticky">
        <div class="d-flex align-items-start flex-column h-100 sidebar-inner">
            <div class="mb-auto w-100">
                <ul class="nav flex-column">
                    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: sidebarItems}">
                    </ng-container>
                </ul>
            </div>
            <div class="mt-2 border-top pt-2 border-light w-100">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/profile">
                            <i class="sidebar-icon small-icon bi-person"></i>
                            <span [ngClass]="{'hidden': sidebarOpen$ | async}">
                              {{'platform.buttons.profile' | translate}}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/new-helpdesk">
                            <i class="sidebar-icon small-icon bi-question-circle"></i>
                            <span [ngClass]="{'hidden': sidebarOpen$ | async}">
                              {{'platform.buttons.helpdesk' | translate}}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="logout()">
                            <i class="sidebar-icon small-icon bi-box-arrow-right"></i>
                            <span [ngClass]="{'hidden': sidebarOpen$ | async}">
                              {{'platform.buttons.logout' | translate}}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="d-flex justify-content-end w-100 border-top border-light">
                <a class="nav-link d-none d-sm-block collapse-icon" (click)="toggleSidebar()">
                    <i *ngIf="!(sidebarOpen$ | async)" class="chevrons-icon bi-chevron-double-left"></i>
                    <i *ngIf="sidebarOpen$ | async" class="chevrons-icon bi-chevron-double-right"></i>
                </a>
            </div>
        </div>
    </div>
</nav>

<ng-template #recursiveList let-items>
  <li *ngFor="let item of items;" class="nav-item light-level" [ngClass]="{'dark-level': item.type !== 'page'}">
    <div class="d-flex w-100 nowrap">
      <div
        (click)="open(item)"
        [ngClass]="{'active': router.url.includes(item.link+'/') || router.url.endsWith(item.link)}"
        class="nav-link"
      >
      <tr>
        <td>
          <i class="small-icon sidebar-icon" [ngClass]="item.icon"></i>
        </td>
        <td>
          <span [ngClass]="{'hidden': sidebarOpen$ | async}">
          {{ item | pageLabelPostfix | translate}}</span>
        </td>
        <td>
          <div *ngIf="(item.type == 'study' || item.type == 'organization') && item.numberActionRequired > 0 && ( !item.open || item.type == 'study')" class="circle">
            <div class="number">{{item.numberActionRequired}}</div>
          </div>
        </td>
        </tr>
      </div>

      <a
        (click)="toggleItem(item)"
        *ngIf="item.type !== 'page'"
        class="mt-2 flex-grow-1 text-right toggle-link"
        aria-expanded="false"
      >
        <i *ngIf="!item.open" class="small-icon bi-plus toggle-icon"></i>
        <i *ngIf="item.open" class="small-icon bi-dash toggle-icon"></i>
      </a>
    </div>

    <ul
      class="items ml-n4 light-level"
      *ngIf="!!item?.children.length && item.open"
      [ngClass]="{
        'dark-level': item.type !== 'page' && !!item.children[0].children.length,
        'hidden': sidebarOpen$ | async
      }"
    >
      <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item.children }">
      </ng-container>
    </ul>
  </li>
</ng-template>
