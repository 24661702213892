import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { ActionType, NGXS_PLUGINS, NgxsNextPluginFn, NgxsPlugin } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ReadonlyDeep } from 'type-fest';
import { NotificationsService } from 'src/app/platform/services/notifications.service';
import { ICoreState } from '../store.interface';

interface ActionNotifications {
  post: {
    notification: {
      success: string;
    }
  };
}

@Injectable()
export class NotificationsPlugin implements NgxsPlugin {
  constructor(
    private readonly notificationsService: NotificationsService
  ) { }

  public handle(
    state: ReadonlyDeep<ICoreState>,
    action: Readonly<ActionType & ActionNotifications>,
    next: NgxsNextPluginFn
  ): void {
    return next(state, action).pipe(
      tap(() => {
        if (!!action.post?.notification?.success) {
          this.notificationsService.notifySuccess(action.post.notification.success);
        }
      })
    );
  }
}

@NgModule()
export class NgxsNotificationsPluginModule {
  static forRoot(): ModuleWithProviders<NgxsNotificationsPluginModule> {
    return {
      ngModule: NgxsNotificationsPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: NotificationsPlugin,
          multi: true
        }
      ]
    };
  }
}
