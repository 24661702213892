import { Injectable, NgZone } from '@angular/core';
import { defer, NEVER, Subject } from 'rxjs';
import { finalize, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading = new Subject<boolean>();

    constructor(private readonly zone: NgZone) {
    }

    // tslint:disable-next-line: max-line-length
    // https://medium.com/show-loader-during-http-request-handeling/angular-display-loader-on-every-http-request-handeling-concurrency-with-rxjs-6b2515abe52a
    public readonly loader$ = defer(() => {
        this.show();
        return NEVER.pipe(
            finalize(() => {
              this.zone.run(() =>  this.hide());
            })
        );
    }).pipe(share());

    show() {
        this.isLoading.next(true);
    }
    hide() {
        this.isLoading.next(false);
    }
}
