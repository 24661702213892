import { MeetingId } from '../../../platform/utils/types';

export class GetRecordingsAction {
    static readonly type = '[Recordings] [GET] [Get Meeting Recordings]';

    constructor(
      public organizationId:string, 
      public studyId: string, 
      public meetingId: MeetingId,  
      public startPage?: number, 
      public itemsPerPage?: number) {

    }
}

export class GetRecordingsCountAction {
    static readonly type = '[Recordings] [GET] [Get Meeting Recordings Count]';

    constructor(
      public organizationId: string, 
      public studyId: string, 
      public meetingId: MeetingId) {

    }
}

export class DeleteRecordingAction {
    static readonly type = '[Recording] [Delete] [Delete Recording]';

    constructor(
      public organizationId:string,
      public studyId: string, 
      public meetingId: MeetingId, 
      public recordingId: string) {

    }
}

export class DownloadRecordingBlobAction {
    static readonly type = '[Recording] [GET] [Download Recording Blob]';

    constructor(
      public organizationId:string,
      public studyId: string, 
      public meetingId: MeetingId, 
      public recordingId: string) {

    }
}

export class UploadRecordingAction {
    static readonly type = '[Recording] [POST] [Upload Recording]';

    public readonly post = {
      notification: {
        success: 'dsmb.upload-document.toastr-message'
      }
    };

    constructor(
      public organizationId: string,
      public studyId: string,
      public meetingId: MeetingId,
      public payload: FormData
    ) { }
}

export class ViewRecordingAction {
    static readonly type = '[Recording] [GET] [View Recording]';

    constructor(
      public organizationId:string,
      public studyId: string, 
      public meetingId: MeetingId, 
      public recordingId: string) {

    }
}

export class GetRecordingAction {
  static readonly type = '[Recording] [GET] [Get Recording Data]';

  constructor(
    public organizationId:string,
    public studyId: string, 
    public meetingId: MeetingId, 
    public recordingId: string) {

  }
}