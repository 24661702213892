import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MeetingId } from '../utils/types';

export interface IRoutesParams {
  username?: string;
  studyId?: string;
  meetingId?: MeetingId;
  documentId?: string;
  versionNo?: string;
  organizationId?: string;
  taskId?: string;
  discussionItemId?: string;
  decisionId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  public getParams(route: ActivatedRouteSnapshot, params: IRoutesParams = {}): IRoutesParams {
    if (route.params.studyId) params.studyId = route.params.studyId;
    if (route.params.documentId) params.documentId = route.params.documentId;
    if (route.params.versionNo) params.versionNo = route.params.versionNo;
    if (route.params.username) params.username = route.params.username;
    if (route.params.meetingId) params.meetingId = MeetingId(route.params.meetingId);
    if (route.params.organizationId) params.organizationId = route.params.organizationId;
    if (route.params.taskId) params.taskId = route.params.taskId;
    if (route.params.discussionItemId) params.discussionItemId = route.params.discussionItemId;
    if (route.params.decisionId) params.decisionId = route.params.decisionId;

    if (route.parent) {
      return this.getParams(route.parent, params);
    } else {
      return params;
    }
  }
}
