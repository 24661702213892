import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    private readonly loaderService: LoaderService
  ) { }

  // tslint:disable-next-line: max-line-length
  // https://medium.com/show-loader-during-http-request-handeling/angular-display-loader-on-every-http-request-handeling-concurrency-with-rxjs-6b2515abe52a
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/platform\/log$/.test(req.url)) {
      const loaderSubscription: Subscription = this.loaderService.loader$.subscribe();

      return next.handle(req).pipe(finalize(() => loaderSubscription.unsubscribe()));
    } else {
      return next.handle(req);
    }
  }
}
