import { IVersionDTO } from '../../dtos/version.dto';
import { GetDsmbBackendVersion, GetPlatformBackendVersion } from './version.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { VERSION_STATE_TOKEN } from './version.token';
import { Injectable } from '@angular/core';
import { VersionService } from '../../services/version.service';
import { deepClone } from '../../utils/global-vars';

export interface IBackendVersion
{
  platformVersion: IVersionDTO;
  dsmbVersion: IVersionDTO;
}

@State({
    name: VERSION_STATE_TOKEN,
    defaults: VersionState.defaultState,
  })
@Injectable()
export class VersionState {
  static defaultState: IBackendVersion = 
    { 
      platformVersion :{
        build: 0, 
        major: 0,
        minor: 0,
        revision: 0,
        fullVersion: ""
      },
      dsmbVersion :{
        build: 0, 
        major: 0,
        minor: 0,
        revision: 0,
        fullVersion: ""
      }
    }
  
  constructor(private readonly versionService: VersionService) {}

  @Selector()
  static getPlatformBackendVersion(state: IBackendVersion) {
      return state.platformVersion;
  }

  @Selector()
  static getDsmbBackendVersion(state: IBackendVersion) {
      return state.dsmbVersion;
  }

  @Action(GetPlatformBackendVersion)
  getPlatformBackendVersion({ patchState, getState } : StateContext<IBackendVersion>) {
    let state = getState();

    if (state.platformVersion === VersionState.defaultState.platformVersion)
    {
      return this.versionService.getPlatformBackendVersion().pipe(tap(version => {
          patchState({platformVersion :{
            ...version
          }});
      }));
    }
  }

  @Action(GetDsmbBackendVersion)
  getDsmbBackendVersion({ patchState, getState }: StateContext<IBackendVersion>) {
    
    let state = getState();

    if (state.dsmbVersion === VersionState.defaultState.dsmbVersion)
    {
        return this.versionService.getDsmbBackendVersion().pipe(tap(version => {
          patchState({dsmbVersion :{
            ...version
          }});
      }));
    }
  }
};

