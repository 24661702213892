import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelpdeskAnonymousDTO } from '../dtos/helpdesk-anonymous.dto';
import { HelpdeskCategoryDTO } from '../dtos/helpdesk-category.dto';
import { HelpdeskDTO } from '../dtos/helpdesk.dto';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {
  constructor(
    private readonly httpClient: HttpClient
  ) { }

  addHelpdesk(helpdeskRequestDTO: HelpdeskDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/helpdesk`, helpdeskRequestDTO);
  }

  addHelpdeskAnonymous(helpdeskAnonymousDTO: HelpdeskAnonymousDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/helpdesk-anonymous`, helpdeskAnonymousDTO);
  }

  getHelpdeskCategories(): Observable<HelpdeskCategoryDTO[]> {
    return this.httpClient.get<HelpdeskCategoryDTO[]>(`${environment.platformHost}/platform/helpdesk-categories`);
  }
}
