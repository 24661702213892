import { HelpdeskAnonymousDTO } from '../../dtos/helpdesk-anonymous.dto';
import { HelpdeskDTO } from '../../dtos/helpdesk.dto';

export class GetHelpdeskCategoriesAction {
  static readonly type = '[Helpdesk] [GET] [Categories]';
}

export class SendHelpdeskFormAction {
  static readonly type = '[Helpdesk] [SEND]';

  constructor(public helpdeskRequest: HelpdeskDTO) {
  }
}

export class SendAnonymousHelpdeskFormAction {
  static readonly type = '[Helpdesk] [SEND] [Anonymous]';

  constructor(public helpdeskRequest: HelpdeskAnonymousDTO) {
  }
}
