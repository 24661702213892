import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOrganization } from '../interfaces/organization.interface';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private readonly httpClient: HttpClient) { }

  getOrganizations(): Observable<IOrganization[]> {
    return this.httpClient.get<IOrganization[]>(`${environment.platformHost}/platform/organizations`);
  }
  getSelfOrganizations(): Observable<IOrganization[]> {
    return this.httpClient.get<IOrganization[]>(`${environment.platformHost}/platform/self-organizations`);
  }
  addOrganization(organizationDTO: IOrganization): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/add-organization`, organizationDTO);
  }
  activateOrganization(organizationId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/activate`, organizationId);
  }
  deactivateOrganization(organizationId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/deactivate`, organizationId);
  }
  getOrgsWithApplication(applicationId: string):Observable<IOrganization[]> {
    return this.httpClient.get<IOrganization[]>(`${environment.platformHost}/platform/application/${applicationId}/get-organizations`);
  }
}
