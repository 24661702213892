import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { MeetingService } from 'src/app/dsmb/services/meeting.service';
import { ApplicationDTO, ApplicationsEnum } from '../../../platform/dtos/application.dto';
import { RoleDTO } from '../../../platform/dtos/role.dto';
import { ApplicationService } from '../../../platform/services/application.service';
import { deepClone } from '../../utils/global-vars';
import {
  AddApplicationToStudyAction,
  GetApplicationsAction,
  GetOrganizationApplicationsAction,
  GetApplicationRolesAction,
  GetSelfStudyApplicationsAction,
  GetStudyApplicationsAction,
  RemoveApplicationFromStudyAction
} from './application.actions';
import { APPLICATION_STATE_TOKEN } from './application.token';

type IApplicationStateStudies = Record<'studies' | 'selfStudies', ApplicationDTO[]>;

function ApplicationStateStudiesDTO() {
  return {
    studies: [],
    selfStudies: []
  };
}

export interface IApplicationState {
  applications: ApplicationDTO[];
  organizations: {
    [id: string]: ApplicationDTO[]
  };
  studies: {
    [id: string]: IApplicationStateStudies
  };
  roles: {
    [id: string]: RoleDTO[]
  };
}

@State({
  name: APPLICATION_STATE_TOKEN,
  defaults: ApplicationState.defaultState
})
@Injectable()
export class ApplicationState {
  static defaultState: IApplicationState = {
    applications: [],
    organizations: {},
    studies: {},
    roles: {}
  };

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly meetingService: MeetingService
  ) {
  }

  @Selector()
  static getApplications(state: IApplicationState) {
    return state.applications;
  }

  @Selector()
  static getOrganizationApplications(state: IApplicationState) {
    return (organizationId: string) => state.organizations[organizationId];
  }

  @Selector()
  static getStudyApplications(state: IApplicationState) {
    return (studyID: string) => state.studies[studyID]?.studies;
  }

  @Selector()
  static getSelfStudyApplications(state: IApplicationState) {
    return (studyID: string) => state.studies[studyID]?.selfStudies;
  }

  @Selector()
  static getApplicationRoles(state: IApplicationState) {
    return (applicationId: string) => state.roles[applicationId];
  }

  @Action(GetApplicationsAction)
  getApplications({ patchState }: StateContext<IApplicationState>) {
    return this.applicationService.getAllApplications().pipe(tap(applications => {
      const platformApplication = applications.find(x => x.id === ApplicationsEnum.PLATFORM);

      if (platformApplication) {
        platformApplication.name = 'Platform';
      }

      patchState({ applications });
    }));
  }

  @Action(GetOrganizationApplicationsAction)
  getOrganizationApplications({ getState, setState }: StateContext<IApplicationState>, {
    organizationID,
    queryString
  }: GetOrganizationApplicationsAction) {
    return this.applicationService.getApplicationsByOrganizationId(organizationID, queryString).pipe(tap(applications => {
      const state = deepClone<IApplicationState>(getState());
      state.organizations[organizationID] = applications;

      setState(state);
    }));
  }

  @Action(GetStudyApplicationsAction)
  getStudyApplications({ getState, setState }: StateContext<IApplicationState>, { organizationId, studyId }: GetStudyApplicationsAction) {
    return this.applicationService.getApplicationsByStudyId(organizationId, studyId).pipe(tap(applications => {
        const state = deepClone<IApplicationState>(getState());

        state.studies[studyId] = state.studies[studyId] || ApplicationStateStudiesDTO();
        state.studies[studyId].studies = applications;

        setState(state);
    })
      , catchError(error => {
        if (error.status === 404) {
          const state = deepClone<IApplicationState>(getState());

          state.studies[studyId] = state.studies[studyId] || ApplicationStateStudiesDTO();
          state.studies[studyId].studies = [];

          setState(state);

          return of(state.studies[studyId].studies);
        }
        else {
          return throwError(() => error);
        }
      }));
  }

  @Action(GetSelfStudyApplicationsAction)
  getSelfStudyApplicationsAction({ getState, setState }: StateContext<IApplicationState>, { organizationId, studyId }: GetSelfStudyApplicationsAction) {
    return this.applicationService.getSelfApplicationsByStudyId(organizationId, studyId).pipe(tap(applications => {
      const state = deepClone<IApplicationState>(getState());

      state.studies[studyId] = state.studies[studyId] || ApplicationStateStudiesDTO();
      state.studies[studyId].selfStudies = applications;

      setState(state);
    }));
  }

  @Action(GetApplicationRolesAction)
  getApplicationRoles({ getState, setState }: StateContext<IApplicationState>, { applicationID }: GetApplicationRolesAction) {
    return this.applicationService.getApplicationRoles(applicationID).pipe(tap(roles => {
      const state = deepClone<IApplicationState>(getState());

      state.roles[applicationID] = roles;

      setState(state);
    }));
  }

  @Action(AddApplicationToStudyAction)
  addApplicationToStudy({ setState }: StateContext<IApplicationState>, { organizationId, studyId, applicationId }: AddApplicationToStudyAction) {
    return this.applicationService.addApplicationToStudy(organizationId, studyId, applicationId).pipe(
      switchMap(_ => this.meetingService.addDefaultMeetingTemplateConfigurations(organizationId, studyId))
    );
  }

  @Action(RemoveApplicationFromStudyAction)
  removeApplicationFromStudy({ setState }: StateContext<IApplicationState>, { organizationId, studyId, applicationId }: RemoveApplicationFromStudyAction) {
    return this.applicationService.deleteApplicationFromStudy(organizationId, studyId, applicationId).pipe(
      switchMap(_ => this.meetingService.removeDefaultMeetingTemplateConfigurations(organizationId, studyId))
    );
  }
}
