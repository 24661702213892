import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExportConfigDTO } from '../dtos/export-config.dto';
import { LogResponseDTO } from '../dtos/log-response.dto';
import { LogDTO } from '../dtos/log.dto';
import { ApplicationParameterService } from './application-parameter.service';
import { AuthService } from './auth.service';
import * as moment from 'moment-timezone';
import { UserService } from './user.service';
import { StringEmpty } from '../utils/global-vars';
import { isEqual } from 'lodash';
import { Select } from '@ngxs/store';
import { OperationState } from '../store/operation/operation.state';
import { ApplicationParameterDTO } from '../dtos/application-parameter.dto';
@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private timezone: string;
  private dateformat: string;

  @Select(OperationState.getApplicationParameters) public readonly applicationParameters$: Observable<ApplicationParameterDTO[]>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly applicationParameterService: ApplicationParameterService,
    private readonly userService: UserService
  ) { }

  public writeLog(body: LogDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/log`, body).pipe(
      catchError((error) => {
        console.error(error);
        return of();
      })
    );
  }

  // Method to get list of logs from the BE, an OData filter and sorting can be applied,
  // If no sorting is given a default is used
  public getLogs(applicationId: string, startPage: number, itemsPerPage: number, sorting: string, filter: string): Observable<LogResponseDTO[]> {
    if (filter) {
      if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
        sorting = '&$orderby=Timestamp desc';
      }
      else if (sorting.toLowerCase() === 'timestamp asc') {
        sorting = '&$orderby=Timestamp asc';
      }
      else {
        sorting = `&$orderby=${sorting}, Timestamp desc`;
      }

      const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;

      return this.httpClient.get<LogResponseDTO[]>(`${environment.platformHost}/platform/logs${odataQueryString}`);
    } else {
      return combineLatest([
        this.userService.getCurrentUser(),
        this.applicationParameters$
      ]).pipe(
        switchMap(([loggedUser, params]) => {
          this.timezone = loggedUser?.timezone;

          if (this.timezone) {
            this.dateformat = params.find(p => p.id === 'datetime-format').value;
            this.dateformat = this.dateformat.replace('dd', 'DD')
              .replace('yyyy', 'YYYY');
            const endDate = moment().tz(this.timezone)?.startOf('day').add(1, 'days')?.toISOString();
            const startDate = moment().tz(this.timezone)?.startOf('day').subtract(7, 'days')?.toISOString();

            filter = '$filter=Timestamp ge ' + startDate + ' and Timestamp le ' + endDate;
          }

          if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
            sorting = '&$orderby=Timestamp desc';
          } else if (sorting.toLowerCase() === 'timestamp asc') {
            sorting = '&$orderby=Timestamp asc';
          } else {
            sorting = `&$orderby=${sorting}, Timestamp desc`;
          }

          const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;

          return this.httpClient.get<LogResponseDTO[]>(`${environment.platformHost}/platform/logs${odataQueryString}`);
        })
      );
    }
  }

  // Method to get list of logs relative to an organization, an OData filter and sorting can be applied,
  // If no sorting is given a default is used
  public getLogsByOrganizationId(
    applicationId: string,
    organizationId: string,
    startPage: number,
    itemsPerPage: number,
    sorting: string,
    filter: string
  ): Observable<LogResponseDTO[]> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    } else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    } else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }

    const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;

    return this.httpClient.get<LogResponseDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/logs${odataQueryString}`);
  }

  // Method to get list of logs relative to an organization, an OData filter and sorting can be applied,
  // If no sorting is given a default is used
  public getLogsByStudyIdAndApplicationId(
    organizationId: string,
    studyId: string,
    applicationId: string,
    startPage: number,
    itemsPerPage: number,
    sorting: string,
    filter: string
  ): Observable<LogResponseDTO[]> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    } else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    } else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }

    const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;
    return this.httpClient.get<LogResponseDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/application/${applicationId}/study/${studyId}/logs${odataQueryString}`);
  }

  // Methods to get the number of logs from the BE, an OData filter can be applied
  public getLogsCount(applicationId: string, filter: string): Observable<number> {
    if (filter) {
      const odataQueryStringNotEmpy = `?${filter}&$count=true`;

      return this.httpClient.get<number>(`${environment.platformHost}/platform/logs${odataQueryStringNotEmpy}`);
    } else {
      return combineLatest([
        this.userService.getCurrentUser(),
        this.applicationParameters$
      ]).pipe(
        switchMap(([loggedUser, params]) => {
          this.timezone = loggedUser?.timezone;

          if (this.timezone) {
            this.dateformat = params.find(p => p.id === 'datetime-format').value;
            this.dateformat = this.dateformat.replace('dd', 'DD')
              .replace('yyyy', 'YYYY');
            const endDate = moment().tz(this.timezone)?.startOf('day').add(1, 'days')?.toISOString();
            const startDate = moment().tz(this.timezone)?.startOf('day').subtract(7, 'days')?.toISOString();

            filter = '$filter=Timestamp ge ' + startDate + ' and Timestamp le ' + endDate  +  ' and Application eq \'platform\'';
          }

          const odataQueryStringNotEmpty = `?${filter}&$count=true`;

          return this.httpClient.get<number>(`${environment.platformHost}/platform/logs${odataQueryStringNotEmpty}`);
        })
      );
    }

  }

  // Method to get number of logs relative to an organization, an OData filter can be applied
  public getLogsCountByOrganizationId(applicationId: string, organizationId: string, filter: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.platformHost}/platform/organization/${organizationId}/logs${odataQueryString}`);
  }

  // Method to get number of logs relative to an organization, an OData filter can be applied
  public getLogsCountByStudyIdAndApplicationId(organizationId: string, studyId: string, applicationId: string, filter: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/application/${applicationId}/study/${studyId}/logs${odataQueryString}`);
  }

  public exportLogs(applicationId: string, sorting: string, filter: string, config: ExportConfigDTO): Observable<HttpResponse<any>> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    } else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    } else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }

    const odataQueryString = `?${filter}${sorting}`;

    return this.httpClient.post(`${environment.platformHost}/platform/logs/export${odataQueryString}`,
      config, { responseType: 'blob', observe: 'response' });
  }

  public exportOrganizationLogs(
    organizationId: string,
    applicationId: string,
    sorting: string,
    filter: string,
    config: ExportConfigDTO
  ): Observable<HttpResponse<any>> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    } else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    } else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }

    const odataQueryString = `?${filter}${sorting}`;

    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/logs/export${odataQueryString}`,
      config, { responseType: 'blob', observe: 'response' });
  }

  public exportStudyApplicationLogs(
    organizationId: string, 
    studyId: string,
    applicationId: string,
    sorting: string,
    filter: string,
    config: ExportConfigDTO
  ): Observable<HttpResponse<any>> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    } else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    } else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }

    const odataQueryString = `?${filter}${sorting}`;

    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/application/${applicationId}/study/${studyId}/logs/export${odataQueryString}`,
      config, { responseType: 'blob', observe: 'response' });
  }

  public downlaodEmailAsMsg(application: string, emailTitle: string, emailBody: string)  {
    var host = (application === 'platform') ? environment.platformHost : environment.dsmbHost ;
    return this.httpClient.post(`${host}/${application}/download-email-msg`,
    {emailTitle: emailTitle, emailBody: emailBody}, { responseType: 'blob' });
  }

  public generateDetailsHtml(oldValue: string, newValue: string):string {
    let innerStruct = StringEmpty;
    oldValue = oldValue !== null ? oldValue.replace(/null,/g, `"",`).replace(/null /g, `"" `): oldValue;
    newValue = newValue !== null ? newValue.replace(/null,/g, `"",`).replace(/null /g, `"" `): newValue;
    const object1 = JSON.parse(oldValue);
    const object2 = JSON.parse(newValue);

    // there is only the old value
    if (oldValue !== null && newValue === null){
      object1.forEach(el =>{
        innerStruct = innerStruct + `<div class="card cardLogDetails w-auto"><div class="card-body"><table><tr><th></th><th>Old value</th><th></th><th></th></tr>`;
        for (let key in el) {
          if (el.hasOwnProperty(key)) {
            if (Array.isArray(el[key])){
              innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td></td><td>`;
              el[key].forEach(subel => {
                innerStruct = innerStruct + `<div class="card cardLogDetails"><div class="card-body"><table class="full-width-table">`;
                for (let subkey in subel){
                  innerStruct = innerStruct + `<tr><td>${subkey}:</td><td>${subel[subkey]}</td></tr>`
                }
                innerStruct = innerStruct + `</table></div></div>`;
              })
              innerStruct = innerStruct + `</td></tr>`
            }
            if (typeof el[key] === 'object') {
              innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td></td><td>`;
              innerStruct = innerStruct + `<div class="card cardLogDetails"><div class="card-body"><table class="full-width-table">`;
              let innerElement = el[key];
              for (let innerKey in innerElement) {
                innerStruct = innerStruct + `<tr><td>${innerKey}:</td><td>${innerElement[innerKey]}</td></tr>`
              }
              innerStruct = innerStruct + `</table></div></div>`;
            } else {
              innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td></td><td>${el[key]}</td></tr>`
            }
          }
        }
        innerStruct = innerStruct + `</table></div></div>`;
      })
    }
    else if (newValue !== null && oldValue === null) { // there is only the new value
      object2.forEach(el =>{
        innerStruct = innerStruct + `<div class="card cardLogDetails w-auto"><div class="card-body"><table><tr><th></th><th></th><th></th><th>New value</th></tr>`;
        for (let key in el) {
          if (el.hasOwnProperty(key)) {
            if (Array.isArray(el[key])){
              innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td></td><td>`;
              el[key].forEach(subel => {
                innerStruct = innerStruct + `<div class="card cardLogDetails"><div class="card-body"><table class="full-width-table">`;
                for (let subkey in subel){
                  innerStruct = innerStruct + `<tr><td>${subkey}:</td><td>${subel[subkey]}</td></tr>`
                }
                innerStruct = innerStruct + `</table></div></div>`;
              })
              innerStruct = innerStruct + `</td></tr>`
            }    
            else { 
              if (typeof el[key] === 'object') {
                innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td></td><td>`;
                innerStruct = innerStruct + `<div class="card cardLogDetails"><div class="card-body"><table class="full-width-table">`;
                let innerElement = el[key];
                for (let innerKey in innerElement) {
                  innerStruct = innerStruct + `<tr><td>${innerKey}:</td><td>${innerElement[innerKey]}</td></tr>`
                }
                innerStruct = innerStruct + `</table></div></div>`;
            } else {
              innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td></td><td>${el[key]}</td></tr>`
            }
          }
        }
        }
        innerStruct = innerStruct + `</table></div></div>`;
      })
    }
    else {

      if (object1.length > 1 || object2.length > 1){
        const commonObject = object1.filter(obj1 => object2.some(obj2 => isEqual(obj2, obj1)));
        const objectInFirstNotInSecond = object1.filter(obj1 => !object2.some(obj2 => isEqual(obj2, obj1)));
        const objectInSecondNotInFirst = object2.filter(obj2 => !object1.some(obj1 => isEqual(obj2, obj1)));

        commonObject.forEach((el, index) =>{
          innerStruct = innerStruct + `<div class="card cardLogDetails w-auto"><div class="card-body"><table class="full-width-table"><tr><th></th><th>Old value</th><th></th><th>New value</th></tr>`;
          for (let key in el) {
            if (el.hasOwnProperty(key)) {
              if (!Array.isArray(el[key])){       
                  innerStruct = innerStruct + `<tr><td>${key}:</td><td>${el[key]}</td><td>=</td><td>${object2[index][key]}</td></tr>`
              }
            }
          }
          innerStruct = innerStruct + `</table></div></div>`;
        })

        objectInFirstNotInSecond.forEach((el, index) =>{
          innerStruct = innerStruct + `<div class="card cardLogDetails w-auto"><div class="card-body"><table class="full-width-table"><tr><th></th><th>Old value</th><th></th><th>New value</th></tr>`;
          for (let key in el) {
            if (el.hasOwnProperty(key)) {
              if (!Array.isArray(el[key])){       
                  innerStruct = innerStruct + `<tr><td>${key}:</td><td>${el[key]}</td><td>&rarr;</td><td></td></tr>`
              }
            }
          }
          innerStruct = innerStruct + `</table></div></div>`;
        })

        objectInSecondNotInFirst.forEach((el, index) =>{
          innerStruct = innerStruct + `<div class="card cardLogDetails w-auto"><div class="card-body"><table class="full-width-table"><tr><th></th><th>Old value</th><th></th><th>New value</th></tr>`;
          for (let key in el) {
            if (el.hasOwnProperty(key)) {
              if (!Array.isArray(el[key])){       
                  innerStruct = innerStruct + `<tr><td>${key}:</td><td></td><td>&rarr;</td><td>${el[key]}</td></tr>`
              }
            }
          }
          innerStruct = innerStruct + `</table></div></div>`;
        })

      }
      else{
        object1.forEach((el, index) =>{
          innerStruct = innerStruct + `<div class="card cardLogDetails w-auto"><div class="card-body"><table class="full-width-table"><tr><th class="col-w-5"></th><th>Old value</th><th class="col-w-5"></th><th>New value</th></tr>`;
          for (let key in el) {
            if (el.hasOwnProperty(key)) {
              if (!Array.isArray(el[key])){    
                if (!this.isObject(el[key])){
                  if (el[key] !== object2[index][key]) {
                    innerStruct = innerStruct + `<tr><td><b>${key}:</b></td><td><b>${el[key]}</b></td><td><b>&rarr;</b></td><td><b>${object2[index][key]}</b></td></tr>`
                  }
                  else{
                    innerStruct = innerStruct + `<tr><td>${key}:</td><td>${el[key]}</td><td>=</td><td>${object2[index][key]}</td></tr>`
                  }
                }
              }
            }
          }
          innerStruct = innerStruct + `</table></div></div>`;
        })
      }
    }
    
    return innerStruct;
  }

  private isObject(variable: any): boolean {
    return typeof variable === 'object' && variable !== null;
  }
  
}
