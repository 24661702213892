<nav class="bg-primary sticky-top">
    <div class="d-flex flex-grow-1 justify-content-between">
        <div
          class="logo-container p-1"
          [ngClass]="{'sidebar-small': sidebarOpen$ | async}"
        >
            <div class="fluid-container">
                <div class="row no-gutters flex-nowrap">
                    <div class="col-md" style="max-width: 50px;">
                        <div class="d-flex align-items-start">
                            <a routerLink="/">
                              <img src="assets/images/logo-square.png" class="bg-white rounded logo-small mr-4 img-fluid" alt="" />
                            </a>
                        </div>
                    </div>

                    <div class="col-md" [ngClass]="{'hidden': sidebarOpen$ | async}">
                        <div class="flex-grow-1">
                            <a routerLink="/">
                              <span class="text-white platform-logo d-none d-sm-block">eDSMB®</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showCollapseButton" class="d-flex d-block d-sm-none d-md-none">
                <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu"
                    aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
        <div class="align-self-center text-white user">
            <span *ngIf="loggedUser?.name">
              {{loggedUser.firstName}} {{loggedUser.lastName}} ({{loggedUser.name}} - {{loggedUser.timezone}})
            </span>
        </div>
    </div>
</nav>
