import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeetingId } from '../../platform/utils/types';
import { MeetingRecordingDTO } from '../dtos/meeting-recording.dto';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingRecordingsService {

  constructor(private http: HttpClient) { }

  getRecordings(organizationId:string, studyId: string, meetingId: MeetingId, startPage?: number, itemsPerPage?: number): Observable<MeetingRecordingDTO[]> {
    let odataQueryString = `?$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;

    return this.http.get<MeetingRecordingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recordings${odataQueryString}`);
  }

  getRecordingsCount(organizationId:string, studyId: string, meetingId: MeetingId): Observable<number> {
    const odataQueryString =  `?$count=true`;
    return this.http.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recordings${odataQueryString}`);
  }

  deleteRecording(organizationId:string, studyId: string, meetingId: MeetingId, recordingId: string) {
    return this.http.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recording/${recordingId}`);
  }

  downloadRecordingBlob(organizationId:string, studyId: string, meetingId: MeetingId, recordingId: string) {
    return this.http.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recording/${recordingId}`, {
      responseType: 'blob'
    });
  }

  viewRecording(organizationId:string, studyId: string, meetingId: MeetingId, recordingId: string): Observable<string>{
    return this.http.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recording/${recordingId}/view`, {
      responseType: 'text'})
  }

  getRecording(organizationId:string, studyId: string, meetingId: MeetingId, recordingId: string): Observable<any> {
    return this.http.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/recording/${recordingId}/info`)
  }
}
