import { IUser } from "src/app/platform/model/user.model";
import { IUserTaskAssignee } from "../../interface/task.interface";

export class GetRequiredTasksAction {
    static readonly type = '[Tasks] [GET] [Required]';

    constructor(
      public organizationId: string,
      public studyId: string
    ) { }
}

export class GetUserTasksAction {
    static readonly type = '[Tasks] [GET]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public oDataQuery?: string
    ) { }
}

export class GetUserTaskDetailsAction {
    static readonly type = '[Tasks] [GET] [Details]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string
    ) { }
}

export class GetTaskPossibleAssigneeAction {
    static readonly type = '[Tasks] [GET] [User]';

    constructor(
      public organizationId: string,
      public studyId: string,
    ) { }
}

export class GetTaskMeetingUsersAction {
  static readonly type = '[Tasks] [GET] [Meeting Users]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public meetingId: string
  ) {}
}

export class CreateUserTaskAction {
    static readonly type = '[Tasks] [POST] [User]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public payload: {
        title: string,
        description: string,
        discussionItemId: string,
        decisionId: string,
        meetingId: string,
        assignees: string,
        expirationDate: string
      },
      
    ) { }
}

export class AddUserTaskMessageAction {
  static readonly type = '[Tasks] [POST] [Message]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public taskId: string,
    public payload: {
      message: string,
      notify: boolean
    }
  ) { }
}

export class DeleteUserTaskAction {
    static readonly type = '[Tasks] [POST] [Delete]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string
    ) { }
}

export class CloseUserTaskAction {
    static readonly type = '[Tasks] [POST] [Close]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string,
      public payload: {
        message?: string
      }
    ) { }
}

export class GetTasksByDiscussionItemAction {
  static readonly type = '[Tasks] [GET] [Get Tasks By Discussion Item]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public discussionItemId: string,
    public username: string,
    public allDecisions: boolean,
  ) { }
}


export class GetTasksByDecisionAction {
  static readonly type = '[Tasks] [GET] [Get Tasks By Decision]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public decisionId: string,
  ) { }
}

export class   GetTasksByMeetingIdAction
 {
    static readonly type = '[Tasks] [GET] [Get Tasks By Meeting Id]';
  
    constructor(
      public organizationId: string,
      public studyId: string,
      public meetingId: string,
      public user: IUser,
    ) { }
}

export class   GetTasksCountByDiscussionItemIdAction
  {
    static readonly type = '[Tasks] [GET] [Get Tasks Count By Discussion Item Id]';
  
    constructor(
      public organizationId: string,
      public studyId: string,
      public discussionItemId: string,
      public username: string,
    ) { }
}

export class EditDueDateTaskAction{
  static readonly type = '[Tasks] [GET] [Edit Due Date]';
  
    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string,
      public dueDate: string,
    ) { }
}

export class ChangeTaskStatusAction {
  static readonly type = '[Task] [Post] [Change Status]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public taskId: string,
    public payload: {
      newStatus: string
      message?: string
    }
  ) { }
}

export class SendTaskReminderNotificationAction {
  static readonly type = '[Task] [Post] [Send Reminder Notification]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public taskId: string,
    public payload: {
      assignees: IUserTaskAssignee[]
    }
  ) { }
}

export class UploadTaskDocumentAction {
  static readonly type = '[Action Document] [POST] [Upload Document]';
    
  public readonly post = {
      notification: {
          success: 'dsmb.upload-document.toastr-message'
      }
  };
    
  constructor(
      public payload: {
          studyDocument: FormData,
          organizationId:string,
          studyId: string,
          meetingId: string,
          discussionItemId: string,
          decisionId: string,
          taskId: string,
          documentId: string,
          versionNo: number,
      }
  ) { }
}

export class GetActionDocumentsAction {
  static readonly type = '[Action Document] [Get] [Task Documents]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public taskId: string,
  ){}
}

export class GetTaskDocumentsCountAction {
  static readonly type = '[Task Document] [Get] [Task Documents Count]';

  constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string,
  ) {}
}

export class GetTaskDocumentAction {
  static readonly type = '[Task Document] [Get] [Task Document]';
  constructor(
      public organizationId: string,
      public studyId: string,
      public documentId: string, 
      public versionNo: string, 
  ) {}
}

export class GetTaskDocumentBase64Action {
  static readonly type = '[Task Document] [Get] [Task Document Base 64]';
  constructor(
      public organizationId: string,
      public studyId: string,
      public documentId: string, 
      public versionNo: string, 
  ) {}
}

export class DownloadTaskDocumentAction {
  static readonly type = '[Task Document] [Get] [Download Task Document]';

  constructor(
      public payload: any,
  ){}
}

export class DeleteTaskDocumentAction {
  static readonly type = '[Task Document] [Delete] [Delete Task Document]';

  public readonly post = {
    notification: {
      success: 'dsmb.delete-document.toastr-message'
    }
  };

  constructor(
    public payload: any
  ) {}
}

export class GetAuditTrailTaskDocumentAction {
  static readonly type = '[Task Document] [GET] [Get Task Document Audit Trail]';

  constructor(
    public payload: {
      organizationId:string,
      studyId: string,
      documentId: string,
      isStudyDocument: boolean,
      startPage?: number,
      itemsPerPage?: number,
      sorting?: string
    }
  ) { }
}

export class GetAuditTrailTaskDocumentCountAction {
  static readonly type = '[Task Document] [Get] [Get Count Task Document Audit Trail]';

  constructor(
    public payload: {
      organizationId: string,
      studyId: string,
      documentId: string,
      isStudyDocument: boolean
    }
  ) { }
}