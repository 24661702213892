<app-header [showCollapseButton]="true"></app-header>

<app-sidebar></app-sidebar>

<main class="pb-5" [ngClass]="{'sidebar-small': sidebarOpen$ | async}">
  <app-breadcrumbs *ngIf="(sidebar$ | async).length"></app-breadcrumbs>

  <router-outlet></router-outlet>
</main>

<app-footer [marginClass]="'margin-default'"></app-footer>
