import { IOrganization } from '../../interfaces/organization.interface';

export class GetOrganizationsAction {
  static readonly type = '[Organization] [GET] [Organizations]';
}

export class GetSelfOrganizationsAction {
  static readonly type = '[Organization] [GET] [Self Organizations]';
}

export class CreateNewOrganizationAction {
  static readonly type = '[Organization] [POST] [Create Organization]';

  constructor(public organization: IOrganization) {
  }
}

export class ActivateOrganizationAction {
  static readonly type = '[Organization] [POST] [Activate Organization]';

  constructor(public organizationId: string) {
  }
}

export class DeactivateOrganizationAction {
  static readonly type = '[Organization] [POST] [Deactivate Organization]';

  constructor(public organizationId: string) {
  }
}

export class GetOrganizationWithApplicationAction{
  static readonly type = '[Organization] [POST] [Get Organization With Application]'

  constructor(public applicationId: string) {
  }
}
