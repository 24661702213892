//@ts-nocheck

import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { ReportingTimeframeEnum } from '../../../dsmb/pages/reporting/reporting.component';

export class GetReportingMeetingsCountAction {
  static readonly type = '[Reporting] [GET] [Meetings Count]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingMeetingsStatusAction {
  static readonly type = '[Reporting] [GET] [Meetings Status]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum,
  ) { }
}

export class GetReportingMeetings4OrganizationCountAction {
  static readonly type = '[Reporting] [GET] [Meetings Count For Organization]';

  constructor(
    public organizationId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingMeetings4OrganizationStatusAction {
  static readonly type = '[Reporting] [GET] [Meetings Status For Organization]';

  constructor(
    public organizationId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingDocsCountAction {
  static readonly type = '[Reporting] [GET] [Documents Count]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingDocsToBeSignedAction {
  static readonly type = '[Reporting] [GET] [Documents to be signed]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingPollsCountAction {
  static readonly type = '[Reporting] [GET] [Polls]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingPollsToBeAction {
  static readonly type = '[Reporting] [GET] [Polls completed, to be closed]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingTasksCountAction {
  static readonly type = '[Reporting] [GET] [Tasks]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}

export class GetReportingTasksToBeAction {
  static readonly type = '[Reporting] [GET] [Tasks completed, to be closed]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public filter: ReportingTimeframeEnum
  ) { }
}
