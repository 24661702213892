<nav aria-label="breadcrumb">
    <ol class="breadcrumb breadcrumb-eth">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last;">
            <li *ngIf="last; else elseBlock" class="breadcrumb-item active" aria-current="page">
              {{breadcrumb.label | translate}}
            </li>

            <ng-template #elseBlock>
                <li class="breadcrumb-item">
                  <a [routerLink]="breadcrumb.url">
                    {{breadcrumb.label | translate}}
                  </a>
                </li>
            </ng-template>
        </ng-container>
    </ol>
</nav>
