import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Select } from '@ngxs/store';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationParameterDTO } from '../../dtos/application-parameter.dto';
import { CookieService } from '../../services/cookie.service';
import { OperationState } from '../../store/operation/operation.state';

@Pipe({
  name: 'coreDate'
})
export class CoreDatePipe implements PipeTransform {
  @Select(OperationState.getApplicationParameters) applicationParameters$: Observable<ApplicationParameterDTO[]>;

  constructor(
    private readonly cookieService: CookieService,
    private readonly datePipe: DatePipe
  ) { }

  transform(
    value: string | Date
  ): Observable<string> {
    return this.applicationParameters$.pipe(
      map(params => {
        if (!params) {
          return;
        }

        const timezoneOffset = moment(value).tz(this.cookieService.getCookie(environment.timezoneCookieName)).format('Z');
        const format = params.find(x => x.id === 'date-format').value;
        const locale = this.cookieService.getCookie(environment.languageCookieName);
        return this.datePipe.transform(value, format, timezoneOffset, locale);
      })
    );
  }
}
