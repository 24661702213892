import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';
import { HttpService } from '../../../platform/services/http.service';
import { DecisionDTO } from '../../dtos/decision.dto';
import { DecisionsService } from '../../services/decisions.service';
import { AddDecisionAction,DeleteDecisionAction, DeleteDecisionDocumentAction, DownloadDecisionDocumentAction, EditDecisionDetailsAction, GetAuditTrailDecisionDocumentAction, GetAuditTrailDecisionDocumentCountAction, GetDecisionDetailsAction, GetDecisionDocumentAction, GetDecisionDocumentBase64Action, GetDecisionDocumentsAction, GetDecisionDocumentsByDiscussionItemAction, GetDecisionDocumentsCountAction, GetDecisionsAction, GetDecisionsByDiscussionItemAction, GetDecisionsByMeetingAction, GetDecisionsCountAction, GetDecisionsCountByDiscussionItemAction, GetDecisionsCountByMeetingAction, UploadDecisionDocumentAction } from './decisions.actions';
import { DECISIONS_STATE_TOKEN } from './decisions.token';
import { IStudyDocument } from 'src/app/dsmb/interface/document.interface';
import { DocumentBlobDTO } from '../../dtos/document-blob.dto';
import { PropagateUpdateDocument } from '../document/document.actions';

export class IDecisionsState {
    decisions: DecisionDTO[];
    decisionsCount: number;
    meetingDecisions: DecisionDTO[];
    meetingDecisionsCount: number;
    discussionItemDecisions: DecisionDTO[];
    discussionItemDecisionsCount: number;
    decisionDetails: DecisionDTO;
    decisionDocuments: IStudyDocument[];
    decisionDocumentsCount: number;
    decisionDocument: IStudyDocument;
    decisionDocumentBase64: any;
    decisionDocumentBlob: any; 
    decisionDocumentHistory: any;
    decisionDocumentHistoryCount: number;
    documentBlob: DocumentBlobDTO;
    decisionDocumentForDiscussionItem: IStudyDocument[];
}

@State({
    name: DECISIONS_STATE_TOKEN,
    defaults: DecisionsState.defaultState
})
@Injectable()
export class DecisionsState {
    static defaultState: IDecisionsState = {
        decisions: [],
        decisionsCount: null,
        meetingDecisions: [],
        meetingDecisionsCount: null,
        discussionItemDecisions: [],
        discussionItemDecisionsCount: null,
        decisionDetails: null,
        decisionDocuments:[],
        decisionDocumentsCount:null,
        decisionDocument: null,
        decisionDocumentBase64:null,
        decisionDocumentBlob: null,
        decisionDocumentHistory:[],
        decisionDocumentHistoryCount: null,
        documentBlob: null,
        decisionDocumentForDiscussionItem: [],
    };

    constructor(
        private readonly store: Store,
        private readonly httpService: HttpService,
        private readonly decisionsService: DecisionsService
    ) { }

    @Selector()
    static getDecisions(state: IDecisionsState) {
        return state.decisions;
    }

    @Selector()
    static getDecisionDetails(state: IDecisionsState) {
        return state.decisionDetails;
    }

    @Selector()
    static getDecisionsCount(state: IDecisionsState) {
        return state.decisionsCount;
    }

    @Selector()
    static getMeetingDecisions(state: IDecisionsState) {
        return state.meetingDecisions;
    }

    @Selector()
    static getMeetingDecisionsCount(state: IDecisionsState) {
        return state.meetingDecisionsCount;
    }

    @Selector()
    static getDiscussionItemDecisions(state: IDecisionsState) {
        return state.discussionItemDecisions;
    }

    @Selector()
    static getDiscussionItemDecisionsCount(state: IDecisionsState) {
        return state.discussionItemDecisionsCount;
    }

    @Selector()
    static getDecisionDocuments(state: IDecisionsState) {
        return state.decisionDocuments;
    }

    @Selector()
    static getDecisionDocumentsCount(state: IDecisionsState) {
        return state.decisionDocumentsCount;
    }

    @Selector()
    static getDecisionDocument(state: IDecisionsState) {
        return state.decisionDocument;
    }

    @Selector()
    static getDecisionDocumentBase64(state: IDecisionsState) {
        return state.decisionDocumentBase64;
    }

    @Selector()
    static getDecisionDocumentBlob(state: IDecisionsState) {
        return state.decisionDocumentBlob;
    }

    @Selector()
    static getDecisionDocumentHistory(state: IDecisionsState) {
        return state.decisionDocumentHistory;
    }

    @Selector()
    static getDecisionDocumentHistoryCount(state: IDecisionsState) {
        return state.decisionDocumentHistoryCount;
    }

    @Selector()
    static getDecisionDocumentForDiscussionItem(state: IDecisionsState) {
        return state.decisionDocumentForDiscussionItem;
    }

    @Action(GetDecisionsAction)
    getDecisions({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, filter, startPage, itemsPerPage, sorting }: GetDecisionsAction) {
        return this.decisionsService.getDecisions(organizationId, studyId, filter, startPage, itemsPerPage, sorting).pipe(tap(decisions => {
            patchState({ decisions });
        }),
            catchError((...args) => {
                if (args[0].status === 404) {
                    patchState({ decisions: [] });
                }
                return throwError(() => args[0]);
            })
        );
    }

    @Action(GetDecisionDetailsAction)
    getDecisionDetail({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, decisionId }: GetDecisionDetailsAction) {
        return this.decisionsService.getDecisionDetails(organizationId, studyId, decisionId).pipe(tap(decisionDetails => {
            patchState({ decisionDetails });
        }));
    }

    @Action(GetDecisionsCountAction)
    getDiscussionItemsCount({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, filter }: GetDecisionsCountAction) {
        return this.decisionsService.getDecisionsCount(organizationId, studyId, filter)
            .pipe(tap(decisionsCount => patchState({ decisionsCount })));

    }

    @Action(GetDecisionsByMeetingAction)
    getDecisionsByMeeting({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, meetingId, filter, startPage, itemsPerPage, sorting }: GetDecisionsByMeetingAction) {
        return this.decisionsService.getDecisionsByMeeting(organizationId, studyId, meetingId, filter, startPage, itemsPerPage, sorting).pipe(tap(meetingDecisions => {
            patchState({ meetingDecisions });
        }));
    }

    @Action(GetDecisionsCountByMeetingAction)
    getDecisionsCountByMeeting({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, meetingId, filter }: GetDecisionsCountByMeetingAction) {
        return this.decisionsService.getDecisionsCountByMeeting(organizationId, studyId, meetingId, filter)
            .pipe(tap(meetingDecisionsCount => patchState({ meetingDecisionsCount })));

    }

    @Action(GetDecisionsByDiscussionItemAction)
    getDecisionsByDiscussionItem({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, discussionItemId, filter, startPage, itemsPerPage, sorting }: GetDecisionsByDiscussionItemAction) {
        return this.decisionsService.getDecisionsByDiscussionItemId(organizationId, studyId, discussionItemId, filter, startPage, itemsPerPage, sorting).pipe(tap(discussionItemDecisions => {
            patchState({ discussionItemDecisions });
        }));
    }

    @Action(GetDecisionsCountByDiscussionItemAction)
    getDecisionsCountByDiscussionItem({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, discussionItemId, filter }: GetDecisionsCountByDiscussionItemAction) {
        return this.decisionsService.getDecisionsCountByDiscussionItem(organizationId, studyId, discussionItemId, filter)
            .pipe(tap(discussionItemDecisionsCount => patchState({ discussionItemDecisionsCount })));

    }

    @Action(AddDecisionAction)
    addDecision({ getState, setState }: StateContext<IDecisionsState>, { organizationId, studyId, newDecision }: AddDecisionAction) {
        return this.decisionsService.addDecision(organizationId, studyId, newDecision).pipe(tap(decision => {
            const state = getState();
            const decisionsList = [...state.decisions];
            decisionsList.push(decision);

            setState({
                ...state,
                decisions: decisionsList,
            });


            const discItemDec = [...state.discussionItemDecisions];
            discItemDec.push(decision);

            setState({
                ...state,
                discussionItemDecisions: discItemDec,
            });

        }));
    }

    @Action(EditDecisionDetailsAction)
    editDecision({ patchState, getState, setState }: StateContext<IDecisionsState>, {organizationId, studyId, updatedDecision}: EditDecisionDetailsAction){
        return this.decisionsService.editDecisionDetails(organizationId, studyId, updatedDecision).pipe(tap(decisionDetails => {
           patchState({ decisionDetails })
            const state = getState();
            let decisionsList = [...state.discussionItemDecisions];
            decisionsList = decisionsList.filter(x => x.id !== decisionDetails.id);
            decisionsList.push(decisionDetails);

            setState({
                ...state,
                discussionItemDecisions: decisionsList,
            });
        }));
    }

    @Action(DeleteDecisionAction)
    deleteDecisionAction({getState, setState}: StateContext<IDecisionsState>, { organizationId, studyId, decisionId, comment }: DeleteDecisionAction){
        return this.decisionsService.deleteDecision(organizationId, studyId, decisionId, comment).pipe(tap(_ =>{
            // Remove deleted discussion item from list
            const state = getState();
            let decisionList = [...state.discussionItemDecisions];
            decisionList = decisionList.filter(d => d.id !== decisionId);

            // Decrease the decision count
            const updatedCount = state.decisionsCount - 1;
            setState({
                ...state,
                discussionItemDecisions: decisionList,
                decisionsCount: updatedCount,
            });
        })) 
    }

    @Action(UploadDecisionDocumentAction)
    uploadDecisionDocument({ getState, setState }: StateContext<IDecisionsState>, { payload: { organizationId, studyDocument, studyId, documentId, versionNo, notify } }:UploadDecisionDocumentAction)
    {
        return this.decisionsService.uploadDecisionDocument(organizationId, studyId, documentId, versionNo, notify, studyDocument).pipe(tap(document => {
            const state = getState();
            let decisionDocumentsList = [... state.decisionDocuments];
            decisionDocumentsList.push(document);
            const updatedCount = state.decisionDocumentsCount + 1;

            setState({
                ...state,
                decisionDocuments: decisionDocumentsList,
                decisionDocumentsCount: updatedCount,
            })
        }));
    }

    @Action(GetDecisionDocumentsAction)
    getDiscussionItemDocuments({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, meetingId, decisionId }: GetDecisionDocumentsAction) {
        return this.decisionsService.getDecisionDocuments(organizationId, studyId, meetingId, decisionId).pipe(tap(decisionDocuments => {
            patchState({decisionDocuments})
        }));
    }

    @Action(GetDecisionDocumentsByDiscussionItemAction)
    getDecisionDocumentsByDiscussionItem({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, meetingId, discussionItemId }: GetDecisionDocumentsByDiscussionItemAction) {
        return this.decisionsService.getDecisionDocumentsByDiscussionItemId(organizationId, studyId, meetingId, discussionItemId).pipe(tap(decisionDocumentForDiscussionItem => {
            patchState({decisionDocumentForDiscussionItem})
        }));
    }


    @Action(GetDecisionDocumentsCountAction)
    getDiscussionItemDocumentsCount({patchState}: StateContext<IDecisionsState>, {organizationId, studyId, meetingId, decisionId }: GetDecisionDocumentsCountAction) {
        return this.decisionsService.getDecisionDocumentsCount(organizationId, studyId, meetingId, decisionId).pipe(tap(decisionDocumentsCount => {
            patchState({decisionDocumentsCount})
        }));
    }

    @Action(GetDecisionDocumentAction)
    getDecisionDocument({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, documentId, versionNo }: GetDecisionDocumentAction){
        return this.decisionsService.getDocument(organizationId, studyId, documentId, versionNo)
            .pipe(tap(decisionDocument => {
                patchState({ decisionDocument })
            }));
    }

    @Action(GetAuditTrailDecisionDocumentAction)
    getAuditTrailDecisionDocument({patchState}: StateContext<IDecisionsState>, { payload: { organizationId, studyId, documentId, startPage, itemsPerPage, sorting } }:GetAuditTrailDecisionDocumentAction){
        return this.decisionsService.getAuditTrailDecisionDocument(organizationId, studyId, documentId, startPage, itemsPerPage, sorting)  
          .pipe(tap(decisionDocumentHistory => patchState({ decisionDocumentHistory })));
    }

    @Action(GetAuditTrailDecisionDocumentCountAction)
    getAuditTrailDecisionDocumentCount({patchState}: StateContext<IDecisionsState>, { payload: { organizationId, studyId, documentId } }:GetAuditTrailDecisionDocumentCountAction){
        return this.decisionsService.getAuditTrailDecisionDocumentCount(organizationId, studyId, documentId )  
          .pipe(tap(decisionDocumentHistoryCount => patchState({ decisionDocumentHistoryCount })));
    }

    @Action(GetDecisionDocumentBase64Action)
    getDecisionDocumentBase64({ patchState }: StateContext<IDecisionsState>, { organizationId, studyId, documentId, versionNo }: GetDecisionDocumentBase64Action) {
        return this.decisionsService.getDocumentBase64(organizationId, studyId, documentId, versionNo)  
          .pipe(tap(decisionDocumentBase64 => patchState({ decisionDocumentBase64 })));
      }
    

    @Action(DeleteDecisionDocumentAction)
    deleteDecisionDocumentAction({ getState, setState }: StateContext<IDecisionsState>, { payload }: DeleteDecisionDocumentAction) {
        return this.decisionsService.deleteDecisionDocument(payload.organizationId, payload.studyId, payload.meetingId, payload.discussionItemId, payload.decisionId, payload.documentId)
            .pipe(tap(_ => {
                // Remove the document from the dicscussionItemDocuments list if there is
                const state = getState();
                let documentList = [...state.decisionDocuments];
                let decisionDocumentForDiscussionItem = [...state.decisionDocumentForDiscussionItem];
                if (documentList.length > 0) {
                    documentList = documentList.filter(d => d.id !== payload.documentId);
                }
                if (decisionDocumentForDiscussionItem.length > 0) {
                    decisionDocumentForDiscussionItem = decisionDocumentForDiscussionItem.filter(d => d.id !== payload.documentId);
                }

                // Decrease the counter
                const updatedCount = state.decisionDocumentsCount -1;

                setState({
                    ...state,
                    decisionDocuments: documentList,
                    decisionDocumentForDiscussionItem: decisionDocumentForDiscussionItem,
                    decisionDocumentsCount: updatedCount
                });

                this.store.dispatch(new PropagateUpdateDocument(payload.documentId));
            }))
    }

    @Action(DownloadDecisionDocumentAction)
    downloadDecisionDocumentAction({ getState, setState}: StateContext<IDecisionsState>,  { organizationId, studyId, documentId, versionNo }: DownloadDecisionDocumentAction){
        return this.decisionsService.downloadDocumentBlob(organizationId, studyId, documentId, versionNo)
        .pipe(tap(blob => {
            const state = getState();
            const temp: DocumentBlobDTO = {
                fileId: documentId,
                blob: blob
            };

            setState({
                ...state,
                documentBlob: temp,
                decisionDocumentBlob: temp,
            });
        }));
    }
}
