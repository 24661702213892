import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { IUserChatMessage } from '../../../dsmb/interface/user-message.interface';
import { ChatService } from '../../services/chat.service';
import { SaveUserChatMessageAction, GetLastChatMessagesAction, GetUserChatMessagesAction, GetUserChatMessageCountAction } from './chat.actions';
import { USER_CHAT_STATE_TOKEN } from './chat.token';

export class IUserChatMessageState {
    messages: IUserChatMessage[];
    lastMessages: IUserChatMessage[];
    messagesCount: number;
}

@State({
    name: USER_CHAT_STATE_TOKEN,
    defaults: UserMessageState.defaultState
})
@Injectable()
export class UserMessageState {
    static defaultState: IUserChatMessageState = {
        messages: [],
        lastMessages: [],
        messagesCount: undefined
    };

    constructor(
        private readonly store: Store,
        private readonly chatService: ChatService
    ) { }

    @Selector()
    static getUserChatMessages(state: IUserChatMessageState) {
        return state.messages;
    }

    @Selector()
    static getLastChatMessages(state: IUserChatMessageState) {
        return state.lastMessages;
    }

    @Selector()
    static getUserChatMessagesCount(state: IUserChatMessageState) {
        return state.messagesCount;
    }

    @Action(GetUserChatMessagesAction)
    getUserChatMessages({ patchState }: StateContext<IUserChatMessageState>, { userId, orgId, studyId }: GetUserChatMessagesAction) {
        return this.chatService.getUserChatMessages(userId, orgId, studyId).pipe(
          tap(messages => {
            const reversed = messages.sort((left, right) => new Date(left.timestamp).getTime() - new Date(right.timestamp).getTime());

            patchState({ messages: reversed });
          })
        );
    }

    @Action(SaveUserChatMessageAction)
    addChatMessage({ getState, setState }: StateContext<IUserChatMessageState>, { senderId, orgId, studyId, newMessage }: SaveUserChatMessageAction) {
        return this.chatService.addChatMessage(senderId, orgId, studyId, newMessage);
    }

    @Action(GetLastChatMessagesAction)
    getLastChatMessages({ patchState }: StateContext<IUserChatMessageState>, { senderId, orgId, studyId }: GetLastChatMessagesAction) {
        return this.chatService.getLastChatMessages(senderId, orgId, studyId).pipe(tap(lastMessages => patchState({ lastMessages })));
    }

    @Action(GetUserChatMessageCountAction)
    getUserChatMessagesCount({ patchState }: StateContext<IUserChatMessageState>, { userId, orgId, studyId }: GetUserChatMessageCountAction) {
        return this.chatService.getChatMessagesCount(userId, orgId, studyId).pipe(tap(messagesCount => {
            patchState({ messagesCount })}
            ));
    }
}
