import { CountersignVerificationData } from '../../dtos/countersign-verification-data.dto';
import { DocumentCountersignDTO } from '../../dtos/document-countersign.dto';

export class GetDocumentCountersignInfoAction {
    static readonly type = '[Document] [GET] [Get Document Countersign Info]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public documentId: string,
      public versionNo: number
    ) { }
}

export class VerifyCountersignPasswordAction {
    static readonly type = '[Document] [POST] [Verify Countersign Password]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public verificationData: CountersignVerificationData
    ) { }
}

export class CountersignDocumentAction {
    static readonly type = '[Document] [GET] [Countersign Document]';

    public readonly post = {
      notification: {
        success: 'dsmb.document-countersign.success-toastr'
      }
    };

    constructor(
      public organizationId: string,
      public studyId: string,
      public documentId: string,
      public versionNo: number,
      public documentCountersign: DocumentCountersignDTO
    ) { }
}
