import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUserChatMessage, IUserChatMessageDTO } from '../../dsmb/interface/user-message.interface';
import { StringEmpty } from '../utils/global-vars';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private readonly httpClient: HttpClient
  ) { }

  public addChatMessage(senderId: string, orgId: string, studyId: string, message: IUserChatMessageDTO): Observable<IUserChatMessage> {
    return this.httpClient.post<IUserChatMessage>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${senderId}/add`, message);
  }

  public getUserChatMessages(userId: string, orgId: string, studyId: string, query = StringEmpty): Observable<IUserChatMessage[]> {
    return this.httpClient.get<IUserChatMessage[]>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${userId}/messages${query}`);
  }

  public getLastChatMessages(senderId: string, orgId: string, studyId: string): Observable<IUserChatMessage[]> {
    return this.getUserChatMessages(senderId, orgId, studyId, '?$top=5');
  }

  public getChatMessagesCount(userId: string, orgId: string, studyId: string): Observable<number>{
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${userId}/messages/count`);
  }
}


