import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DocumentHistoryDTO } from '../../../dsmb/dtos/document-history.dto';
import { IDocumentState } from '../../../dsmb/store/document/document.state';
import { LoginDTO } from '../../dtos/login.dto';
import { IDocument, IStudyDocument } from '../../../dsmb/interface/document.interface';
import { loginsSchema } from '../../model/login-json-validator.model';
import { IUser, User, userSchema, usersSchema } from '../../model/user.model';
import { FilesService } from '../../services/files.service';
import { HttpService } from '../../services/http.service';
import { StudyService } from '../../services/study.service';
import { StringEmpty } from '../../utils/global-vars';
import {
  GetPanelUsersAction,
  GetPanelUserDetailsAction,
  GetPanelUsersCountAction,
  PanelUserRoleActivateAction,
  PanelUserRoleDeactivateAction,
  GetPanelUserLoginsCountAction,
  GetPanelUserLoginsAction,
  GetPanelDocumentsAction,
  GetPanelDocumentsCountAction,
  UploadPanelDocumentAction,
  DownloadPanelDocument,
  DeletePanelDocument,
  GetPanelDocumentAuditTrailAction,
  GetPanelDocumentAction, GetPanelDocumentBase64Action,
  GetPanelDocumentAuditTrailCountAction
} from './panel.actions';
import { PANEL_STATE_TOKEN } from './panel.token';
import { DocumentBlobDTO } from 'src/app/dsmb/dtos/document-blob.dto';

export interface IPanelState {
  pagedUsers: IUser[];
  usersCount: number;
  documents: IDocument[];
  documentsCount: number;
  document: IDocument;
  documentBlob: Blob;
  documentsBase64: any;
  user: IUser;
  panelUserLogins: any[];
  panelLoginsCount: number;
  documentHistory: DocumentHistoryDTO[];
  documentHistoryCount: number;
}

@State({
  name: PANEL_STATE_TOKEN,
  defaults: PanelState.defaultState
})
@Injectable()
export class PanelState {
  static defaultState: IPanelState = {
    pagedUsers: [],
    usersCount: -1,
    documents: [],
    documentsCount: 0,
    document: null,
    documentBlob: null,
    documentsBase64: null,
    user: new User(),
    panelUserLogins: [],
    panelLoginsCount: 0,
    documentHistory: [],
    documentHistoryCount: null
  };

  constructor(
    private readonly studyService: StudyService,
    private readonly httpService: HttpService,
    private readonly httpClient: HttpClient,
    private readonly filesService: FilesService
  ) { }

  @Selector()
  static getPagedUsers(state: IPanelState) {
    return state.pagedUsers;
  }

  @Selector()
  static getPagedUsersCount(state: IPanelState) {
    return state.usersCount;
  }

  @Selector()
  static getDocuments(state: IDocumentState) {
    return state.documents;
  }

  @Selector()
  static getDocumentBlob(state: IDocumentState) {
    return state.documentBlob;
  }

  @Selector()
  static getDocumentsCount(state: IDocumentState) {
    return state.documentsCount;
  }

  @Selector()
  static getDocument(state: IDocumentState) {
    return state.document;
  }

  @Selector()
  static getDocumentsBase64(state: IDocumentState) {
    return state.documentsBase64;
  }

  @Selector()
  static getUser(state: IPanelState) {
    return state.user;
  }

  @Selector()
  static getPanelUserLogins(state: IPanelState) {
    return state.panelUserLogins;
  }

  @Selector()
  static getPanelUserLoginsCount(state: IPanelState) {
    return state.panelLoginsCount;
  }

  @Selector()
  static getDocumentHistory(state: IDocumentState) {
    return state.documentHistory;
  }

  @Selector()
  static getDocumentHistoryCount(state: IDocumentState) {
    return state.documentHistoryCount;
  }

  @Action(GetPanelUsersAction)
  public getPanelUsersAction({ patchState }: StateContext<IPanelState>, { organizationId, studyId, startPage, itemsPerPage, sorting, filter }: GetPanelUsersAction) {
    // TODO Make service and use it instead of an hpptService, remove as well the catch error
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/users`, {
      odataQueryParams: builder => builder({ startPage, itemsPerPage, sorting, filter }, { orderProperty: 'Name' }).asPaged(),
      validateSchema: usersSchema,
      error: () => patchState({ pagedUsers: [] }),
      success: users => patchState({ pagedUsers: users.map(user => new User(user)) })
    });
  }

  @Action(GetPanelUsersCountAction)
  public getPanelUsersCountAction({ patchState }: StateContext<IPanelState>, { organizationId, studyId, filter }: GetPanelUsersCountAction) {
    return this.httpService.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/users`, {
      odataQueryParams: builder => builder({ filter }).asCount(),
      validateSchema: { type: 'number' },
      error: () => patchState({ usersCount: -1 }),
      success: usersCount => patchState({ usersCount })
    });
  }

  // TODO why here for dsmb, would not be better to have it in platform as get general information about user?
  @Action(GetPanelUserDetailsAction)
  public getPanelUserDetailsAction({ patchState }: StateContext<IPanelState>, { organizationId, studyId, username }: GetPanelUserDetailsAction) {
    // TODO Make service and do not call it like this, call the service
    return this.httpService.get<IUser>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${username}`, {
      validateSchema: userSchema,
      error: () => patchState({ user: new User() }), // TODO Delete the error section
      success: user => patchState({ user: new User(user) })
    });
  }

  @Action(PanelUserRoleActivateAction)
  public panelUserRoleActivateAction( _, { orgId, studyId, username }: PanelUserRoleActivateAction) {
    // TODO Make method in service and call it
    return this.httpService.post(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${username}/role/setActive?active=true`);
  }

  @Action(PanelUserRoleDeactivateAction)
  public panelUserRoleDeactivateAction( _, { orgId, studyId, username }: PanelUserRoleDeactivateAction) {
    // TODO Make method in service and call it
    return this.httpService.post(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${username}/role/setActive?active=false`);
  }

  // TODO why needed? would not be better to have it in platform and just call that? since are platform information??
  @Action(GetPanelUserLoginsAction)
  getPanelUserLoginsAction({ patchState }: StateContext<IPanelState>, { payload: { organizationId, studyId, username, pageData: { startPage, itemsPerPage, columnSort } } }: GetPanelUserLoginsAction) {
    // TODO: fix empty first call
    if (!startPage || !itemsPerPage) {
      return;
    }

    // TODO Call the service
    return this.httpService.get<LoginDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${username}/logins`, {
      odataQueryParams: builder => builder({ startPage: startPage, itemsPerPage, sorting: columnSort }, { orderProperty: 'Timestamp' }).asPaged(),
      validateSchema: loginsSchema,
      error: () => patchState({ panelUserLogins: [] }), // TODO Remove error section
      success: panelUserLogins => patchState({ panelUserLogins })
    });
  }

    // TODO why needed? would not be better to have it in platform and just call that? since are platform information??
  @Action(GetPanelUserLoginsCountAction)
  getPanelUserLoginsCountAction({ patchState }: StateContext<IPanelState>, { payload: { organizationId, studyId, username } }: GetPanelUserLoginsCountAction) {
    // TODO Call the service
    return this.httpService.get<LoginDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${username}/logins`, {
      odataQueryParams: builder => builder().asCount(),
      validateSchema: { type: 'number' },
      error: () => patchState({ panelLoginsCount: -1 }), // TODO Remove error section
      success: panelLoginsCount => patchState({ panelLoginsCount })
    });
  }

  @Action(GetPanelDocumentsAction)
  getPanelDocuments({ patchState }: StateContext<IDocumentState>, { params: { organizationId, studyId, username, pageData: { filter, startPage, itemsPerPage, sorting } } }: GetPanelDocumentsAction) {
    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      // TODO: leave only the last else block
      if (sorting.toLowerCase() === 'name asc') {
        sorting = '$orderby=Name asc';
      }
      else if (sorting.toLowerCase() === 'name desc') {
        sorting = '$orderby=Name desc';
      }
      else if (sorting.toLowerCase() === 'versionno asc') {
        sorting = '$orderby=VersionNo asc';
      }
      else if (sorting.toLowerCase() === 'versionno desc') {
        sorting = '$orderby=VersionNo desc';
      } else {
        sorting = `$orderby=${sorting}`;
      }

      params.push(sorting);
    }

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&') && !temp.startsWith('$'))
    {
      temp = '&' + temp;
    }

    odataQueryString += temp;

    return this.httpClient.get<IDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${username}/userDocuments?${odataQueryString}`)
      .pipe(
        this.mapDocuments(),
        tap(documents => patchState({ documents }))
      );
  }

  @Action(GetPanelDocumentsCountAction)
  getPanelDocumentsCount(_, { params: { organizationId, studyId, username, pageData: { filter } } }: GetPanelDocumentsCountAction) {
    // TODO How does this work that make the call and do not even patch/save the results??
    const odataQueryString = (filter) ? `${filter}&$count=true` : `&$count=true`;
    return this.httpClient.get<IDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/user/${username}/userDocuments?${odataQueryString}`)
  }

  @Action(GetPanelDocumentAction)
  getPanelDocument({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, documentId, versionNo }: GetPanelDocumentAction) {
    // TODO move in a service and call the service
    return this.httpClient.get<IDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/userDocument/${documentId}/version/${versionNo}`)
      .pipe(tap(document => patchState({ document })));
  }

  @Action(GetPanelDocumentBase64Action)
  getPanelDocumentBase64({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, documentId, versionNo }: GetPanelDocumentBase64Action) {
    // TODO move in a service and call the service
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/userDocument/${documentId}/file/${versionNo}?isBase64=true`, {
      responseType: 'text'
    })
      .pipe(tap(documentsBase64 => patchState({ documentsBase64 })));
  }

  @Action(UploadPanelDocumentAction)
  uploadStudyDocument(_, { payload: { organizationId, studyDocument, studyId, documentId, versionNo, notify } }: UploadPanelDocumentAction) {
    // TODO move in a service and call the service
    return this.httpClient.post<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/userDocument/${documentId}${versionNo > 0 ? `/file/${versionNo}/` : StringEmpty}notify/${notify}`, studyDocument);
  }

  private mapDocuments() {
    return map((documents: any[]) => {
      return documents.map(document => {
        if (!document.oldVersions) {
          console.error('document.oldVersions is empty');
        }

        return {
          ...(document.lastVersion || document),
          children: document.oldVersions || [],
          open: false
        };
      });
    });
  }

  @Action(DownloadPanelDocument)
  downloadStudyDocumentBlob({ getState, setState }: StateContext<IDocumentState>, { httpParams: { organizationId, studyId, documentId, versionNo }, params: { filename } }: DownloadPanelDocument) {
    // TODO move call in service and call the service
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/userDocument/${documentId}/file/${versionNo}?isBase64=false`, {
      responseType: 'blob'
    })
    .pipe(tap(blob => {
      const state = getState();
      const temp: DocumentBlobDTO = {
          fileId: documentId,
          blob: blob
      };

      setState({
          ...state,
          documentBlob: temp,
      });
  }));
  }

  @Action(DeletePanelDocument)
  deleteStudyDocument(_, { params: { organizationId, studyId, documentId } }: DeletePanelDocument) {
    // TODO move call in service and call the service
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/userDocument/${documentId}`);
  }

  @Action(GetPanelDocumentAuditTrailAction)
  getDocumentHistory({ patchState }: StateContext<IDocumentState>, { payload: { organizationId, studyId, documentId, isStudyDocument, startPage, itemsPerPage, sorting } }: GetPanelDocumentAuditTrailAction ) {
    // TODO move call in service and call the service
    const filter = `?$filter=Args1 eq '${documentId}' and Operation ne 'get-document-history' and Operation ne 'get-user-document' and Operation ne 'get-document-countersign-info'`;
    return this.httpClient.get<DocumentHistoryDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/history/${isStudyDocument}/${filter}`)
      .pipe(tap(documentHistory => patchState({ documentHistory })));
  }

  @Action(GetPanelDocumentAuditTrailCountAction)
  getDocumentHistoryCount({ patchState }: StateContext<IDocumentState>, { payload: { organizationId, studyId, documentId, isStudyDocument } }: GetPanelDocumentAuditTrailCountAction ) {
    // TODO move call in service and call the service
    const filter = `?$filter=Args1 eq '${documentId}' and Operation ne 'get-document-history' and Operation ne 'get-user-document' and Operation ne 'get-document-countersign-info'`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/document/${documentId}/history/${isStudyDocument}/${filter}`)
      .pipe(tap(documentHistoryCount => patchState({ documentHistoryCount })));
  }
}
