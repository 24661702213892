import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from '../services/cookie.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private readonly cookieService: CookieService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const language = this.cookieService.getCookie(environment.languageCookieName);

    if (language !== 'en') {
      return next.handle(request.clone({
        setParams: { language }
      }));
    }

    return next.handle(request);
  }
}
