<app-header [showCollapseButton]="false"></app-header>

<div class="container-fluid p-0">
    <div>
        <main role="main" class="ml-sm-auto px-3">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>

<app-footer [marginClass]="'no-margin'"></app-footer>
