import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { LoggingService } from '../services/logging.service';
import { StringEmpty } from '../utils/global-vars';

// temporary workaround until ngxs 4 published https://github.com/ngxs/store/issues/803
// export const silenceClientErrors = catchError(error => {
//   if (error.status < 500) {
//     error.message += ' (silence)';
//   }

//   return throwError(() => error);
// });

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector
  ) { }

  public async handleError(error: Error | HttpErrorResponse) {
    if (environment.debug && (error as HttpErrorResponse).status !== 404 && (error as HttpErrorResponse).status !== 304) {
      console.error(error);
    }

    // temporary workaround until ngxs 4 published https://github.com/ngxs/store/issues/803
    // if (error.message?.includes('(silence)')) {
    //   return;
    // }

    const router = this.injector.get(Router);
    
    if (error instanceof HttpErrorResponse) {
      if (error.status === 403) {
        
        const innerError = error.error?.error || error.error || error;

        if (innerError === 'PASSWORD_EXPIRED') {
          await router.navigate(['/login/change-password-expired']);
        } else {
          await router.navigate(['/error/permission-denied'], { state: { error: JSON.parse(JSON.stringify(error))}});
        }
      } else if (error.status === 401) {
        if (!/login|\/login\/twostepverification/.test(router.url)) {
          await router.navigate(['/login']);
        }
      } else if (error.status !== 409) {
        await this.redirectError(error);

        this.log(error);
      }
    } else {
      if (!environment.debug) {
        await this.redirectError(error);

        this.log(error);
      }
    }
  }

  private async redirectError(item) {
    const router = this.injector.get(Router);

    let error = item;

    if (!(error instanceof Error)) {
      error = JSON.parse(JSON.stringify(error));
    }

    await router.navigate(['/error/page-error'], { state: { error } });
  }

  private log(error) {
    if (error instanceof HttpErrorResponse) {
      const logger = this.injector.get(LoggingService);

      // TODO - Use Action
      logger.writeLog({
        operation: StringEmpty,
        application: StringEmpty,
        study: null,
        success: false,
        error: `Error Name: ${error.message} </br>Error Message: ${error.error}`
      }).subscribe();
    }
  }
}
