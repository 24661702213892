import { Pipe, PipeTransform } from '@angular/core';
import { SidebarItem } from '../../model/sidebar.model';

@Pipe({
  name: 'pageLabelPostfix'
})
export class PageLabelPostfixPipe implements PipeTransform {
  public transform(value: SidebarItem): string {
    if (value.type === 'page') {
      return value.label + '.label';
    }

    return value.label;
  }
}
