import Ajv from 'ajv';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface JsonValidatorSchema {

}

@Injectable({
  providedIn: 'root'
})
export class JsonValidatorService {
  ajv = new Ajv({ allErrors: environment.debug });

  public validate<T>(schema: JsonValidatorSchema, data: T) {
    const validator = this.ajv.compile(schema);
    const valid = validator(data);

    return {
      errors: validator.errors,
      valid
    }
  }
}
