import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IUser } from '../../model/user.model';
import { HttpService } from '../../services/http.service';
import {
  GetReportingDocsCountAction,
  GetReportingDocsToBeSignedAction,
  GetReportingMeetings4OrganizationCountAction,
  GetReportingMeetings4OrganizationStatusAction,
  GetReportingMeetingsCountAction,
  GetReportingMeetingsStatusAction,
  GetReportingPollsCountAction, GetReportingPollsToBeAction, GetReportingTasksCountAction, GetReportingTasksToBeAction
} from './reporting.actions';
import { REPORTING_STATE_TOKEN } from './reporting.token';

export interface IReportingXAxis {
  count: number;
  xAxis: string;
}

export interface IMeetingsCountDTO {
  avgTime: string;
  meetingData: IReportingXAxis[];
}

export interface IReportingState {
  meetingsCount: IMeetingsCountDTO;
  meetingsStatus: any[];
  docsCount: any[];
  docsToBeSigned: any[];
  pollsCount: any[];
  pollsStatus: any[];
  tasksCount: IMeetingsCountDTO;
  tasksStatus: any[];
  meetingsCount4Organization: IMeetingsCountDTO;
  meetingsStatus4Organization: any[];
}

@State({
  name: REPORTING_STATE_TOKEN,
  defaults: ReportingState.defaultState
})
@Injectable()
export class ReportingState {
  static defaultState: IReportingState = {
    meetingsCount: { avgTime: 'N/A', meetingData: [] },
    meetingsStatus: [],
    docsCount: [],
    docsToBeSigned: [],
    pollsCount: [],
    pollsStatus: [],
    tasksCount: { avgTime: 'N/A', meetingData: [] },
    tasksStatus: [],
    meetingsCount4Organization: { avgTime: 'N/A', meetingData: [] },
    meetingsStatus4Organization: [],
  };

  constructor(
    private readonly httpService: HttpService
  ) { }

  @Selector()
  static getMeetingsCount(state: IReportingState) {
    return state.meetingsCount;
  }

  @Selector()
  static getMeetingsStatus(state: IReportingState) {
    return state.meetingsStatus;
  }

  @Selector()
  static getMeetings4OrganizationCount(state: IReportingState) {
    return state.meetingsCount4Organization;
  }

  @Selector()
  static getMeetings4OrganizationStatus(state: IReportingState) {
    return state.meetingsStatus4Organization;
  }

  @Selector()
  static getDocsCount(state: IReportingState) {
    return state.docsCount;
  }

  @Selector()
  static getDocsTobeSigned(state: IReportingState) {
    return state.docsToBeSigned;
  }

  @Selector()
  static getPollsCount(state: IReportingState) {
    return state.pollsCount;
  }

  @Selector()
  static getPollsStatus(state: IReportingState) {
    return state.pollsStatus;
  }

  @Selector()
  static getTasksCount(state: IReportingState) {
    return state.tasksCount;
  }

  @Selector()
  static getTasksStatus(state: IReportingState) {
    return state.tasksStatus;
  }

  @Action(GetReportingMeetings4OrganizationCountAction)
  public getReportingMeetings4OrganizationCountAction({ patchState }: StateContext<IReportingState>, { organizationId, filter }: GetReportingMeetings4OrganizationCountAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/meeting-by-time-report/${filter}`, {
      error: () => patchState({ meetingsCount4Organization: { avgTime: 'N/A', meetingData: [] } }),  // TODO Remove catch of error
      success: meetingsCount4Organization => patchState({ meetingsCount4Organization })
    });
  }

  @Action(GetReportingMeetings4OrganizationStatusAction)
  public getReportingMeetings4OrganizationStatusAction({ patchState }: StateContext<IReportingState>, { organizationId, filter }: GetReportingMeetings4OrganizationCountAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/meeting-by-status-report/${filter}`, {
      error: () => patchState({ meetingsStatus4Organization: [] }), // TODO Remove catch of error
      success: meetingsStatus4Organization => patchState({ meetingsStatus4Organization })
    });
  }

  @Action(GetReportingMeetingsCountAction)
  public getReportingMeetingsCountAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingMeetingsCountAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting-by-time-report/${filter}`, {
      error: () => patchState({ meetingsCount: { avgTime: 'N/A', meetingData: [] } }), // TODO Remove catch of error
      success: meetingsCount => patchState({ meetingsCount })
    });
  }

  @Action(GetReportingMeetingsStatusAction)
  public getReportingMeetingsStatusAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingMeetingsStatusAction) {
    // TODO move it in service and call the serive, httpService still used?
      return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting-by-status-report/${filter}`, {
        error: () => patchState({ meetingsStatus: [] }), // TODO Remove catch of error
        success: meetingsStatus => patchState({ meetingsStatus })
      });
  }

  @Action(GetReportingDocsCountAction)
  public getReportingDocsCountAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingDocsCountAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/uploaded-docs-by-time-report/${filter}`, {
      error: () => patchState({ docsCount: [] }), // TODO Remove catch of error
      success: docsCount => patchState({ docsCount })
    });
  }

  @Action(GetReportingDocsToBeSignedAction)
  public getReportingDocsToBeSignedAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingDocsToBeSignedAction) {
     // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/sign-docs-by-status-report/${filter}`, {
      error: () => patchState({ docsToBeSigned: [] }), // TODO Remove catch of error
      success: docsToBeSigned => patchState({ docsToBeSigned })
    });
  }

  @Action(GetReportingPollsCountAction)
  public getReportingPollsCountAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingPollsCountAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/polls-by-time-report/${filter}`, {
      error: () => patchState({ pollsCount: [] }), // TODO Remove catch of error
      success: pollsCount => patchState({ pollsCount })
    });
  }

  @Action(GetReportingPollsToBeAction)
  public getReportingPollsToBeAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingPollsToBeAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/polls-by-status-report/${filter}`, {
      error: () => patchState({ pollsStatus: [] }), // TODO Remove catch of error
      success: pollsStatus => patchState({ pollsStatus })
    });
  }

  @Action(GetReportingTasksCountAction)
  public getReportingTasksCountAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingTasksCountAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/tasks-by-time-report/${filter}`, {
      error: () => patchState({ tasksCount: { avgTime: 'N/A', meetingData: [] } }),  // TODO Remove catch of error
      success: tasksCount => patchState({ tasksCount })
    });
  }

  @Action(GetReportingTasksToBeAction)
  public getReportingTasksToBeAction({ patchState }: StateContext<IReportingState>, { organizationId, studyId, filter }: GetReportingTasksToBeAction) {
    // TODO move it in service and call the serive, httpService still used?
    return this.httpService.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/tasks-by-status-report/${filter}`, {
      error: () => patchState({ tasksStatus: [] }), // TODO Remove catch of error
      success: tasksStatus => patchState({ tasksStatus })
    });
  }
}
