import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { IDocument } from 'src/app/dsmb/interface/document.interface';
import { DocumentService } from '../../services/document.service';
import { DeleteMeetingDocument, DeleteStudyDocument, DownloadDocumentBase64, DownloadMeetingDocumentBlob,
         DownloadStudyDocumentBase64, DownloadStudyDocumentBlob, GetAuditTrailDocumentAction,
         GetDocumentAction, GetDocumentMeetingTitlesAction, GetDocumentsAction, GetDocumentsCountAction,
         GetDocumentUploadersAction, GetStudyDocumentAction, GetStudyDocumentsAction, GetStudyDocumentsCountAction,
         UploadDocumentAction, UploadStudyDocumentAction, GetAuditTrailDocumentCountAction,
         GetStudyDocumentsUploadedCountAction, GetDocumentToCountersignAction, GetDocumentToCountersignCountAction,
         GetLastDocumentsUploadedAction,
         PropagateUpdateDocument,
         UploadImageTextareaAction} from './document.actions';
import { DOCUMENT_STATE_TOKEN } from './document.token';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentBlobDTO } from '../../dtos/document-blob.dto';
import { IStudyDocument } from 'src/app/dsmb/interface/document.interface';
import { DocumentUploaderDTO } from '../../dtos/document-uploader.dto';
import { DocumentHistoryDTO } from '../../dtos/document-history.dto';
import { TextareaImageUrlDTO } from '../../dtos/textarea-image-url.dto';

export class IDocumentState {
    documents: IDocument[];
    documentsCount: number;
    document: IDocument;
    documentBlob: DocumentBlobDTO;
    documentsBase64: any;
    studyDocuments: IStudyDocument[];
    studyDocument: IStudyDocument;
    documentUploaders: DocumentUploaderDTO[];
    documentMeetingTitles: string[];
    documentsToCountersign: IStudyDocument[];
    documentsToCountersignCount: number;
    documentHistory: DocumentHistoryDTO[];
    documentHistoryCount: number;
    UploadImageTextarea: TextareaImageUrlDTO;
}

@State({
    name: DOCUMENT_STATE_TOKEN,
    defaults: DocumentState.defaultState
})
@Injectable()
export class DocumentState {
    static defaultState: IDocumentState = {
        documents: [],
        documentsCount: null,
        document: null,
        documentBlob: null,
        documentsBase64: null,
        studyDocuments: [],
        studyDocument: null,
        documentUploaders: [],
        documentMeetingTitles: [],
        documentsToCountersign: [],
        documentsToCountersignCount: null,
        documentHistory: [],
        documentHistoryCount: null,
        UploadImageTextarea: null,
    };

    constructor(
        private readonly store: Store,
        private documentService: DocumentService
    ) {

    }

    @Selector()
    static getDocuments(state: IDocumentState) {
        return state.documents;
    }

    @Selector()
    static getDocumentsCount(state: IDocumentState) {
        return state.documentsCount;
    }

    @Selector()
    static getDocument(state: IDocumentState) {
        return state.document;
    }

    @Selector()
    static getDocumentBlob(state: IDocumentState) {
        return state.documentBlob;
    }

    @Selector()
    static getDocumentsBase64(state: IDocumentState) {
        return state.documentsBase64;
    }

    @Selector()
    static getStudyDocuments(state: IDocumentState) {
        return state.studyDocuments;
    }

    @Selector()
    static getStudyDocument(state: IDocumentState) {
        return state.studyDocument;
    }

    @Selector()
    static getDocumentUploaders(state: IDocumentState) {
        return state.documentUploaders;
    }

    @Selector()
    static getDocumentMeetingTitles(state: IDocumentState) {
        return state.documentMeetingTitles;
    }

    @Selector()
    static getDocumentsToCountersign(state: IDocumentState) {
        return state.documentsToCountersign;
    }

    @Selector()
    static getDocumentsToCountersignCount(state: IDocumentState) {
        return state.documentsToCountersignCount;
    }

    @Selector()
    static getDocumentHistory(state: IDocumentState) {
        return state.documentHistory;
    }

    @Selector()
    static getDocumentHistoryCount(state: IDocumentState) {
        return state.documentHistoryCount;
    }

    @Action(GetDocumentsAction)
    getDocuments({ patchState }: StateContext<IDocumentState>, { params: { organizationId, studyId, meetingId, pageData } }: GetDocumentsAction) {
        return this.documentService.getDocuments(organizationId, studyId, meetingId, pageData?.filter, pageData?.startPage, pageData?.itemsPerPage, pageData?.sorting)
            .pipe(
              tap(documents => patchState({ documents })),
              catchError((error: HttpErrorResponse) => {
                  if (error.status === 404) {
                      patchState({ documents: [] });
                  }

                  return throwError(() => error);
              })
            );
    }

    @Action(GetDocumentsCountAction)
    getDocumentsCount({ patchState }: StateContext<IDocumentState>, { params: { organizationId, studyId, meetingId, pageData: { filter } } }: GetDocumentsCountAction) {
        return this.documentService.getDocumentsCount(organizationId, studyId, meetingId, filter)
            .pipe(tap(documentsCount => patchState({ documentsCount })));
    }

    @Action(UploadDocumentAction)
    uploadDocument({ getState, setState }: StateContext<IDocumentState>, { meetingDocument, organizationId, studyId, meetingId, documentId, versionNo }: UploadDocumentAction) {
        return this.documentService.uploadDocument(meetingDocument, organizationId, studyId, meetingId, documentId, versionNo);
    }

    @Action(GetDocumentAction)
    getDocument({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, meetingId, documentId, versionNo }: GetDocumentAction) {
        return this.documentService.getDocument(organizationId, studyId, meetingId, documentId, versionNo)
            .pipe(tap(document => patchState({ document })));
    }

    @Action(DownloadMeetingDocumentBlob)
    downaloadDocumentsBlob({ getState, setState }: StateContext<IDocumentState>, { organizationId, studyId, meetingId, documentId, versionId }: DownloadMeetingDocumentBlob) {
        return this.documentService.downloadDocumentBlob(organizationId, studyId, meetingId, documentId, versionId)
            .pipe(tap(blob => {
                const state = getState();
                const temp: DocumentBlobDTO = {
                    fileId: documentId,
                    blob: blob
                };

                setState({
                    ...state,
                    documentBlob: temp,
                });
            }));
    }

    @Action(DownloadStudyDocumentBlob)
    downloadStudyDocumentBlob({ getState, setState }: StateContext<IDocumentState>, { organizationId, studyId, documentId, versionId }: DownloadStudyDocumentBlob) {
        return this.documentService.downloadStudyDocumentBlob(organizationId, studyId, documentId, versionId)
        .pipe(tap(blob => {
            const state = getState();
            const temp: DocumentBlobDTO = {
                fileId: documentId,
                blob: blob
            };

            setState({
                ...state,
                documentBlob: temp,
            });
        }));
    }

    @Action(DownloadDocumentBase64)
    downaloadDocumentsBase64({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, meetingId, documentId, versionId }: DownloadDocumentBase64) {
        return this.documentService.downloadDocumentBase64(organizationId, studyId, meetingId, documentId, versionId)
            .pipe(tap(documentsBase64 => patchState({ documentsBase64 })));
    }

    @Action(DownloadStudyDocumentBase64)
    downaloadStudyDocumentsBase64({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, documentId, versionId }: DownloadStudyDocumentBase64) {
        return this.documentService.downloadStudyDocumentBase64(organizationId, studyId, documentId, versionId)
            .pipe(tap(documentsBase64 => patchState({ documentsBase64 })));
    }

    @Action(DeleteMeetingDocument)
    deleteDocument({ getState, setState }: StateContext<IDocumentState>, { organizationId, studyId, meetingId, documentId }: DeleteMeetingDocument) {
        return this.documentService.deleteDocument(organizationId, studyId, meetingId, documentId)
            .pipe(tap(_ => {
                // Remove deleted document from document list
                const state = getState();
                let documentsList = [...state.documents];
                documentsList = documentsList.filter(d => d.id !== documentId);

                // Remove the document form the studyDocument list if there is
                let studyDocumentList = [...state.studyDocuments];
                if (studyDocumentList.length > 0) {
                    studyDocumentList = studyDocumentList.filter(d => d.id !== documentId);
                }

                // Decrease the document count
                const updatedCount = state.documentsCount - 1;

                // Remove document from the document to countersign list if there is
                let documentsToCountersign = [...state.documentsToCountersign];
                let documentsToCountersignCount = state.documentsToCountersignCount;
                if (documentsToCountersign.find(d => d.id === documentId)) {
                    documentsToCountersign = documentsToCountersign.filter(d => d.id !== documentId);
                    documentsToCountersignCount -= 1;
                }

                setState({
                    ...state,
                    documents: documentsList,
                    studyDocuments: studyDocumentList,
                    documentsCount: updatedCount,
                    documentsToCountersign: documentsToCountersign,
                    documentsToCountersignCount: documentsToCountersignCount
                });
            }));
    }

    @Action(PropagateUpdateDocument)
    propagateUpdateDocument({ getState, setState }: StateContext<IDocumentState>, { documentId }: DeleteMeetingDocument) { 
        // Remove deleted document from document list
        const state = getState();
        let documentsList = [...state.documents];
        let updatedDocumentCount = state.documentsCount;
        if (documentsList.length > 0) {
            documentsList = documentsList.filter(d => d.id !== documentId);
            updatedDocumentCount = state.documentsCount - 1;
        }
        
        // Remove the document form the studyDocument list if there is
        let studyDocumentList = [...state.studyDocuments];
        if (studyDocumentList.length > 0) {
            studyDocumentList = studyDocumentList.filter(d => d.id !== documentId);
        }

        // Remove document from the document to countersign list if there is
        let documentsToCountersign = [...state.documentsToCountersign];
        let updatedDocumentsToCountersignCount = state.documentsToCountersignCount;
        if (documentsToCountersign.find(d => d.id === documentId)) {
            documentsToCountersign = documentsToCountersign.filter(d => d.id !== documentId);
            updatedDocumentsToCountersignCount -= 1;
        }

        setState({
            ...state,
            documents: documentsList,
            studyDocuments: studyDocumentList,
            documentsCount: updatedDocumentCount,
            documentsToCountersign: documentsToCountersign,
            documentsToCountersignCount: updatedDocumentsToCountersignCount
        });
    }

    @Action(DeleteStudyDocument)
    deleteStudyDocument({ getState, setState }: StateContext<IDocumentState>, { organizationId, studyId, documentId }: DeleteStudyDocument) {
        return this.documentService.deleteStudyDocument(organizationId, studyId, documentId)
            .pipe(tap(_ => {
                // Remove the document form the studyDocument list if there is
                const state = getState();
                let studyDocumentList = [...state.studyDocuments];
                if (studyDocumentList.length > 0) {
                    studyDocumentList = studyDocumentList.filter(d => d.id !== documentId);
                }

                // Decrease the document count
                const updatedCount = state.documentsCount - 1;

                // Remove document from the document to countersign list if there is
                let documentsToCountersign = [...state.documentsToCountersign];
                let documentsToCountersignCount = state.documentsToCountersignCount;
                if (documentsToCountersign.find(d => d.id === documentId)) {
                    documentsToCountersign = documentsToCountersign.filter(d => d.id !== documentId);
                    documentsToCountersignCount -= 1;
                }

                setState({
                    ...state,
                    studyDocuments: studyDocumentList,
                    documentsCount: updatedCount,
                    documentsToCountersign: documentsToCountersign,
                    documentsToCountersignCount: documentsToCountersignCount
                });
            }));
    }

    @Action(GetStudyDocumentsAction)
    getStudyDocuments({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, filter, startPage, itemsPerPage, sorting }: GetStudyDocumentsAction) {
        return this.documentService.getStudyDocuments(organizationId, studyId, filter, startPage, itemsPerPage, sorting)
            .pipe(tap(studyDocuments => patchState({ studyDocuments })),
                // So that in case method return empty list, attendees list get emptied
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        patchState({ studyDocuments: [] });
                    }

                    return throwError(() => error);
                }));
    }

    @Action(GetStudyDocumentsCountAction)
    getStudyDocumentsCount({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, filter }: GetStudyDocumentsCountAction) {
        return this.documentService.getStudyDocumentCount(organizationId, studyId, filter)
            .pipe(
              tap(documentsCount => patchState({ documentsCount })),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                  patchState({ documentsCount: 0 });
                }

                return throwError(() => error);
              })
            );
    }

    @Action(GetStudyDocumentsUploadedCountAction)
    getStudyDocumentUploadedCount( {patchState }: StateContext<IDocumentState>, { organizationId, studyId }: GetStudyDocumentsUploadedCountAction) {
        return this.documentService.getUploadedStudyDocumentCount(organizationId, studyId)
            .pipe(
              tap(documentsCount => patchState({ documentsCount })),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                  patchState({ documentsCount: 0 });
                }

                return throwError(() => error);
              })
            );
    }

    @Action(UploadStudyDocumentAction)
    uploadStudyDocument({ getState, setState }: StateContext<IDocumentState>, { payload: { studyDocument, organizationId, studyId, documentId, versionNo } }: UploadStudyDocumentAction) {
        return this.documentService.uploadStudyDocument(studyDocument, organizationId, studyId, documentId, versionNo);
    }

    @Action(GetStudyDocumentAction)
    getStudyDocument({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, documentId, versionNo }: GetStudyDocumentAction) {
        return this.documentService.getStudyDocument(organizationId, studyId, documentId, versionNo)
            .pipe(tap(studyDocument => patchState({ studyDocument })));
    }

    @Action(GetDocumentUploadersAction)
    getDocumentUploaders({ patchState }: StateContext<IDocumentState>, { organizationId, studyId }: GetDocumentUploadersAction ) {
        return this.documentService.getDocumentUploaders(organizationId, studyId)
            .pipe(tap(documentUploaders => patchState({ documentUploaders })));
    }

    @Action(GetDocumentMeetingTitlesAction)
    getDocumentMeetingTitles({ patchState }: StateContext<IDocumentState>, { organizationId, studyId }: GetDocumentMeetingTitlesAction ) {
        return this.documentService.getDocumentMeetingTitles(organizationId, studyId)
            .pipe(tap(documentMeetingTitles => patchState({ documentMeetingTitles })));
    }

    @Action(GetAuditTrailDocumentAction)
    getDocumentHistory({ patchState }: StateContext<IDocumentState>, { payload: { organizationId, studyId, documentId, isStudyDocument, startPage, itemsPerPage, sorting } }: GetAuditTrailDocumentAction ) {
        return this.documentService.getDocumentHistory(organizationId, studyId, documentId, isStudyDocument, startPage, itemsPerPage, sorting)
            .pipe(tap(documentHistory => patchState({ documentHistory })),
                // So that in case method return empty list, attendees list get emptied
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        patchState({ documentHistory: [] });
                    }

                    return throwError(() => error)
                }));
    }

    @Action(GetAuditTrailDocumentCountAction)
    getDocumentHistoryCount({ patchState }: StateContext<IDocumentState>, { payload: { organizationId, studyId, documentId, isStudyDocument } }: GetAuditTrailDocumentCountAction ) {
        return this.documentService.getDocumentHistoryCount(organizationId, studyId, documentId, isStudyDocument)
          .pipe(tap(documentHistoryCount => patchState({ documentHistoryCount })));
    }

    @Action(GetDocumentToCountersignAction)
    getDocumentToCountersign({ patchState }: StateContext<IDocumentState>, { organizationId, studyId, startPage, itemsPerPage}: GetDocumentToCountersignAction) {
        return this.documentService.getDocumentsToCountersign(organizationId, studyId, startPage, itemsPerPage)
        .pipe(
            tap(documentsToCountersign => patchState({ documentsToCountersign })),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                patchState({ documentsToCountersign: [] });
              }

              return throwError(() => error)
            })
          );
    }

    @Action(GetDocumentToCountersignCountAction)
    getDocumentToCountersignCount({ patchState }: StateContext<IDocumentState>, { organizationId, studyId }: GetDocumentToCountersignCountAction) {
        return this.documentService.getDocumentsToCountersignCount(organizationId, studyId)
          .pipe(
            tap(documentsToCountersignCount => patchState({ documentsToCountersignCount })),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                patchState({ documentsToCountersignCount: 0 });
              }

              return throwError(() => error)
            })
          );
    }

    @Action(GetLastDocumentsUploadedAction)
    getLastDocumentsUploaded({ patchState }: StateContext<IDocumentState>, { organizationId, studyId }: GetLastDocumentsUploadedAction) {
        return this.documentService.getLastDocumentUploaded(organizationId, studyId)
          .pipe(tap(studyDocuments => patchState({ studyDocuments })));
    }

    @Action(UploadImageTextareaAction)
    uploadImageTextarea({ patchState }: StateContext<IDocumentState>, { payload, isPublic }: UploadImageTextareaAction) {
        return this.documentService.uploadImageTextarea(payload, isPublic).pipe(tap(textareaImageUrl => patchState({ UploadImageTextarea: textareaImageUrl })));
    }
}
