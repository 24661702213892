export interface ApplicationDTO {
    id: string;
    applicationType: string;
    name: string;
}

export enum ApplicationsEnum {
  PLATFORM = 'platform',
  DSMB = 'dsmb'
}

export enum ApplicationScopeEnum {
  STUDY = 'study',
  ORGANIZATION = 'organization',
  ALL = 'all',
  SELF = 'self'
}
