import { DiscussionItemDTO } from 'src/app/dsmb/dtos/meeting-discussion-item.dto';
import { MeetingId } from '../../platform/utils/types';
import { DecisionDTO } from 'src/app/dsmb/dtos/decision.dto';
import { ITask } from './task.interface';

export enum DocumentsSigned {
  COMPLETED = 'completed',
  NOT_ACTIVE = 'notActive',
  INCOMPLETED = 'incompleted'
}
export interface IDocument extends IDocumentBase<IDocument> {
  title: string;
  type: string;
  created: string;
  isAgenda: string;
  isOutcome: string;
  retainAt?: string;
}

export interface IStudyDocument extends IDocumentBase<IStudyDocument> {
  documentTitle: string;
  createdAt: string;
  retainAt?: string;
  uploadBy: string;
  documentType: string;
  meetingTitle: string;
  meetingSession: string;
  meetingId: MeetingId;
  uploadByFullName?: string;
}

interface IDocumentBase<T> {
  id: string;
  name: string;
  key: string;
  versionId: string;
  versionNo: number;
  meetingId: string;
  discussionItemId: string;
  decisionId: string;
  discussionItem: DiscussionItemDTO;
  decision: DecisionDTO;
  discussionItemTitle: string;
  decisionTitle: string;
  task: ITask,
  taskId: string;
  taskTitle: string;
  view: string;
  download: string;
  author: string;
  description: string;
  edit: string;
  username: string;
  requireCountersign: boolean;
  completedCountersign: boolean;
  lastVersion: T;
  oldVersions: T[];
  /* @description UI field */
  children: T[];
  /* @description UI field */
  open: boolean;
  readonly documentSignStatus: DocumentsSigned;
  _retainAt: string;
  _tooltip: string;
}
