import { MeetingId } from '../../utils/types';

export class GetPanelUsersAction {
  static readonly type = '[Panel] [GET] [Paged Users]';

  constructor(
    public orgId: string,
    public studyId: string,
    public applicationId: string,
    public startPage: number,
    public itemsPerPage: number,
    public sorting: string,
    public filter: string
  ) { }
}

export class GetPanelUsersCountAction {
  static readonly type = '[Panel] [GET] [Users Count]';

  constructor(
    public orgId: string,
    public studyId: string,
    public applicationId: string,
    public filter: string
  ) { }
}

export class GetPanelUserDetailsAction {
  static readonly type = '[Panel] [GET] [User Details]';

  constructor(
    public orgId: string,
    public studyId: string,
    public username: string
  ) { }
}

export class PanelUserRoleActivateAction {
  static readonly type = '[Panel] [POST] [User Activate Role]';

  constructor(
    public orgId: string,
    public studyId: string,
    public username: string
  ) { }
}

export class PanelUserRoleDeactivateAction {
  static readonly type = '[Panel] [POST] [User Deactivate Role]';

  constructor(
    public orgId: string,
    public studyId: string,
    public username: string
  ) { }
}

export class GetPanelUserLoginsAction {
  static readonly type = '[Panel] [GET] [User Logins]';

  constructor(
    public payload: {
      orgId: string,
      studyId: string,
      username: string,
      pageData: {
        startPage: number,
        itemsPerPage: number,
        columnSort: string
      }
    }
  ) { }
}

export class GetPanelUserLoginsCountAction {
  static readonly type = '[Panel] [GET] [User Logins Count]';

  constructor(
    public payload: {
      orgId: string,
      studyId: string,
      username: string
    }
  ) { }
}

export class GetPanelDocumentsAction {
  static readonly type = '[Panel] [GET] [Documents]';

  constructor(
    public params: {
      organizationId:string,
      studyId: string,
      username: string,
      pageData?: {
        filter: string,
        startPage?: number,
        itemsPerPage?: number,
        sorting?: string
      }
    }
  ) { }
}

export class GetPanelDocumentsCountAction {
  static readonly type = '[Panel] [GET] [Documents Count]';

  constructor(
    public params: {
      organizationId: string, 
      studyId: string,
      username: string,
      pageData?: {
        filter: string,
        startPage?: number,
        itemsPerPage?: number,
        sorting?: string
      }
    }
  ) { }
}

export class GetPanelDocumentAction {
  static readonly type = '[Panel] [GET] [Document]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public documentId: string,
    public versionNo: string
  ) { }
}

export class GetPanelDocumentBase64Action {
  static readonly type = '[Panel] [GET] [Document Base64]';

  constructor(
    public organizationId:string,
    public studyId: string,
    public documentId: string,
    public versionId: string
  ) { }
}

export class UploadPanelDocumentAction {
  static readonly type = '[Panel] [POST] [Upload Document]';

  public readonly post = {
    notification: {
      success: 'dsmb.upload-document.toastr-message'
    }
  };

  constructor(
    public payload: {
      studyDocument: FormData,
      organizationId:string,
      studyId: string,
      documentId: string,
      versionNo: number,
      notify: boolean
    }
  ) { }
}

export class DownloadPanelDocument {
  static readonly type = '[Panel] [POST] [Download Document]';

  constructor(
    public httpParams: {
      organizationId:string,
      studyId: string,
      documentId: string,
      versionId: string
    },
    public params: {
      filename: string
    }
  ) { }
}

export class DeletePanelDocument {
  static readonly type = '[Panel] [DELETE] [Document]';

  public readonly post = {
    notification: {
      success: 'dsmb.delete-document.toastr-message'
    }
  };

  constructor(
    public params: {
      organizationId:string,
      studyId: string,
      documentId: string
    }
  ) { }
}

export class GetPanelDocumentAuditTrailAction {
  static readonly type = '[Panel] [GET] [Document Audit Trail]';

  constructor(
    public payload: {
      organizationId:string,
      studyId: string,
      documentId: string,
      isStudyDocument: boolean,
      startPage?: number,
      itemsPerPage?: number,
      sorting?: string
    }
  ) { }
}

export class GetAuditTrailDocumentCountAction {
  static readonly type = '[Panel] [Get] [Document Audit Trail Count]';

  constructor(
    public payload: {
      organizationId: string,
      studyId: string,
      documentId: string,
      isStudyDocument: boolean
    }
  ) { }
}
