import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum BroadcastMessageEnum {
  countersigned = 'countersigned',
  taskDone = 'task-done',
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private readonly channel = new BroadcastChannel('sync');

  public channel$ = new BehaviorSubject<string>(null);

  constructor() {
    this.channel.addEventListener('message', event => {
      if (this.channel$.closed) {
        this.create();
      }

      this.channel$.next(event.data);
    });
  }

  private create(): void {
    this.channel$ = new BehaviorSubject(null);
  }

  public notify(message: string): void {
    this.channel.postMessage(message);
  }
}
