export class GetSidebarAction {
  static readonly type = '[Sidebar] [GET]';
}

export class ToggleSidebarItemAction {
  static readonly type = '[Sidebar Toggle Item]';

  constructor(public selectedItemId: string) {
  }
}

export class GetSidebarTreeAction {
  static readonly type = '[Sidebar Tree] [GET]';
}

export class UpdateDsmbSidebarAction{
  static readonly type = '[Sidebar] [GET] [Update Dsmb Sidebar]';
}

export class ToggleSidebarAction {
  static readonly type = '[Sidebar Toggle]';
}
