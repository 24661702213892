import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ISidebarItem } from '../interfaces/sidebar-item.interface';
import { SidebarItem } from '../model/sidebar.model';
import { UserState } from 'src/app/platform/store/user/user.state';
import { Select } from '@ngxs/store';
import { IUser } from '../model/user.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StringEmpty } from '../utils/global-vars';
import { ApplicationsEnum } from '../dtos/application.dto';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  @Select(UserState.getLoggedUser) user$: Observable<IUser>;

  // TODO - Review if it still necessary?? 
  private readonly switchStructure = switchMap((sidebarTree: ISidebarItem[]) => {
    return this.user$
      .pipe(
        first(),
        map((user) => {
          const output: ISidebarItem[] = [];
          const countOrgs = sidebarTree.filter(org => org.type === 'organization').length;

          sidebarTree.forEach(organization => {
            const organizationItem = new SidebarItem();

            if (countOrgs > 0 || organization.type === 'page') {
              organizationItem.id = organization.id;
              organizationItem.label = organization.label;
              organizationItem.icon = organization.icon;
              organizationItem.link = organization.link;
              organizationItem.type = organization.type;

              if(countOrgs == 1){
                organizationItem.open = true;
              }

              output.push(organizationItem);
            }

            const countStudies = organization.children.filter(study => study.type === 'study').length;

            organization.children.forEach(study => {
              const studyItem = new SidebarItem();

              studyItem.id = study.id;
              studyItem.label = study.label;
              studyItem.icon = study.icon;
              studyItem.link = study.link;
              studyItem.type = study.type;

              if( countOrgs == 1 && countStudies ==1 ){
                studyItem.open = true;
              }

              if (countOrgs > 0) {
                organizationItem.children.push(studyItem);
              } else {
                output.push(studyItem);
              }

              study.children.forEach(application => {
                const applicationItem = new SidebarItem();



                if (study.children.length > 1 || application.type === 'page') {
                  applicationItem.id = application.id;
                  applicationItem.label = application.label;
                  applicationItem.icon = application.icon;
                  applicationItem.link = application.link;
                  applicationItem.type = application.type;

                  if (countStudies > 1 && application.type === 'application') {
                    studyItem.children.push(applicationItem);
                  } else if (countOrgs > 1) {
                    organizationItem.children.push(applicationItem);
                  } else {
                    output.push(applicationItem);
                  }
                }

                application.children.forEach(page => {
                  const pageItem = new SidebarItem();

                  pageItem.id = page.id;
                  pageItem.label = page.label;
                  pageItem.icon = page.icon;
                  pageItem.link = page.link;
                  pageItem.type = page.type;

                  if (study.children.length > 1) {
                    applicationItem.children.push(pageItem);
                  } else if (countStudies || user.isSuper()) {
                    studyItem.children.push(pageItem);
                  } else if (countOrgs > 1) {
                    organizationItem.children.push(pageItem);
                  } else {
                    output.push(pageItem);
                  }
                });
              });
            });
          });

          return output;
        })
      );
  });

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router
  ) { }

  public getSidebar(): Observable<ISidebarItem[]> {
    return this.httpClient.get<ISidebarItem[]>(`${environment.platformHost}/platform/user/sidebar`)
      .pipe(
        this.switchStructure
      );
  }

  public getActionRequired(): Observable<Map<string,number>> {
    return this.httpClient.get<Map<string,number>>(`${environment.dsmbHost}/dsmb/user/action-required`)
  }

  public updateDsmbSidebar(sidebar: ISidebarItem[], actionRequired: Map<string,number>) {
    let studyNodes = this.getAllStudyNodes(sidebar);
    for(let study of studyNodes){
      if(actionRequired.hasOwnProperty(study.id)){
        study.numberActionRequired = actionRequired[study.id];
      }
    }
    for(let node of sidebar){
      if(node.type == 'organization'){
        node.numberActionRequired = actionRequired[node.id];
      }
    }
  }

  public getAllStudyNodes(sidebar: ISidebarItem[]) : ISidebarItem[]{
    let studyNodes = [];
    this.searchStudyNodes(sidebar, studyNodes);
    return studyNodes;
  }

  private searchStudyNodes(sidebar: ISidebarItem[], studyNodes = []): void {
    for(let node of sidebar){
      if(node.type == 'study'){
        studyNodes.push(node);
      }
      else{
        this.searchStudyNodes(node.children, studyNodes)
      }
    }
  }
}
