import { Route } from '@angular/router';
import { StateToken } from '@ngxs/store';
import { Opaque } from 'type-fest';
import { ApplicationsEnum } from '../dtos/application.dto';
import { Breadcrumb } from '../shared/breadcrumbs/breadcrumbs.component';
import { StringEmpty } from './global-vars';

export type Unpack<T extends StateToken> = T extends StateToken<infer U> ? U : T;

export interface IConfirmationEmailResponse {
  organizationId: string;
  meetingDate: string;
  meetingName: string;
  message: string;
  studyName: string;
}

export enum ContentType {
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export enum DropdownOptionsEnum {
  Any = 'Any',
  Yes = 'Yes',
  No = 'No',
}

export interface IRoute extends Route {
  data?: {
    application: ApplicationsEnum,
    operation: string,
    icon?: string,
    breadcrumbs?: Breadcrumb[]
  };
  children?: IRoute[];
}

export type MeetingId = Opaque<string, 'MeetingId'>;
export const MeetingId = (value = StringEmpty) => value as MeetingId;

export type StudyId = Opaque<string, 'StudyId'>;
export const StudyId = (value = StringEmpty) => value as StudyId;

export type DiscussionItemId = Opaque<string, 'DiscussionItemId'>;
export const DiscussionItemId = (value = StringEmpty) => value as DiscussionItemId;