import { Routes } from '@angular/router';
import { AuthGuard } from './platform/guards/auth.guard';
import { NoSidebarLayoutComponent } from './platform/layout/no-sidebar-layout/no-sidebar-layout.component';
import { HomeComponent } from './platform/pages/home/home.component';
import { StringEmpty } from './platform/utils/global-vars';
import { OnlyContentLayoutComponent } from './platform/shared/only-content-layout/only-content-layout.component';
import { UserResolver } from './platform/resolvers/user.resolver';
import { ApplicationsEnum } from './platform/dtos/application.dto';
import { FAGuard } from './platform/guards/2fa.guard';
import { DefaultLayoutComponent } from './platform/shared/default-layout/default-layout.component';
import { TrainingGuard } from './platform/guards/training.guard';


export const appRoutes: Routes = [
  {
    path: StringEmpty,
    redirectTo: '/home',
    pathMatch: 'full'
  },

  // No authenticated Routes 
  {
    path: '',
    children: [
      {
        path: 'login',
        component: OnlyContentLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('src/app/platform/pages/login/login.module').then(m => m.LoginModule)
          }
        ]
      },
      {
        path: 'meeting-email-confirmation',
        component: NoSidebarLayoutComponent,
        loadChildren: () => import('./dsmb/pages/meeting-email-confirm/meeting-email-confirm.module').then(m => m.MeetingEmailConfirmModule)
      },
      {
        path: 'privacy-policy',
        component: NoSidebarLayoutComponent,
        loadChildren: () => import('src/app/platform/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
    ]
  },


  // Authenticated Routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: { data: UserResolver },
    children: [
      
      {
        path: '',
        canActivate: [FAGuard],
        data: {
          operation: 'view-profile-page',
          application: ApplicationsEnum.PLATFORM,
        },
        children: [
          {
            path: 'home',
            component: HomeComponent,
          },
          {
            path: '',
            loadChildren: () => import('./platform/platform.module').then(m => m.PlatformModule)
          }
        ]
      },

      // Profile page
      {
        path: 'profile',
        component: DefaultLayoutComponent,
        loadChildren: () => import('src/app/platform/pages/profile/profile.module').then(m => m.ProfileModule),
        data: {
          operation: 'view-profile-page',
          application: ApplicationsEnum.PLATFORM,
          breadcrumbs: [
            {
              label: 'platform.profile.title'
            }
          ]
        }
      }
    ],
  },

  

  


  {
    path: 'error',
    component: NoSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/platform/pages/page-error/page-error.module').then(m => m.PageErrordModule),
      },

    ],
  },
  {
    path: '**',
    redirectTo: '/error/page-not-found',
  }
];

