import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { DiscussionItemDTO } from "../dtos/meeting-discussion-item.dto";
import { environment } from 'src/environments/environment';
import { DiscussionItemPresenterDTO } from "../dtos/discussion-item-presenter.dto";
import { StringEmpty } from "src/app/platform/utils/global-vars";
import { IDocument, IStudyDocument } from "src/app/dsmb/interface/document.interface";
import { DocumentHistoryDTO } from "../dtos/document-history.dto";

@Injectable({
  providedIn: 'root'
})
export class DiscussionItemService {

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  public getDiscussionItems(organizationId: string, studyId: string, filter: string, startPage?: number, itemsPerPage?: number, sorting?: string): Observable<DiscussionItemDTO[]> {
    if (filter) {
      filter += ' and \'di.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'di.StudyId\' eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      sorting = sorting.replace("meetingTitle", "meetingId");
      sorting = `$orderby=${sorting}, CreatedAt desc, Order asc`;
    }
    else {
      sorting = `$orderby=CreatedAt desc, Order asc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<DiscussionItemDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-items${odataQueryString}`);
  }

  public getDiscussionItemDetail(organizationId: string, studyId: string, discussionItemId: string): Observable<DiscussionItemDTO> {
    return this.httpClient.get<DiscussionItemDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}`);
  }

  public getDiscussionItemsCount(organizationId: string, studyId: string, filter: string): Observable<number> {

    if (filter) {
      filter += ' and \'di.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'di.StudyId\' eq \'' + studyId + '\'';
    }

    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-items${odataQueryString}`);
  }

  public addDiscussionItem(organizationId: string, studyId: string, discussionItem: DiscussionItemDTO): Observable<DiscussionItemDTO> {
    return this.httpClient.post<DiscussionItemDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item`, discussionItem);

  }

  public getDiscussionItemsByMeeting(organizationId: string, studyId: string, meetingId: string, filter: string, startPage: number, itemsPerPage: number, sorting: string) {
    if (filter) {
      filter += ' and StudyId eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=StudyId eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      sorting = `$orderby=${sorting}, Order asc`;
    }
    else {
      // by default sort meeting by start descending
      sorting = `$orderby=Order asc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<DiscussionItemDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-items${odataQueryString}`);
  }

  public getDiscussionItemsCountByMeeting(organizationId: string, studyId: string, meetingId: string, filter: string): Observable<number> {

    if (filter) {
      filter += ' and StudyId eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=StudyId eq \'' + studyId + '\'';
    }

    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-items${odataQueryString}`);
  }

  public getDiscussionItemsPresenters(organizationId: string, studyId: string, filter: string, startPage?: number, itemsPerPage?: number, sorting?: string): Observable<DiscussionItemPresenterDTO[]> {
    if (filter) {
      filter += ' and \'di.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'di.StudyId\' eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;


    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<DiscussionItemPresenterDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-items-presenters${odataQueryString}`);
  }

  public getDiscussionItemsAssociatedWithDecisions(organizationId: string, studyId: string, filter: string): Observable<DiscussionItemDTO[]> {
    let odataQueryString = StringEmpty;

    if (filter) {
      odataQueryString = `?${filter}`;
    }

    return this.httpClient.get<DiscussionItemDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decisions-discussion-item${odataQueryString}`);
  }

  public addDiscussion(organizationId: string, studyId: string, discussionItemId: string, discussion: string): Observable<DiscussionItemDTO> {
    return this.httpClient.post<DiscussionItemDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/discussion`, { discussion: discussion });
  }

  public editDiscussionItemDetails(organizationId: string, studyId: string, discussionItem: DiscussionItemDTO): Observable<DiscussionItemDTO> {
    return this.httpClient.post<DiscussionItemDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItem.id}/edit`, { discussionItem: discussionItem });
  }

  public deleteDiscussionItem(organizationId: string, studyId: string, discussionItemId: string, comment: string) {
    return this.httpClient.post<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/delete`, { comment: comment })
  }

  public uploadDiscussionItemDocument(organizationId: string, studyId: string, documentId: string, versionNo: number, notify: boolean, document: any){
    let meetingId = document.get('meetingId');
    let discussionItemId = document.get('discussionItemId');
    return this.httpClient.post<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/upload/${documentId}${versionNo > 0 ? `/file/${versionNo}/` : StringEmpty}notify/${notify}`, document)
    .pipe(map( document => {
       return {
        ...(document.lastVersion || document),
        children: document.oldVersions || [],
        open: false,
      };
    }));
  }

  public getDiscussionItemDocuments(organizationId: string, studyId: string, discussionItemId: string, meetingId:string ): Observable<IStudyDocument[]>{
    return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/documents`)
    .pipe(this.mapDocuments());
  }

  public getdiscussionItemDocumentsCount(organizationId:string, studyId:string, discussionItemId:string, meetingId:string): Observable<number>{
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/documents?$count=true`)
  }

  public getDocument(organizationId:string, studyId:string, documentId:string, versionNo:string){
    return this.httpClient.get<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/document/${documentId}/version/${versionNo}/view`)
  }

  public getDocumentBase64(organizationId: string, studyId:string, documentId:string, versionNo:string){
  return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/document/${documentId}/version/${versionNo}?isBase64=true`, {
    responseType: 'text'})
  }

  public downloadDocumentBlob(organizationId: string, studyId:string, documentId:string, versionNo: string) {
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/document/${documentId}/version/${versionNo}?isBase64=false`, {
      responseType: 'blob'
    });
  }

  public getAuditTrailDiscussionItemDocument(organizationId: string, studyId: string, discussionItemId: string,  documentId: string, startPage: number, itemsPerPage: number, sorting: string){
    const filter = `$filter=Args1 eq '${documentId}' and Operation eq 'download-discussion-item-document' or Operation eq 'view-discussion-item-document' or Operation eq 'upload-discussion-item-document' or Operation eq 'update-discussion-item-document'`;
    const order = `$orderby=Timestamp desc`;
    let odataQueryString = `?${filter}&${order}`;
    const isStudyDocument = false; //ALERT
    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    const temp = params.join('&');
    odataQueryString += `&${temp}`;
    return this.httpClient.get<DocumentHistoryDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
  }

  public getAuditTrailDiscussionItemDocumentCount(organizationId:string, studyId:string, discussionItemId: string, documentId:string){
    const filter = `?$filter=Args1 eq '${documentId}' and Operation eq 'download-discussion-item-document' or Operation eq 'get-discussion-item-documents' or Operation eq 'upload-discussion-item-document' or Operation eq 'update-discussion-item-document'`;
    const odataQueryString = filter + `&$count=true`;
    const isStudyDocument = false;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
  }

  deleteDiscussionItemDocument(organizationId:string, studyId: string, meetingId: string, discussionItemId: string, documentId: string) {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/document/${documentId}`);
  }

  updateMeetingDiscussionItemOrder(organizationId: string, studyId: string, meetingId: string, discussionItems: DiscussionItemDTO[]): Observable<DiscussionItemDTO[]> {
    return this.httpClient.post<DiscussionItemDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-items/update-order`, { discussionItems: discussionItems });
  }

  deleteDiscussionItemPoll(organizationId: string, studyId: string, meetingId: string, discussionItemId: string, pollId: string) {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/polls/${pollId}`);
  }

  private mapDocuments() {
    return map((documents: any[]) => {
      return documents.map(document => {
        if (!document.oldVersions) {
          console.error('document.oldVersions is empty');
        }

        return {
          ...(document.lastVersion || document),
          children: document.oldVersions || [],
          open: false,
        };
      });
    });
  }
}
