import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VersionState } from '../../store/version/version.state';
import { IVersionDTO } from '../../dtos/version.dto';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, tap } from 'rxjs';
import { GetDsmbBackendVersion, GetPlatformBackendVersion } from '../../store/version/version.actions';
import  packageInfo  from 'package.json'
import { UserState } from '../../store/user/user.state';
import { IUser } from '../../model/user.model';
import { AuthService } from '../../services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})

export class VersionComponent implements OnInit {
  public platformBackendVersion: string;
  public dmsbBackendVersion: string;
  public readonly appVersion: string = packageInfo.version;
  public backendVersions: string;
  

  @Select(VersionState.getPlatformBackendVersion) platformVersion$: Observable<IVersionDTO>;
  @Select(VersionState.getDsmbBackendVersion) dsmbVersion$: Observable<IVersionDTO>;  
  @Select(UserState.getLoggedUser) loggedUser$: Observable<IUser>;

  public loggedUser: IUser;

  constructor(
    private readonly store: Store,    
    private readonly authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.userIsLogged()) {
      this.loggedUser$.subscribe(loggedUser => {
        this.loggedUser = loggedUser;
        if(this.loggedUser.isSuper()){
        this.store.dispatch([new GetPlatformBackendVersion(), new GetDsmbBackendVersion()]);  
        combineLatest([
          this.platformVersion$,
          this.dsmbVersion$
        ])
        .pipe(
          untilDestroyed(this),
          tap(([platformVersion, dsmbVersion]:[IVersionDTO, IVersionDTO]) => 
          {
            this.backendVersions = `- Platform BE version ${platformVersion.fullVersion} - DSMB BE version ${dsmbVersion.fullVersion}`
          }      
          )
        )
        .subscribe();
      }
      });
    }
  }
}

