import { Component, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import packageInfo from 'package.json'

@Component({
  selector: 'app-only-content-layout',
  templateUrl: './only-content-layout.component.html',
  styleUrls: ['./only-content-layout.component.scss']
})
export class OnlyContentLayoutComponent {

  environmentName: string;
  isLogin: boolean;
  public readonly appVersion = packageInfo.version;

  constructor(private readonly activeRoute: ActivatedRoute) {
    this.environmentName = environment.environment;
    const componentName = (this.activeRoute.snapshot.children[0].component as Type<any>)?.name;
    this.isLogin = componentName === 'LoginComponent';
  }
}
