import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StringEmpty } from '../utils/global-vars';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // TODO: delete static fields after all components call NotificationsService.notify
  static TIME_OUT = 4000;
  static POSITION_CLASS = 'toast-top-center';

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastrService: ToastrService,
  ) { }

  public notifySuccess(messageKey: string): void {
    const message = this.translateService.instant(messageKey);
    this.toastrService.success(message, StringEmpty);
  }

  public notifyInfo(messageKey: string): void { 
    const message = this.translateService.instant(messageKey);
    this.toastrService.info(message, StringEmpty);
  }
}
