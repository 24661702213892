import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { DocumentCountersignDTO } from '../../dtos/document-countersign.dto';
import { DocumentCountersignService } from '../../services/document-countersign.service';
import { CountersignDocumentAction, GetDocumentCountersignInfoAction,
         VerifyCountersignPasswordAction } from './document-countersign.action';
import { DOCUMENT_COUNTERSIGN_STATE_TOKEN } from './document-countersign.token';

export class IDocumentCountersignState {
    documentsCountersignInfo: DocumentCountersignDTO[];
}

@State({
    name: DOCUMENT_COUNTERSIGN_STATE_TOKEN,
    defaults: DocumentCountersignState.defaultState
})
@Injectable()
export class DocumentCountersignState {
    static defaultState: IDocumentCountersignState = {
        documentsCountersignInfo: [],
    };

    constructor(
        private readonly store: Store,
        private documentCountersignService: DocumentCountersignService
    ) {

    }

    @Selector()
    static getDocumentCountersignInfo(state: IDocumentCountersignState) {
        return state.documentsCountersignInfo;
    }

    @Action(GetDocumentCountersignInfoAction)
    getDocumentCountersignInfo({ patchState }: StateContext<IDocumentCountersignState>,
                               { organizationId, studyId, documentId, versionNo }: GetDocumentCountersignInfoAction) {
        return this.documentCountersignService.getDocumentCountersignInfo(organizationId, studyId, documentId, versionNo)
                                              .pipe(tap(documentCountersignInfo => {
            patchState({ documentsCountersignInfo: documentCountersignInfo });
        }));
    }

    @Action(VerifyCountersignPasswordAction)
    verifyCountersignPassword(_, { organizationId, studyId, verificationData }: VerifyCountersignPasswordAction) {
        return this.documentCountersignService.verifyCountersignPassword(organizationId, studyId, verificationData);
    }

    @Action(CountersignDocumentAction)
    countersignDocument({ getState, setState }: StateContext<IDocumentCountersignState>,
                        { organizationId, studyId, documentId, versionNo, documentCountersign }: CountersignDocumentAction) {
        return this.documentCountersignService.countersignDocument(organizationId, studyId, documentId, versionNo, documentCountersign)
                                              .pipe(tap(documentCountersign => {
            const state = getState();
            const documentCountersignList = [...state.documentsCountersignInfo];
            const updatedInfoIndex = documentCountersignList.findIndex(d => d.documentId === documentCountersign.documentId
                && d.username === documentCountersign.username && d.versionNo === documentCountersign.versionNo);
            documentCountersignList[updatedInfoIndex] = documentCountersign;

            setState({
                ...state,
                documentsCountersignInfo: documentCountersignList,
            });
        }));
    }
}
