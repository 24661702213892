export enum MeetingStatusEnum {
  DRAFT = 'meeting-status-draft',
  ACTIVE = 'meeting-status-activated',  
  SCHEDULED = 'meeting-status-scheduled',
  CONFIRMED = 'meeting-status-confirmed',
  REOPEN = 'meeting-status-re-open',
  COMPLETED = 'meeting-status-completed',
  CANCELLED = 'meeting-status-cancelled',  
  REMOVED = 'meeting-status-removed'
}
