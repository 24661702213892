import { IVersionDTO } from '../../dtos/version.dto';

export class GetPlatformBackendVersion {
  static readonly type = '[Version] [GET] [Get Platform Version]';
  constructor() {}
}

export class GetDsmbBackendVersion {
  static readonly type = '[Version] [GET] [Get Dsmb Version]';
  constructor() {}
}