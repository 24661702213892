import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrainingHistoryDTO } from '../dtos/training-history.dto';
import { TrainingPasswordVerificationDTO } from '../dtos/training-password-verification.dto';
import { TrainingVideoDTO } from '../dtos/training-video.dto';

@Injectable({
  providedIn: 'root'
})

export class TrainingHistoryService {
constructor(private readonly httpClient: HttpClient) { }

  getSelfTrainingHistory(organizationId: string, studyId: string): Observable<TrainingHistoryDTO> {
    return this.httpClient.get<TrainingHistoryDTO>(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/training/get-self-training-info`);
  }

  verifyTrainingPassword(organizationId: string, studyId: string, verificationData: TrainingPasswordVerificationDTO) {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/training/verify-password`, verificationData);
  }

  signTraining(organizationId: string, studyId: string): Observable<TrainingHistoryDTO> {
    return this.httpClient.post<TrainingHistoryDTO>(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/training/sign`, {});
  }

  getTrainingVideo(organizationId: string, studyId: string): Observable<TrainingVideoDTO> {
    return this.httpClient.get<TrainingVideoDTO>(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/training/video`);
  }
}