import { Injectable } from '@angular/core';

export interface ODataQueryParams {
  filter?: string;
  startPage?: number;
  itemsPerPage?: number;
  sorting?: string;
}

export interface ODataQueryParamsOptions {
  orderProperty: string;
}

export interface ODataQueryParamsMap {
  asPaged(): string | null,
  asCount(): string
}

@Injectable({
  providedIn: 'root'
})
export class ODataService {
  public serialize = (params?: ODataQueryParams, options?: ODataQueryParamsOptions): ODataQueryParamsMap => {
    return {
      asPaged: () => params && options ? this.pagedData(params, options) : null,
      asCount: () => this.count(params)
    }
  }

  private pagedData({ filter, sorting, startPage, itemsPerPage }: ODataQueryParams, options: ODataQueryParamsOptions) {
    let query = '?';

    if (filter) {
      query += `${filter}&`;
    }

    query += `$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}`;

    if (sorting !== undefined) {
      if (sorting === null || sorting.toLowerCase() === 'name asc') {
        query += `&$orderby=${options.orderProperty} asc`;
      }
      else if (sorting.toLowerCase() === 'name desc') {
        query += `&$orderby=${options.orderProperty} desc`;
      }
      else {
        query += `&$orderby=${sorting}, ${options.orderProperty} asc`;
      }
    }

    return query;
  }

  private count(params?: ODataQueryParams) {
    let query = '?';

    if (params?.filter) {
      query += `${params.filter}&`;
    }

    query += '$count=true';

    return query;
  }
}
