import { Injectable } from '@angular/core';
import { IVersionDTO } from '../dtos/version.dto';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VersionService {
constructor(private readonly httpClient: HttpClient) { }

getPlatformBackendVersion(): Observable<IVersionDTO> {
    return this.httpClient.get<IVersionDTO>(`${environment.platformHost}/platform/version`);
  }

getDsmbBackendVersion(): Observable<IVersionDTO> {
    return this.httpClient.get<IVersionDTO>(`${environment.dsmbHost}/dsmb/version`);
  }

}