import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from '../../model/user.model';
import { RoutesService } from '../../services/routes.service';
import { SidebarState } from '../../store/sidebar/sidebar.state';
import { UserState } from '../../store/user/user.state';
import { ApplicationScopeEnum, ApplicationsEnum } from '../../dtos/application.dto';
import  packageInfo  from 'package.json'


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Select(UserState.getLoggedUser) public readonly loggedUser$: Observable<IUser>;
  @Select(SidebarState.getSidebarOpen) public readonly sidebarOpen$: Observable<boolean>;

  @Input() public readonly marginClass: string;

  public readonly environmentName = environment.environment;
  public readonly routeParams = this.routesService.getParams(this.activeRoute.snapshot);
  public readonly application = ApplicationsEnum.DSMB;
  public readonly scopeOrganization = ApplicationScopeEnum.ORGANIZATION;
  public readonly scopeStudy = ApplicationScopeEnum.STUDY;
  public readonly appVersion: string = packageInfo.version;

  constructor(
    private readonly routesService: RoutesService,
    private readonly activeRoute: ActivatedRoute
  ) { }
}
