import { DecisionDTO } from "../../dtos/decision.dto";


export class GetDecisionsAction {
    static readonly type = '[Decisions] [GET] [Get Decisions]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string,
        public startPage?: number,
        public itemsPerPage?: number,
        public sorting?: string
    ){

    }
}

export class GetDecisionDetailsAction {
    static readonly type = '[Decisions] [GET] [Get Decision Detail]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public decisionId: string,
    ){

    }
}

export class GetDecisionsCountAction {
    static readonly type = '[Decisions] [GET] [Get Decisions Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public filter: string
    ) {
        
    }
}

export class GetDecisionsByMeetingAction {
    static readonly type = '[Decisions] [GET] [Get Meeting Decisions]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public filter: string,
        public startPage?: number,
        public itemsPerPage?: number,
        public sorting?: string
    ){

    }
}

export class GetDecisionsByDiscussionItemAction {
    static readonly type = '[Decisions] [GET] [Get Discussion Item Decisions]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
        public filter: string,
        public startPage?: number,
        public itemsPerPage?: number,
        public sorting?: string
    ){

    }
}

export class GetDecisionsCountByMeetingAction {
    static readonly type = '[Decisions] [GET] [Get Meeting Decisions Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public filter: string
    ) {
        
    }
}

export class GetDecisionsCountByDiscussionItemAction {
    static readonly type = '[Decisions] [GET] [Get Discussion Item Decisions Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public discussionItemId: string,
        public filter: string
    ) {
        
    }
}

export class AddDecisionAction {
    static readonly type = '[Decisions] [GET] [Add Decisions]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public newDecision: DecisionDTO,
    ) {
        
    }
}

export class EditDecisionDetails{
    static readonly type = '[Decisions] [POST] [Edit Decision]'

    constructor(
        public organizationId: string,
        public studyId: string,
        public updatedDecision: DecisionDTO,
    ) {
        
    }
}

export class DeleteDecisionAction{
        static readonly type = '[Decisions] [Delete] [Delete Decision]';
    
        constructor(
            public organizationId: string,
            public studyId: string,
            public decisionId: string,
            public comment: string,
        ){
    
        }
    }
    
export class GetDecisionDocumentsAction{
    static readonly type = '[Decisions Document] [Get] [get Decision Documents]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public decisionId: string,
    ){}
}

export class GetDecisionDocumentsByDiscussionItemAction {
    static readonly type = '[Decisions Document] [Get] [get Decision Documents For Discussion Item]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public meetingId: string,
        public discussionItemId: string,
    ){}
}

export class GetDecisionDocumentsCountAction {
    static readonly type = '[Decisions Document] [Get] [Get Decision Documents Count]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public decisionId: string,
    ) {
        
    }
}

export class GetDecisionDocumentAction {
    static readonly type = '[Decisions Document] [Get] [Get Decision Document]';
    constructor(
        public organizationId: string,
        public studyId: string,
        public documentId: string, 
        public versionNo: string, 
    ) {
        
    }
}

export class GetDecisionDocumentBase64Action {
    static readonly type = '[Decisions Document] [Get] [Get Decision Document Base 64]';
    constructor(
        public organizationId: string,
        public studyId: string,
        public documentId: string, 
        public versionNo: string, 
    ) {
        
    }
}

export class DownloadDecisionDocumentAction {
    static readonly type = '[Decision Document] [Get] [Download Decision Document]';

    constructor(
        public organizationId: string,
        public studyId: string,
        public documentId: string,
        public versionNo: string
    ){

    }
}

export class UploadDecisionDocumentAction {
    static readonly type = '[Decisions Document] [POST] [Upload Document]';
      
    public readonly post = {
        notification: {
            success: 'dsmb.upload-document.toastr-message'
        }
    };
      
    constructor(
        public payload: {
            studyDocument: FormData,
            organizationId:string,
            studyId: string,
            meetingId: string,
            discussionItemId: string,
            decisionId: string,
            documentId: string,
            versionNo: number,
            notify: boolean
        }
    ) { }
}

export class DeleteDecisionDocumentAction {
    static readonly type = '[Document] [Delete] [Delete Decision Document]';

    public readonly post = {
      notification: {
        success: 'dsmb.delete-document.toastr-message'
      }
    };

    constructor(
      // Payload { organizationId, studyId, documentId}
      public payload: any
    ) { }
}

export class GetAuditTrailDecisionDocumentAction {
    static readonly type = '[Decisions] [GET] [Get Decision Document Audit Trail]';

    constructor(
      public payload: {
        organizationId:string,
        studyId: string,
        documentId: string,
        isStudyDocument: boolean,
        startPage?: number,
        itemsPerPage?: number,
        sorting?: string
      }
    ) { }
}

export class GetAuditTrailDecisionDocumentCountAction {
    static readonly type = '[Decisions] [Get] [Get Count Decisions Document Audit Trail]';

    constructor(
      public payload: {
        organizationId: string,
        studyId: string,
        documentId: string,
        isStudyDocument: boolean
      }
    ) { }
}