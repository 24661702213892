import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';
import { TimeZoneDTO } from '../../dtos/timezone.dto';
import { TIMEZONE_STATE_TOKEN } from './timezone.token';
import { defaultState } from './timezones.default';

export class ITimezoneState {
  timezones: TimeZoneDTO[];
}

@State({
  name: TIMEZONE_STATE_TOKEN,
  defaults: TimezoneState.defaultState
})
@Injectable()
export class TimezoneState {
  static defaultState: ITimezoneState = defaultState;

  constructor() {
  }

  @Selector()
  static getTimezones(state: ITimezoneState) {
    return state.timezones;
  }
}
